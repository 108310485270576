"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupQuoteCharges = void 0;
const lodash_1 = require("lodash");
const utils_1 = require("@truxweb/utils");
const formatQuoteCharge = (charge) => {
    const formattedOriginal = (0, utils_1.formatNumberToCurrencyValue)(charge.charge.original.value);
    const formattedModified = (0, utils_1.formatNumberToCurrencyValue)(charge.charge.modified.value);
    return Object.assign(Object.assign({}, charge), { charge: Object.assign(Object.assign({}, charge.charge), { modified: Object.assign(Object.assign({}, charge.charge.modified), { value: formattedModified }), original: Object.assign(Object.assign({}, charge.charge.original), { value: formattedOriginal }) }) });
};
const groupQuoteCharges = (quoteData, charges, userType, t) => {
    const equipmentPricing = {};
    const internalCharges = {};
    const adminFees = {};
    const accessorialPricing = {};
    const additionalCharges = {};
    const accessorialLookup = {};
    const equipmentLookup = {};
    quoteData.equipment.forEach((equipment) => {
        equipmentLookup[equipment.equipmentId] = equipment;
    });
    quoteData.accessorials.forEach((accessorial) => {
        accessorialLookup[accessorial.accessorialId] = accessorial;
    });
    const taxes = [];
    const subtotal = [];
    const surcharge = [];
    let isTruxwebChargeBillable = true;
    charges.forEach((charge) => {
        charge = formatQuoteCharge((0, lodash_1.cloneDeep)(charge));
        if (['EQUIPMENT'].includes(charge.chargeType) && charge.chargeReferenceId) {
            const equipment = equipmentLookup[charge.chargeReferenceId];
            equipmentPricing[charge.chargeReferenceId] = {
                charge,
                title: t(`common:${userType}-equipment-${equipment === null || equipment === void 0 ? void 0 : equipment.equipmentCode}`),
            };
            subtotal.push(charge);
        }
        else if (['FUEL_SURCHARGE'].includes(charge.chargeType)) {
            additionalCharges[charge.chargeTypeId] = {
                charge,
                title: t(`common:charge-FUEL_SURCHARGE`),
            };
            subtotal.push(charge);
        }
        else if (['CREDIT_CARD_SURCHARGE'].includes(charge.chargeType)) {
            surcharge.push(charge);
        }
        else if (charge.chargeType === 'TRUXWEB' && charge.id) {
            const summaryItem = {
                charge,
                title: t(`common:charge-${charge.chargeType}`),
            };
            internalCharges[charge.id] = summaryItem;
            if (charge.isBillable) {
                subtotal.push(charge);
            }
            else {
                isTruxwebChargeBillable = false;
            }
        }
        else if (charge.chargeType === 'TRUXWEB' &&
            charge.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
            // this charge does not have an id, as it is an ephemeral charge
            // so we use 0 for this charge, as there will never be a 0 id
            adminFees[0] = {
                charge,
                title: t(`common:adminFees`),
            };
            subtotal.push(charge);
        }
        else if (charge.chargeType === 'ACCESSORIAL' && charge.chargeReferenceId) {
            const accessorial = accessorialLookup[charge.chargeReferenceId];
            accessorialPricing[charge.chargeReferenceId] = {
                charge,
                title: t(`common:${userType}-accessorial-${accessorial === null || accessorial === void 0 ? void 0 : accessorial.accessorialCode}`),
            };
            subtotal.push(charge);
        }
        else if (charge.isTaxCharge) {
            taxes.push(charge);
        }
    });
    const subtotalDisplay = (0, lodash_1.cloneDeep)(subtotal[0]);
    subtotal.slice(1).forEach((charge) => {
        subtotalDisplay.charge.modified.value += charge.charge.modified.value;
    });
    const totalDisplay = (0, lodash_1.cloneDeep)(subtotalDisplay);
    [...taxes, ...surcharge].forEach((charge) => {
        totalDisplay.charge.modified.value += charge.charge.modified.value;
    });
    const potentialSavings = Object.values(internalCharges).reduce((value, { charge }) => {
        return value + charge.charge.modified.value;
    }, 0);
    return {
        accessorialPricing,
        additionalCharges,
        adminFees,
        equipmentPricing,
        internalCharges,
        isTruxwebChargeBillable,
        savingsTotal: potentialSavings,
        subtotal,
        subtotalDisplay,
        surcharge,
        taxes,
        totalDisplay,
    };
};
exports.groupQuoteCharges = groupQuoteCharges;
