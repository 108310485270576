"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarEquipment = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const ShipmentsDashboardSidebarEquipment_styles_1 = require("./ShipmentsDashboardSidebarEquipment.styles");
const ShipmentsDashboardSidebarEquipment = ({ searchFormParams, shipment, t, }) => {
    const classes = (0, ShipmentsDashboardSidebarEquipment_styles_1.useStyles)();
    const [equipmentDetails, setEquipmentDetails] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (!equipmentDetails) {
            const selectedEquipment = searchFormParams.searchEquipment[shipment.equipment[0].equipmentCode];
            setEquipmentDetails(selectedEquipment);
        }
    }, [shipment, searchFormParams, setEquipmentDetails, equipmentDetails]);
    if (!equipmentDetails)
        return (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.CircularProgress, null)));
    return (react_1.default.createElement(ux_1.Grid, { className: classes.container, container: true, justifyContent: "center" },
        react_1.default.createElement(ux_1.Box, { p: 2 },
            react_1.default.createElement(ux_1.SelectCard, { checkbox: react_1.default.createElement(react_1.default.Fragment, null), className: classes.noPointerEvents, color: "grey" },
                react_1.default.createElement(ux_1.EquipmentCardContent, { description: react_1.default.createElement(__1.TruckDescription, { areDetailsExpanded: true, areDetailsForcedVisible: true, category: equipmentDetails === null || equipmentDetails === void 0 ? void 0 : equipmentDetails.category, equipmentCode: equipmentDetails === null || equipmentDetails === void 0 ? void 0 : equipmentDetails.equipment.code, isSecondary: equipmentDetails === null || equipmentDetails === void 0 ? void 0 : equipmentDetails.hasVariants, t: t }), equipmentCode: equipmentDetails === null || equipmentDetails === void 0 ? void 0 : equipmentDetails.equipment.code, height: 137, isSecondary: equipmentDetails === null || equipmentDetails === void 0 ? void 0 : equipmentDetails.hasVariants, name: t(`search:${shipment.equipment[0].equipmentCode}_TITLE`) }),
                Boolean(shipment.shipment.ltlCargoDimensions) && (react_1.default.createElement(__1.LtlCargoSpecifications, { data: shipment.shipment.ltlCargoDimensions || [], equipmentCode: equipmentDetails === null || equipmentDetails === void 0 ? void 0 : equipmentDetails.equipment.code, fields: [], isDisplayOnly: true, t: t }))))));
};
exports.ShipmentsDashboardSidebarEquipment = ShipmentsDashboardSidebarEquipment;
