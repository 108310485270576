"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardPageTemplate = void 0;
const __1 = require("..");
const react_1 = __importStar(require("react"));
const DashboardPageTemplate_styles_1 = require("./DashboardPageTemplate.styles");
const DashboardPageTemplate = ({ children, dashboardHeaderProps, iconMenuProps, internalContents, isInMaintenance, isLeftTextMenuOpen, isMobileEnabled, logo, maintenanceComponent, menu, onLeftMenuStateChange, pageBanner, textMenuContent, textMenuProps, titleSupplement, }) => {
    const { title } = dashboardHeaderProps;
    const classes = (0, DashboardPageTemplate_styles_1.useStyles)({ isLeftTextMenuOpen });
    const theme = (0, __1.useTheme)();
    const isMobile = (0, __1.useMediaQuery)(theme.breakpoints.down(theme.breakpoints.values.sm));
    const handleLeftTextMenuOpen = (0, react_1.useCallback)(() => {
        onLeftMenuStateChange(true);
    }, [onLeftMenuStateChange]);
    const handleToggleLeftMenu = (0, react_1.useCallback)(() => {
        const newState = !isLeftTextMenuOpen;
        onLeftMenuStateChange(newState);
    }, [isLeftTextMenuOpen, onLeftMenuStateChange]);
    if (isMobile && isMobileEnabled) {
        return (react_1.default.createElement(__1.DashboardMobileTemplate, { logo: logo, menu: menu }, children()));
    }
    return (react_1.default.createElement(__1.Grid, { className: classes.containerDashboard, container: true, wrap: "nowrap" },
        react_1.default.createElement(__1.Grid, { className: classes.leftSidebar, item: true },
            react_1.default.createElement(__1.LeftHandSidebar, { handleToggleLeftMenu: handleToggleLeftMenu, iconMenuProps: Object.assign(Object.assign({}, iconMenuProps), { handleClick: handleLeftTextMenuOpen }), isOpen: isLeftTextMenuOpen, textMenuProps: Object.assign(Object.assign({}, textMenuProps), { isOpen: isLeftTextMenuOpen }) }, textMenuContent)),
        isInMaintenance && (maintenanceComponent || null),
        !isInMaintenance && (react_1.default.createElement(__1.Grid, { className: internalContents === 'fullWidth' ? classes.contentPanel : classes.backgroundGrey, container: true, direction: "column", item: true, style: {
                overflow: 'auto',
                position: 'relative',
                zIndex: 0,
            }, xs: true },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(__1.Box, { className: classes.bannerContainer, ml: isLeftTextMenuOpen ? 0 : -6 }, pageBanner),
                react_1.default.createElement(__1.Grid, { className: classes.headerContainer, item: true, style: { maxHeight: 148 } },
                    react_1.default.createElement(__1.DashboardHeader, Object.assign({}, dashboardHeaderProps, { title: internalContents === 'centered' ? '' : title }))),
                react_1.default.createElement(__1.Grid, { item: true, xs: true },
                    internalContents === 'fullWidth' && (react_1.default.createElement(__1.FullWidthInternalContent, { isLeftTextMenuOpen: isLeftTextMenuOpen }, children())),
                    internalContents === 'centered' && (react_1.default.createElement(__1.CenteredInternalContents, { isLeftTextMenuOpen: isLeftTextMenuOpen, title: title, titleSupplement: titleSupplement }, children()))))))));
};
exports.DashboardPageTemplate = DashboardPageTemplate;
