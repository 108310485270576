// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentShipperQuoteStatusV1 = exports.LtlPackagingV1 = exports.TruckloadTypeV1 = exports.ShipmentLocationTypeV1 = exports.CountryV1 = exports.PriceTypeV1 = exports.CurrencyV1 = exports.CarrierServiceTypeV1 = exports.ShipmentQuoteRequestStatusV1 = exports.MetadataTypeV1 = void 0;
var MetadataTypeV1;
(function (MetadataTypeV1) {
    MetadataTypeV1["INTEGER"] = "INTEGER";
    MetadataTypeV1["STRING"] = "STRING";
    MetadataTypeV1["ADDRESS"] = "ADDRESS";
    MetadataTypeV1["DATE"] = "DATE";
    MetadataTypeV1["WEIGHT"] = "WEIGHT";
    MetadataTypeV1["PRICE"] = "PRICE";
    MetadataTypeV1["DURATION"] = "DURATION";
    MetadataTypeV1["TIME"] = "TIME";
    MetadataTypeV1["OPTIONAL"] = "OPTIONAL";
})(MetadataTypeV1 || (exports.MetadataTypeV1 = MetadataTypeV1 = {}));
var ShipmentQuoteRequestStatusV1;
(function (ShipmentQuoteRequestStatusV1) {
    ShipmentQuoteRequestStatusV1["PENDING"] = "PENDING";
    ShipmentQuoteRequestStatusV1["ACCEPTED"] = "ACCEPTED";
    ShipmentQuoteRequestStatusV1["MODIFIED"] = "MODIFIED";
    ShipmentQuoteRequestStatusV1["REFUSED"] = "REFUSED";
    ShipmentQuoteRequestStatusV1["EXPIRED"] = "EXPIRED";
    ShipmentQuoteRequestStatusV1["CANCELLED"] = "CANCELLED";
})(ShipmentQuoteRequestStatusV1 || (exports.ShipmentQuoteRequestStatusV1 = ShipmentQuoteRequestStatusV1 = {}));
var CarrierServiceTypeV1;
(function (CarrierServiceTypeV1) {
    CarrierServiceTypeV1["lane"] = "lane";
})(CarrierServiceTypeV1 || (exports.CarrierServiceTypeV1 = CarrierServiceTypeV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var ShipmentLocationTypeV1;
(function (ShipmentLocationTypeV1) {
    ShipmentLocationTypeV1["ORIGIN"] = "ORIGIN";
    ShipmentLocationTypeV1["DESTINATION"] = "DESTINATION";
})(ShipmentLocationTypeV1 || (exports.ShipmentLocationTypeV1 = ShipmentLocationTypeV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
/**
 * How the good is packaged for shipping
 */
var LtlPackagingV1;
(function (LtlPackagingV1) {
    LtlPackagingV1["STANDARD_PALLET"] = "STANDARD_PALLET";
    LtlPackagingV1["NON_STANDARD_PALLET"] = "NON_STANDARD_PALLET";
    LtlPackagingV1["CRATE"] = "CRATE";
    LtlPackagingV1["LOOSE"] = "LOOSE";
})(LtlPackagingV1 || (exports.LtlPackagingV1 = LtlPackagingV1 = {}));
var ShipmentShipperQuoteStatusV1;
(function (ShipmentShipperQuoteStatusV1) {
    ShipmentShipperQuoteStatusV1["DECLINED"] = "DECLINED";
    ShipmentShipperQuoteStatusV1["CARRIER_DECLINED"] = "CARRIER_DECLINED";
    ShipmentShipperQuoteStatusV1["CANCELLED"] = "CANCELLED";
    ShipmentShipperQuoteStatusV1["CONFIRMED"] = "CONFIRMED";
    ShipmentShipperQuoteStatusV1["RESPONSE_REQUIRED"] = "RESPONSE_REQUIRED";
    ShipmentShipperQuoteStatusV1["EXPIRED"] = "EXPIRED";
    ShipmentShipperQuoteStatusV1["WAITING_FOR_RESPONSES"] = "WAITING_FOR_RESPONSES";
    ShipmentShipperQuoteStatusV1["NO_CARRIER_RESPONSE"] = "NO_CARRIER_RESPONSE";
})(ShipmentShipperQuoteStatusV1 || (exports.ShipmentShipperQuoteStatusV1 = ShipmentShipperQuoteStatusV1 = {}));
