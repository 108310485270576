// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodV1 = exports.UserStatusV1 = exports.LanguageV1 = exports.LtlPackagingV1 = exports.TruckloadTypeV1 = exports.CountryV1 = void 0;
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
/**
 * How the good is packaged for shipping
 */
var LtlPackagingV1;
(function (LtlPackagingV1) {
    LtlPackagingV1["STANDARD_PALLET"] = "STANDARD_PALLET";
    LtlPackagingV1["NON_STANDARD_PALLET"] = "NON_STANDARD_PALLET";
    LtlPackagingV1["CRATE"] = "CRATE";
    LtlPackagingV1["LOOSE"] = "LOOSE";
})(LtlPackagingV1 || (exports.LtlPackagingV1 = LtlPackagingV1 = {}));
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var UserStatusV1;
(function (UserStatusV1) {
    UserStatusV1["Active"] = "ACTIVE";
    UserStatusV1["Inactive"] = "INACTIVE";
    UserStatusV1["Banned"] = "BANNED";
    UserStatusV1["Pending"] = "PENDING";
    UserStatusV1["Review"] = "REVIEW";
    UserStatusV1["Registered"] = "REGISTERED";
})(UserStatusV1 || (exports.UserStatusV1 = UserStatusV1 = {}));
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
