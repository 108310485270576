"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdjustShipmentPaymentTermsForm = exports.AdjustShipmentPaymentTerms = void 0;
const date_fns_1 = require("date-fns");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const AdjustShipmentPaymentTerms_styles_1 = require("./AdjustShipmentPaymentTerms.styles");
const AdjustShipmentPaymentTerms = (props) => {
    const classes = (0, AdjustShipmentPaymentTerms_styles_1.useStyles)();
    const { deliveryDate, handleSubmitSuccess, isSaving, paymentTermsData, t } = props;
    const { paymentTerms } = paymentTermsData, others = __rest(paymentTermsData, ["paymentTerms"]);
    const dateToEdit = (0, react_1.useMemo)(() => {
        return (0, date_fns_1.add)(new Date(deliveryDate), {
            days: paymentTerms,
        });
    }, [paymentTerms, deliveryDate]);
    const { control, handleSubmit } = (0, react_hook_form_1.useForm)({
        defaultValues: Object.assign({ paymentDate: dateToEdit }, others),
    });
    const onSubmitSuccess = (0, react_1.useCallback)((formData) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedPaymentTerms = Object.assign(Object.assign({}, paymentTermsData), { adjustmentType: formData.adjustmentType, adjustmentValue: formData.adjustmentValue, paymentTerms: (0, date_fns_1.differenceInCalendarDays)(formData.paymentDate, deliveryDate ? new Date(deliveryDate) : new Date()) });
        yield handleSubmitSuccess(updatedPaymentTerms);
    }), [handleSubmitSuccess, paymentTermsData, deliveryDate]);
    return (react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
        react_1.default.createElement(ux_1.Box, { my: 3 },
            react_1.default.createElement(ux_1.Card, { className: classes.container },
                react_1.default.createElement(exports.AdjustShipmentPaymentTermsForm, Object.assign({}, props, { control: control, isShipmentPaymentTermsAdjustment: true, shouldAllowDateAdjustment: true })),
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                    react_1.default.createElement(ux_1.Box, { mb: 2 },
                        react_1.default.createElement(__1.SaveButton, { color: "primaryLight", isSaving: isSaving, t: t }, "Update Shipment Payment Terms")))))));
};
exports.AdjustShipmentPaymentTerms = AdjustShipmentPaymentTerms;
const AdjustShipmentPaymentTermsForm = ({ companyType, control, deliveryDate, isShipmentPaymentTermsAdjustment, language, paymentTermsData, shouldAllowDateAdjustment, t, }) => {
    const { paymentTerms } = paymentTermsData;
    const dateToEdit = (0, react_1.useMemo)(() => {
        return deliveryDate
            ? (0, date_fns_1.add)(new Date(deliveryDate), {
                days: paymentTerms,
            })
            : new Date();
    }, [paymentTerms, deliveryDate]);
    return (react_1.default.createElement(ux_1.Box, { p: 2 },
        shouldAllowDateAdjustment && (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: dateToEdit, name: "paymentDate", render: ({ field: { onChange, value } }) => {
                return (react_1.default.createElement(__1.LocalizedDatePicker, { dateToEdit: value, locale: language, minDate: deliveryDate ? new Date(deliveryDate) : new Date(), onDateChange: onChange, variant: "static" }));
            } })),
        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
            isShipmentPaymentTermsAdjustment && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Typography, { variant: "bodySmall" },
                    "Enter in the value of the ",
                    react_1.default.createElement("i", null, "adjustment"),
                    " to the shipment total"))),
            !isShipmentPaymentTermsAdjustment && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Typography, { variant: "bodySmall" }, "Enter in the value of the admin fee to be applied to each shipment."))),
            react_1.default.createElement(ux_1.Box, { my: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: schemas_1.EPriceTypeV1.PERCENT, name: "adjustmentType", render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", onChange: onChange, sourceEnum: schemas_1.EPriceTypeV1, t: t, value: value }));
                    } })),
            react_1.default.createElement(__1.FormField, { control: control, defaultValue: 0, id: "adjustmentValue", label: 'Adjustment Value', shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number" }),
            isShipmentPaymentTermsAdjustment && (react_1.default.createElement(__1.PaymentTermsAdjustmentExplanation, { companyType: companyType })))));
};
exports.AdjustShipmentPaymentTermsForm = AdjustShipmentPaymentTermsForm;
