import {
  acceptCarrierQuoteForShipper,
  addCreditCardV2,
  fetchShipperQuoteRequestById,
  getCarrierQuoteRequestChargesForShipper,
  shipmentQuoteRequestMessagePoll,
} from '../../actions';
import {
  AddCircleOutlineIcon,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FlatButton,
  Grid,
  Typography,
} from '@truxweb/ux';
import { DEFAULT_LOCATION_HOURS, DEFAULT_LOCATION_SCHEDULE } from '../../config';
import {
  EPaymentMethodV1,
  EPermissionV1,
  type TCarrierShipmentQuoteRequestV1,
  type TCompleteQuoteToShipmentV1,
  type TShipperShipmentQuoteRequestV1,
} from '@truxweb/schemas';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useAlerts,
  useBambora,
  useBankAccounts,
  useCompanyData,
  useCompanyLocations,
  useCreditCards,
  useErrorHandling,
  usePaymentTerms,
  usePermissions,
  usePollResponse,
  useShipmentCredits,
  useTimeout,
  useUserData,
} from '../../hooks';
import { captureException } from '@sentry/nextjs';
import { determineQuoteCrossBorderStatus } from '@truxweb/quote-utils';
import { Helmet } from 'react-helmet';
import { PageLink } from '..';
import { QuoteRequestCheckout } from '@truxweb/common-components';
import { setShipperQuotesData } from '../../stores';
import { SubscriptionMarketing } from '../SubscriptionMarketing';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useDispatch } from 'react-redux';
import { useStyles } from './QuoteRequestCheckoutWrapper.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'checkout', 'shipments'];

type TQuoteRequestCheckoutWrapperProps = {
  onCancelCheckout: () => void;
  carrierQuoteData: TCarrierShipmentQuoteRequestV1;
  shipperQuoteData: TShipperShipmentQuoteRequestV1;
  quoteAcceptanceReason: string | null;
  onCheckoutComplete: () => void;
  ipAddress: string;
};

export const QuoteRequestCheckoutWrapper = ({
  carrierQuoteData,
  ipAddress,
  onCancelCheckout,
  onCheckoutComplete,
  quoteAcceptanceReason,
  shipperQuoteData,
}: TQuoteRequestCheckoutWrapperProps): JSX.Element => {
  const {
    i18n: { language },
    t,
  } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const userData = useUserData();
  const dispatch = useDispatch();
  const { addAlert } = useAlerts();
  const termsAndConditionsRef = useRef<any>();
  const { data: companyLocations, hasLoaded: haveCompanyLocationsLoaded } = useCompanyLocations();
  const errorHandler = useErrorHandling();

  const [companyData] = useCompanyData();
  const [quoteAcceptanceError, setQuoteAcceptanceError] = useState(null);

  const [shouldRefetch, setShouldRefetch] = useState(true);
  const shipmentCredits = useShipmentCredits(shouldRefetch);
  const [isSubscriptionDialogShown, setSubscriptionDialogShown] = useState(false);
  const [creditCards, hasCardDataLoaded] = useCreditCards();
  const [paymentTerms, havePaymentTermsLoaded] = usePaymentTerms();
  const [isAddCardFormShown, setIsAddCardFormShown] = useState(false);
  const [bankAccounts, haveBankAccountsFetched] = useBankAccounts();
  const [isPaymentDataLoading, setIsPaymentDataLoading] = useState(true);
  const [localBamboraInstance, setLocalBamboraInstance] = useState(null);
  const [canSetTaxExemptState, havePermissionsEvaluated] = usePermissions([
    EPermissionV1.CAN_SET_NON_TAXABLE_SHIPMENT,
  ]);
  const [quoteAcceptanceMessageId, setQuoteAcceptanceMessageId] = useState(null);

  const companyLocationData = useMemo(() => {
    if (!haveCompanyLocationsLoaded) return [];
    return companyLocations.map(({ data }) => data);
  }, [companyLocations, haveCompanyLocationsLoaded]);

  const handleAcceptQuote = useCallback(
    async (carrierQuoteRequestId: number, acceptanceRequest: TCompleteQuoteToShipmentV1) => {
      if (
        !acceptanceRequest.cardId &&
        acceptanceRequest.paymentMethod === EPaymentMethodV1.CreditCard
      ) {
        addAlert({
          message: t('common:mustSelectACard'),
          severity: 'error',
        });
        return;
      }

      try {
        setQuoteAcceptanceError(null);
        const messageId = await acceptCarrierQuoteForShipper(
          carrierQuoteRequestId,
          acceptanceRequest
        );
        setQuoteAcceptanceMessageId(messageId);
        return;
      } catch (err) {
        errorHandler(err);
      }
    },
    [setQuoteAcceptanceMessageId, addAlert, t, errorHandler, setQuoteAcceptanceError]
  );

  const handleQuoteAcceptancePollSuccess = useCallback(
    async (response: Record<string, any>) => {
      const quote = await fetchShipperQuoteRequestById(shipperQuoteData.id);

      dispatch(setShipperQuotesData({ item: quote }));
      setQuoteAcceptanceMessageId(null);
      onCheckoutComplete();
      return response;
    },
    [onCheckoutComplete, setQuoteAcceptanceMessageId, shipperQuoteData, dispatch]
  );

  const onRouteToSubscription = useCallback(() => {
    setSubscriptionDialogShown(true);
  }, [setSubscriptionDialogShown]);

  const handleCloseSubscription = useCallback(() => {
    setSubscriptionDialogShown(false);
  }, [setSubscriptionDialogShown]);

  const handleSubscriptionSuccess = useTimeout(
    useCallback(() => {
      setSubscriptionDialogShown(false);
    }, [setSubscriptionDialogShown]),
    2000
  );

  // NOTE: This is a little cheeky, but this allows for the
  // terms and conditions to opened without forcing a link
  // down to the checkbox on <QuoteRequestCheckout />
  const handleRouteToTermsAndConditions = useCallback(() => {
    if (termsAndConditionsRef && termsAndConditionsRef.current) {
      termsAndConditionsRef.current.firstChild.click();
    }
  }, [termsAndConditionsRef]);

  // Ensure that the bambora library is loaded
  const bambora = useBambora();

  const handleClearQuoteAcceptanceError = useCallback(() => {
    setQuoteAcceptanceError(null);
  }, [setQuoteAcceptanceError]);

  const isAwaitingCompletion = usePollResponse(quoteAcceptanceMessageId, {
    errorFn: (err, code) => {
      setQuoteAcceptanceError({ code, err });
      setQuoteAcceptanceMessageId(null);
    },
    pollFn: shipmentQuoteRequestMessagePoll,
    successFn: handleQuoteAcceptancePollSuccess,
  });

  useEffect(() => {
    if (
      hasCardDataLoaded &&
      haveBankAccountsFetched &&
      havePaymentTermsLoaded &&
      haveCompanyLocationsLoaded
    ) {
      setIsPaymentDataLoading(false);
    }
  }, [
    hasCardDataLoaded,
    haveBankAccountsFetched,
    havePaymentTermsLoaded,
    setIsPaymentDataLoading,
    haveCompanyLocationsLoaded,
  ]);

  // Due to a bug with the bambora library we need to recreate the instance
  // every time the form fields for adding a card will be shown.
  //
  // This situation arises because we are submitting the create card request
  // along side the payment submission, which means that the bambora library
  // needs to be available outside of where the card components are mounted
  useEffect(() => {
    // We need to ensure that the bambora libary is available
    // before attempting to use it.
    if (bambora) {
      if (!isAddCardFormShown) {
        setLocalBamboraInstance(null);
      } else {
        setLocalBamboraInstance(new window.customcheckout());
      }
    }
  }, [bambora, setLocalBamboraInstance, isAddCardFormShown]);

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
    }
  }, [shouldRefetch, setShouldRefetch]);

  return (
    <>
      <Dialog onClose={handleCloseSubscription} open={isSubscriptionDialogShown}>
        <DialogContent style={{ maxWidth: 468, position: 'relative' }}>
          <Box mt={-4} pb={3} pl={2} pr={3}>
            <Grid alignItems="center" container direction="column" justifyContent="center">
              <SubscriptionMarketing onSuccess={handleSubscriptionSuccess} />
              <Box mt={3}>
                <FlatButton
                  color="primaryLight"
                  onClick={handleCloseSubscription}
                  variant="contained"
                >
                  {t('common:close')}
                </FlatButton>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <a ref={termsAndConditionsRef}>
        <PageLink pageId="terms-and-conditions" target="termsAndConditions" />
      </a>
      <Helmet>
        {/* Linting disabled due to payment processor requirements */}
        {/* eslint-disable-next-line @next/next/no-sync-scripts */}
        <script src="https://libs.na.bambora.com/customcheckout/1/customcheckout.js"></script>
      </Helmet>
      {(!haveCompanyLocationsLoaded || !havePermissionsEvaluated) && (
        <Grid alignItems="center" container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {haveCompanyLocationsLoaded && (
        <QuoteRequestCheckout
          ApplyForPaymentTerms={({ children }: any) => {
            return (
              <PageLink pageId={'account/payment'} target="requestPaymentTerms">
                <Grid container>
                  <Grid item>
                    <AddCircleOutlineIcon className={classes.requestPaymentTerms} />
                  </Grid>
                  <Grid item>
                    <Box ml={1} mt={0.25}>
                      <Typography className={classes.requestPaymentTerms}>
                        {children || t('checkout:paymentTermsCTA')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </PageLink>
            );
          }}
          acceptCarrierQuoteForShipper={handleAcceptQuote}
          addAlert={addAlert}
          addCreditCard={addCreditCardV2}
          bambora={localBamboraInstance}
          bankAccounts={bankAccounts || []}
          canSetQuoteTaxExemptStatus={canSetTaxExemptState}
          captureException={captureException}
          carrierQuoteData={carrierQuoteData}
          clearExternalError={handleClearQuoteAcceptanceError}
          company={companyData}
          companyLocations={companyLocationData}
          companySubscription={userData?.companyData.subscription}
          creditCards={creditCards || []}
          defaultLocationHours={DEFAULT_LOCATION_HOURS}
          defaultLocationSchedule={DEFAULT_LOCATION_SCHEDULE}
          externalError={quoteAcceptanceError}
          getUpdatedQuoteCharges={async (request) => {
            const {
              carrierQuoteRequestId,
              creditCardType,
              isTaxExempt,
              paymentMethod,
              shouldRedeemCredit,
            } = request;
            return await getCarrierQuoteRequestChargesForShipper({
              carrierQuoteRequestId,
              creditCardType,
              isTaxExempt: canSetTaxExemptState
                ? isTaxExempt !== undefined
                  ? isTaxExempt
                  : determineQuoteCrossBorderStatus(carrierQuoteData)
                : determineQuoteCrossBorderStatus(carrierQuoteData),
              paymentMethod,
              shouldRedeemCredit,
            });
          }}
          handleRouteToSubscription={onRouteToSubscription}
          handleRouteToTermsAndConditions={handleRouteToTermsAndConditions}
          ipAddress={ipAddress}
          isAddCardFormShown={isAddCardFormShown}
          isPaymentDataLoading={isPaymentDataLoading}
          isSaving={isAwaitingCompletion}
          locale={transformI18nLocaleToLanguage(language)}
          onCancelCheckout={onCancelCheckout}
          paymentTerms={paymentTerms}
          quoteAcceptanceReason={quoteAcceptanceReason}
          setIsAddCardFormShown={setIsAddCardFormShown}
          shipmentCredits={shipmentCredits?.data || []}
          shipperQuoteData={shipperQuoteData}
          t={t}
          userData={userData.userData}
        />
      )}
    </>
  );
};
