"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierProfileAdditionalCosts = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const CarrierProfileAdditionalCosts = ({ equipment, language, t, }) => {
    const [selectedEquipment, setSelectedEquipment] = (0, react_1.useState)(equipment[0]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", item: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Select, { IconComponent: ux_1.UnfoldMoreIcon, MenuProps: {
                        MenuListProps: {
                            disablePadding: true,
                        },
                    }, defaultValue: selectedEquipment.equipmentCode, displayEmpty: true, fullWidth: true, id: "equipment", labelId: "equipmentsLabel", onChange: (event) => {
                        const equipmentCode = event.target.value;
                        const newSelection = equipment.find((item) => {
                            return item.equipmentCode === equipmentCode;
                        });
                        if (newSelection) {
                            setSelectedEquipment(newSelection);
                        }
                    }, variant: 'outlined' }, equipment.map((item) => {
                    return (react_1.default.createElement(ux_1.MenuItem, { key: item.equipmentCode, value: item.equipmentCode }, t(`common:SHIPPER-equipment-${item.equipmentCode}`)));
                }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { pt: 2 }))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column", item: true, justifyContent: "center", style: { background: '#EDF8FF', borderRadius: '8px', height: 100, width: 160 } },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.TruxwebTimePastIcon, { style: { fill: '#0087CE' } })),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { variant: "bodySmall" }, t('common:freeWaitingTime'))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mt: 1 },
                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, selectedEquipment.waitingTimeIncluded)))),
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column", item: true, justifyContent: "center", style: { background: '#EDF8FF', borderRadius: '8px', height: 100, width: 160 } },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.TruxwebReceiptIcon, { style: { fill: '#0087CE' } })),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { variant: "bodySmall" }, t('common:additionalWaitingTime'))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mt: 1 },
                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" },
                                react_1.default.createElement(__1.PriceDisplay, { language: language, price: {
                                        currency: selectedEquipment.currency,
                                        isBillable: true,
                                        type: schemas_1.EPriceTypeV1.CURRENCY,
                                        value: selectedEquipment.waitingTimeRate,
                                    } })))))))));
};
exports.CarrierProfileAdditionalCosts = CarrierProfileAdditionalCosts;
