"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupShipmentCharges = void 0;
const schemas_1 = require("@truxweb/schemas");
const groupShipmentCharges = (charges, companyType) => {
    const taxes = [];
    const truxweb = [];
    const serviceFees = [];
    const additional = [];
    const additionalTruxwebShipper = [];
    const additionalTruxwebCarrier = [];
    const adjustments = [];
    const carrierAdjustments = [];
    const truxwebAdjustments = [];
    const creditCardSurcharge = [];
    const fuelSurcharge = [];
    let truxwebSubtotalValue = 0;
    let truxwebTotalValue = 0;
    let carrierTotalValue = 0;
    const equipmentPricing = {};
    const accessorialPricing = {};
    charges.forEach((charge) => {
        if (charge.isPriceAdjustment && !charge.isTaxCharge) {
            adjustments.push(charge);
            if (charge.recipient === 'TRUXWEB') {
                truxwebAdjustments.push(charge);
                if (charge.isBillable) {
                    truxwebTotalValue += charge.charge.value;
                    if (!charge.isTaxCharge) {
                        truxwebSubtotalValue += charge.charge.value;
                    }
                }
            }
            else if (charge.recipient === 'CARRIER') {
                carrierTotalValue += charge.charge.value;
                carrierAdjustments.push(charge);
            }
        }
        else {
            if (charge.recipient === 'TRUXWEB') {
                if (charge.isBillable || companyType === schemas_1.EUserTypeV1.TRUXWEB) {
                    if (charge.isBillable) {
                        truxwebTotalValue += charge.charge.value;
                        if (!charge.isTaxCharge && charge.chargeType !== 'CREDIT_CARD_SURCHARGE') {
                            truxwebSubtotalValue += charge.charge.value;
                        }
                    }
                    if (!['TRUXWEB_CARRIER', 'TRUXWEB_SHIPPER', 'CREDIT_CARD_SURCHARGE'].includes(charge.chargeType) &&
                        !charge.isTaxCharge) {
                        serviceFees.push(charge);
                    }
                    if (charge.chargeType === 'TRUXWEB_SHIPPER') {
                        additionalTruxwebShipper.push(charge);
                        /*
                      } else if (charge.chargeType === 'TRUXWEB_CARRIER') {
                        additionalTruxwebCarrier.push(charge);
                        */
                    }
                    else if (charge.chargeType === 'CREDIT_CARD_SURCHARGE') {
                        creditCardSurcharge.push(charge);
                    }
                    else if (charge.isTaxCharge) {
                        taxes.push(charge);
                    }
                    else {
                        truxweb.push(charge);
                    }
                }
            }
            else if (charge.chargeType === 'TRUXWEB_CARRIER') {
                additionalTruxwebCarrier.push(charge);
                carrierTotalValue += charge.charge.value;
            }
            else if (charge.chargeType === 'EQUIPMENT' && charge.chargeReferenceId) {
                equipmentPricing[charge.chargeReferenceId] = charge.charge;
                carrierTotalValue += charge.charge.value;
            }
            else if (charge.chargeType === 'ACCESSORIAL' && charge.chargeReferenceId) {
                accessorialPricing[charge.chargeReferenceId] = charge.charge;
                carrierTotalValue += charge.charge.value;
            }
            else if (charge.chargeType === 'FUEL_SURCHARGE') {
                fuelSurcharge.push(charge);
                carrierTotalValue += charge.charge.value;
            }
            else if (charge.chargeType === 'ADDITIONAL' && charge.chargeReferenceId) {
                additional.push(charge);
                carrierTotalValue += charge.charge.value;
            }
            else if (charge.isTaxCharge && charge.recipient !== 'TRUXWEB') {
                taxes.push(charge);
                if (charge.isBillable) {
                    carrierTotalValue += charge.charge.value;
                }
            }
        }
    });
    const sourceTruxwebCharge = truxweb.length ? truxweb[0].charge : charges[0].charge;
    const allTruxwebCharges = [
        ...truxweb,
        ...truxwebAdjustments,
        ...additionalTruxwebCarrier,
        ...additionalTruxwebShipper,
    ];
    const isTruxwebChargeBillable = allTruxwebCharges
        .map(({ isBillable }) => isBillable)
        .includes(true);
    const truxwebSubtotal = {
        charge: Object.assign(Object.assign({}, sourceTruxwebCharge), { isBillable: isTruxwebChargeBillable, value: truxwebSubtotalValue }),
        chargeReferenceId: null,
        chargeType: 'TRUXWEB',
        chargeTypeId: 0,
        isBillable: isTruxwebChargeBillable,
        isChargeActive: true,
        isPriceAdjustment: false,
        isTaxCharge: false,
        quantity: 1,
        recipient: 'TRUXWEB',
    };
    const truxwebTotal = {
        charge: Object.assign(Object.assign({}, sourceTruxwebCharge), { isBillable: isTruxwebChargeBillable, value: truxwebTotalValue }),
        chargeReferenceId: null,
        chargeType: 'TRUXWEB',
        chargeTypeId: 0,
        isBillable: isTruxwebChargeBillable,
        isChargeActive: true,
        isPriceAdjustment: false,
        isTaxCharge: false,
        quantity: 1,
        recipient: 'TRUXWEB',
    };
    const carrierTotal = {
        charge: Object.assign(Object.assign({}, charges[0].charge), { value: carrierTotalValue }),
        chargeReferenceId: null,
        chargeType: 'TRUXWEB',
        chargeTypeId: 0,
        isBillable: true,
        isChargeActive: true,
        isPriceAdjustment: false,
        isTaxCharge: false,
        quantity: 1,
        recipient: 'TRUXWEB',
    };
    return {
        accessorialPricing,
        additional,
        additionalTruxwebCarrier,
        additionalTruxwebShipper,
        adjustments,
        carrierAdjustments,
        carrierTotal,
        creditCardSurcharge,
        equipmentPricing,
        fuelSurcharge,
        serviceFees,
        taxes,
        truxweb,
        truxwebAdjustments,
        truxwebSubtotal,
        truxwebTotal,
    };
};
exports.groupShipmentCharges = groupShipmentCharges;
