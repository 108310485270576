// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceTypeV1 = exports.CurrencyV1 = exports.ShipmentQuoteRequestStatusV1 = void 0;
var ShipmentQuoteRequestStatusV1;
(function (ShipmentQuoteRequestStatusV1) {
    ShipmentQuoteRequestStatusV1["PENDING"] = "PENDING";
    ShipmentQuoteRequestStatusV1["ACCEPTED"] = "ACCEPTED";
    ShipmentQuoteRequestStatusV1["MODIFIED"] = "MODIFIED";
    ShipmentQuoteRequestStatusV1["REFUSED"] = "REFUSED";
    ShipmentQuoteRequestStatusV1["EXPIRED"] = "EXPIRED";
    ShipmentQuoteRequestStatusV1["CANCELLED"] = "CANCELLED";
})(ShipmentQuoteRequestStatusV1 || (exports.ShipmentQuoteRequestStatusV1 = ShipmentQuoteRequestStatusV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
