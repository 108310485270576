"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadParametersSidebar = void 0;
const utils_1 = require("@truxweb/utils");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const LoadParametersSidebar_styles_1 = require("./LoadParametersSidebar.styles");
const LoadParametersSidebar = ({ availableTags, companyLocations, handleEditLoadSubmit, loadParameters, renderCompanyLocation, searchParams, t, }) => {
    const classes = (0, LoadParametersSidebar_styles_1.useStyles)();
    const editLoadControl = (0, react_hook_form_1.useForm)({
        defaultValues: loadParameters,
    });
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [truckloadType, setTruckloadType] = (0, react_1.useState)(loadParameters.loadDefinition.truckloadType);
    const [selectedEquipmentId, setSelectedEquipmentId] = (0, react_1.useState)(loadParameters.loadDefinition.equipmentId);
    const { append, fields: cargoFields, remove, update, } = (0, react_hook_form_1.useFieldArray)({
        control: editLoadControl.control,
        name: 'loadDefinition.cargoSpecifications',
    });
    const accessorialFieldControls = (0, react_hook_form_1.useFieldArray)({
        control: editLoadControl.control,
        name: 'loadDefinition.accessorialSelections',
    });
    const accessorialMetadataFieldControls = (0, react_hook_form_1.useFieldArray)({
        control: editLoadControl.control,
        name: 'loadDefinition.accessorialMetadataSelections',
    });
    const equipmentLookup = (0, react_1.useMemo)(() => {
        const lookup = {};
        Object.values(searchParams.equipment).forEach((record) => {
            lookup[record.id] = record;
        });
        return lookup;
    }, [searchParams]);
    const [availableAccessorials, setAvailableAccessorials] = (0, react_1.useState)(searchParams.shipmentFeaturesEquipmentAccessorials[equipmentLookup[loadParameters.loadDefinition.equipmentId].code].map((accessorialCode) => {
        return searchParams.accessorials[accessorialCode];
    }));
    const handleSubmit = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { loadDefinition } = data;
        const tags = Object.assign({}, (((_a = loadParameters.metadata) === null || _a === void 0 ? void 0 : _a.tags) || {}));
        if (data.shipperTags) {
            tags.SHIPPER = data.shipperTags;
        }
        const equipment = equipmentLookup[loadDefinition.equipmentId];
        const truckloadType = (0, utils_1.getTruckloadTypeFromEquipmentCode)(equipment.code, searchParams);
        const { accessorialMetadataSelections, accessorialSelections } = loadDefinition, others = __rest(loadDefinition, ["accessorialMetadataSelections", "accessorialSelections"]);
        const accessorialIds = accessorialSelections.map(({ id }) => id);
        const accessorialMetadata = accessorialMetadataSelections
            .map((record) => {
            if (!accessorialIds.includes(record.accessorialId))
                return null;
            return {
                accessorialId: record.accessorialId,
                value: record.value,
            };
        })
            .filter((record) => record);
        const updatedLoadDefinition = Object.assign(Object.assign({}, others), { accessorialIds,
            accessorialMetadata,
            truckloadType });
        const update = Object.assign(Object.assign({}, data), { loadDefinition: updatedLoadDefinition, metadata: Object.assign(Object.assign({}, loadParameters.metadata), { tags }) });
        setIsSaving(true);
        yield handleEditLoadSubmit(update);
        setIsSaving(false);
    }), [handleEditLoadSubmit, setIsSaving, equipmentLookup, searchParams, loadParameters]);
    editLoadControl.watch((data) => {
        // Ensure that when an accessorial is selected it is not
        // available to be selected again
        const equipment = equipmentLookup[data.loadDefinition.equipmentId];
        const equipmentAccessorials = searchParams.shipmentFeaturesEquipmentAccessorials[equipment.code]
            .map((accessorialCode) => {
            return searchParams.accessorials[accessorialCode];
        })
            .filter((record) => {
            return record.visibleToShipper;
        });
        const equipmentAccessorialCodes = equipmentAccessorials
            .map((record) => record === null || record === void 0 ? void 0 : record.code)
            .filter((code) => code);
        const selectedAccessorials = data.loadDefinition.accessorialSelections
            .map((record) => record === null || record === void 0 ? void 0 : record.code)
            .filter((code) => code);
        const filteredAccessorialSelections = equipmentAccessorials.filter(({ code }) => {
            return !selectedAccessorials.includes(code);
        });
        setAvailableAccessorials(filteredAccessorialSelections);
        // Ensure that if there are any selected accessorials which are
        // not available for the currently selected equipment, we
        // remove them and any accessorial metadata
        data.loadDefinition.accessorialSelections.forEach((selection, index) => {
            if (!equipmentAccessorialCodes.includes(selection.code)) {
                // remove accessorial
                accessorialFieldControls.remove(index);
                // remove any metadata
                accessorialMetadataFieldControls.fields.forEach((record, metadataIndex) => {
                    if (record.code === selection.code) {
                        accessorialMetadataFieldControls.remove(metadataIndex);
                    }
                });
            }
        });
        // expose the equipment selection and derived truckloadType
        setSelectedEquipmentId(data.loadDefinition.equipmentId);
        setTruckloadType((0, utils_1.getTruckloadTypeFromEquipmentCode)(equipment.code, searchParams));
    });
    const tags = (0, react_1.useMemo)(() => {
        var _a, _b;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Box, { mb: 1 },
                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:tags'))),
            react_1.default.createElement(__1.EntityTags, { availableTags: availableTags || [], control: editLoadControl.control, name: 'shipperTags', tags: ((_b = (_a = loadParameters.metadata) === null || _a === void 0 ? void 0 : _a.tags) === null || _b === void 0 ? void 0 : _b.SHIPPER) || [] })));
    }, [editLoadControl, loadParameters, availableTags, t]);
    return (react_1.default.createElement("form", { onSubmit: editLoadControl.handleSubmit(handleSubmit) },
        react_1.default.createElement(ux_1.Box, { mb: 3 },
            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, Boolean(loadParameters === null || loadParameters === void 0 ? void 0 : loadParameters.id) ? t('common:editLoad') : t('common:addNewLoad')))),
        react_1.default.createElement(ux_1.FlatCard, null,
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { control: editLoadControl.control, defaultValue: loadParameters.loadDefinition.name, id: 'loadDefinition.name', inputProps: { shrink: 1 }, label: t('common:name'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "text" })),
            tags,
            react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: editLoadControl.control, name: 'loadDefinition.originId', render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(LoadParametersSidebarLocation, { companyLocations: companyLocations, locationType: schemas_1.EShipmentLocationTypeV1.ORIGIN, onChange: onChange, renderCompanyLocation: renderCompanyLocation, t: t, value: value }));
                    }, rules: { required: true } })),
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: editLoadControl.control, name: 'loadDefinition.destinationId', render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(LoadParametersSidebarLocation, { companyLocations: companyLocations, locationType: schemas_1.EShipmentLocationTypeV1.DESTINATION, onChange: onChange, renderCompanyLocation: renderCompanyLocation, t: t, value: value }));
                    }, rules: { required: true } })),
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: editLoadControl.control, name: 'loadDefinition.equipmentId', render: ({ field: { onChange, value } }) => {
                        const equipmentRecord = equipmentLookup[value];
                        const equipmentSelect = (react_1.default.createElement(ux_1.TruxwebSelect, { fullWidth: true, onChange: onChange, value: value, variant: "outlined" }, Object.values(equipmentLookup).map((equipment) => {
                            return (react_1.default.createElement(ux_1.MenuItem, { key: equipment.id, value: equipment.id }, t(`search:${equipment.code}_TITLE`)));
                        })));
                        return (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(ux_1.Box, { mb: 1 },
                                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t(`common:equipment`))),
                            react_1.default.createElement(ux_1.Box, { className: classes.equipmentCard, pb: 4, pt: 4 },
                                react_1.default.createElement(ux_1.EquipmentCardContent, { equipmentCode: equipmentRecord.code, height: 137, isSecondary: false, name: react_1.default.createElement(ux_1.Box, { style: { width: '226px' } }, equipmentSelect) }))));
                    }, rules: { required: true } })),
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(ux_1.Box, { mb: 1 },
                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t(`common:accessorials`))),
                react_1.default.createElement(__1.ShipperAccessorialManagement, { accessorialLookup: searchParams.accessorials, accessorialMetadataControls: accessorialMetadataFieldControls, accessorialsControls: accessorialFieldControls, availableAccessorials: availableAccessorials, control: editLoadControl.control, shouldDisplayMetadata: true, t: t })),
            truckloadType === schemas_1.ETruckloadTypeV1.LTL ? (react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(ux_1.Box, { mb: 1 },
                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t(`common:cargo`))),
                react_1.default.createElement(__1.LtlCargoSpecifications, { control: editLoadControl.control, equipmentCode: equipmentLookup[selectedEquipmentId].code, fields: cargoFields, formAppend: append, formRemove: remove, formValueName: 'loadDefinition.cargoSpecifications', isTemperatureDataRequired: equipmentLookup[selectedEquipmentId].code.includes('REEFER'), loadClassName: classes.equipmentCard, setValue: editLoadControl.setValue, shouldDisplayAll: true, t: t, update: update, watch: editLoadControl.watch }))) : (react_1.default.createElement(react_1.default.Fragment, null)),
            react_1.default.createElement(ux_1.Box, { mt: 3 },
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                    react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }))))));
};
exports.LoadParametersSidebar = LoadParametersSidebar;
const LoadParametersSidebarLocation = ({ companyLocations, locationType, onChange, renderCompanyLocation, t, value, }) => {
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Box, { mb: 1 },
            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t(`common:${locationType.toLowerCase()}`))),
        react_1.default.createElement(ux_1.Box, { mb: 1 },
            react_1.default.createElement(ux_1.TruxwebSelect, { autoWidth: true, fullWidth: true, onChange: onChange, value: value, variant: "outlined" }, companyLocations.map((location) => {
                return (react_1.default.createElement(ux_1.MenuItem, { key: location.id, value: location.id }, (0, utils_1.addressToDisplayString)(location)));
            }))),
        renderCompanyLocation(value)));
};
