"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDashboardOrderSummary = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const utils_1 = require("../../utils");
const __1 = require("..");
const next_i18next_1 = require("next-i18next");
const ShipmentDashboardOrderSummary_styles_1 = require("./ShipmentDashboardOrderSummary.styles");
const ADDITIONAL_CHARGES_TYPES = Object.values(schemas_1.EShipmentAdditionalChargeTypeV1);
const TRUXWEB_CHARGES_SORT = ['TRUXWEB_SHIPPER', 'TRUXWEB', 'GST', 'PST', 'QST', 'HST', 'VAT'];
const ShipmentDashboardOrderSummary = ({ charges, companyType, handleRouteToPage, language, shipmentDetails, subtotal, t, total, }) => {
    const { accessorial, equipment } = shipmentDetails;
    const classes = (0, ShipmentDashboardOrderSummary_styles_1.useStyles)();
    const [feeTypeExplanationShown, setFeeTypeExplanationShown] = (0, react_1.useState)([]);
    const handleToggleFeeExplanation = (0, react_1.useCallback)((feeType) => {
        const fees = [...feeTypeExplanationShown.slice(0)];
        const feeIndex = fees.findIndex((item) => item === feeType);
        if (feeIndex < 0) {
            setFeeTypeExplanationShown([...fees, feeType]);
            return;
        }
        fees.splice(feeIndex, 1);
        setFeeTypeExplanationShown(fees);
    }, [feeTypeExplanationShown, setFeeTypeExplanationShown]);
    const regroupedCharges = (0, react_1.useMemo)(() => {
        return (0, utils_1.groupShipmentCharges)(charges, companyType);
    }, [charges, companyType]);
    const { accessorialPricing, additional, additionalTruxwebCarrier, additionalTruxwebShipper, adjustments, creditCardSurcharge, equipmentPricing, fuelSurcharge, serviceFees, taxes, truxweb, truxwebAdjustments, truxwebSubtotal, truxwebTotal, } = regroupedCharges;
    const displayCompanyType = companyType === schemas_1.EUserTypeV1.TRUXWEB ? schemas_1.EUserTypeV1.CARRIER : companyType;
    const companyAdditionalCharges = [...additional];
    const truxwebAdditionalCharges = companyType === schemas_1.EUserTypeV1.CARRIER ? additionalTruxwebCarrier : additionalTruxwebShipper;
    const getFeeExplanationChildren = (0, react_1.useCallback)((fee) => {
        return fee.chargeType === 'TRUXWEB' && fee.chargeReference !== 'SHIPPER_CHARGE_ADJUSTMENT' ? (react_1.default.createElement("button", { className: classes.button, onClick: () => handleRouteToPage(`feeExplanation-${fee.chargeType}`), type: "button" },
            react_1.default.createElement(ux_1.Typography, null, t('common:here')))) : null;
    }, [classes, t, handleRouteToPage]);
    /* CREDIT_CARD_SURCHARGE
      NOTE: There is the assumption that there is only a single credit card surcharge
    */
    const creditCardSurchargeDisplay = Boolean(creditCardSurcharge.length) ? (react_1.default.createElement(ux_1.Grid, { item: true },
        react_1.default.createElement(ux_1.Box, { mb: 0.5 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:charge-CREDIT_CARD_SURCHARGE`))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                        react_1.default.createElement(__1.PriceDisplay, { language: language, price: creditCardSurcharge[0].charge }))))))) : null;
    if (companyType === schemas_1.EUserTypeV1.TRUXWEB) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Grid, { item: true }, [
                ...truxweb,
                ...(companyType === schemas_1.EUserTypeV1.TRUXWEB ? truxwebAdjustments : []),
                ...additionalTruxwebShipper,
                ...additionalTruxwebCarrier,
            ]
                .sort((chargeA, chargeB) => {
                return (TRUXWEB_CHARGES_SORT.indexOf(chargeA.chargeType) -
                    TRUXWEB_CHARGES_SORT.indexOf(chargeB.chargeType));
            })
                .map((charge, index) => {
                let chargeTitle = t(`common:charge-${charge.chargeType}`);
                let chargeTitleSuffix = '';
                if (['TRUXWEB_SHIPPER', 'TRUXWEB_CARRIER'].includes(charge.chargeType)) {
                    const chargeTitlePieces = [
                        'additionalchargetype',
                        charge.recipient,
                        charge.chargeType,
                    ];
                    if (charge.chargeReference) {
                        chargeTitlePieces.push(charge.chargeReference);
                    }
                    chargeTitle = t(`shipments:${chargeTitlePieces.join('-')}`);
                }
                else if (charge.chargeReference === 'CARRIER_CHARGE_ADJUSTMENT') {
                    const localeString = charge.charge.value > 0 ? 'delayedPaymentDiscount' : 'advancedPaymentSurcharge';
                    chargeTitle = t(`common:CARRIER-${localeString}`);
                    chargeTitleSuffix = `(${t('common:carrier')})`;
                }
                else if (charge.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
                    chargeTitle = t(`common:adminFees`);
                    chargeTitleSuffix = `(${t('common:shipper')})`;
                }
                return (react_1.default.createElement(ux_1.Box, { key: `${charge.recipient}-${charge.chargeTypeId}-${index}`, mb: 0.5 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle },
                                chargeTitle,
                                " ",
                                chargeTitleSuffix)),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                react_1.default.createElement(__1.PriceDisplay, { language: language, price: charge.isBillable ? charge.charge : Object.assign(Object.assign({}, charge.charge), { value: 0 }) }))))));
            })),
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Divider, null)),
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:subtotal`))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                react_1.default.createElement(__1.PriceDisplay, { language: language, price: truxwebSubtotal.charge.isBillable
                                        ? truxwebSubtotal.charge
                                        : Object.assign(Object.assign({}, truxwebSubtotal.charge), { value: 0 }) })))))),
            react_1.default.createElement(ux_1.Grid, { item: true }, taxes.map((tax, index) => {
                return (react_1.default.createElement(ux_1.Box, { key: `${tax.recipient}-${tax.chargeTypeId}-${index}`, mb: 0.5 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:charge-${tax.chargeType}`))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                react_1.default.createElement(__1.PriceDisplay, { language: language, price: tax.isBillable ? tax.charge : Object.assign(Object.assign({}, tax.charge), { value: 0 }) }))))));
            })),
            creditCardSurchargeDisplay,
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { className: classes.totalRowItem, fontStyle: "semibold", variant: "bodyLarge" }, t(`common:total`))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyLarge" },
                                react_1.default.createElement(__1.PriceDisplay, { language: language, price: truxwebTotal.charge }))))))));
    }
    return (react_1.default.createElement(ux_1.Box, { style: { position: 'relative' } },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mb: 1 },
                        react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowHeader }, t('common:freightCost')))),
                equipment.map((equip) => {
                    if (!equip || !equipmentPricing[equip.equipmentId])
                        return null;
                    return (react_1.default.createElement(ux_1.Box, { key: `chargeFor-${equip.equipmentCode}`, mb: 0.5 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:${displayCompanyType}-equipment-${equip.equipmentCode}`))),
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: equipmentPricing[equip.equipmentId] }))))));
                }),
                accessorial.length !== 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mb: 1 },
                                react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowHeader }, t('common:additionalServicesHeader')))),
                        accessorial.map((accessorial) => {
                            const price = accessorialPricing[accessorial.accessorialId] || accessorial.price;
                            if (!price)
                                return null;
                            return (react_1.default.createElement(ux_1.Grid, { item: true, key: accessorial.accessorialCode },
                                react_1.default.createElement(ux_1.Box, { mb: 0.5 },
                                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                        react_1.default.createElement(ux_1.Grid, { item: true },
                                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:${displayCompanyType}-accessorial-${accessorial.accessorialCode}`))),
                                        react_1.default.createElement(ux_1.Grid, { item: true },
                                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                                react_1.default.createElement(__1.PriceDisplay, { language: language, price: price, valueFormatter: (displayValue, value) => {
                                                        if (shipmentDetails.shipment.isAllInRate &&
                                                            !parseFloat(`${value}`)) {
                                                            return t('common:includedAbbr');
                                                        }
                                                        return displayValue;
                                                    } })))))));
                        })))),
                adjustments.length > 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mb: 1 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowHeader }, t('common:priceAdjustments')))),
                    adjustments.map((chargeAdjustment, idx) => {
                        const chargeCompanyType = displayCompanyType;
                        let localeString = '';
                        if (companyType === schemas_1.EUserTypeV1.CARRIER) {
                            localeString =
                                chargeAdjustment.charge.value > 0
                                    ? 'delayedPaymentDiscount'
                                    : 'advancedPaymentSurcharge';
                        }
                        else if (companyType === schemas_1.EUserTypeV1.SHIPPER) {
                            localeString =
                                chargeAdjustment.charge.value > 0
                                    ? 'delayedPaymentSurcharge'
                                    : 'advancedPaymentDiscount';
                        }
                        return (react_1.default.createElement(ux_1.Box, { key: idx, mb: 0.5 },
                            react_1.default.createElement(ux_1.Grid, { item: true, key: idx },
                                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between" },
                                    react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, item: true, xs: 8 },
                                        react_1.default.createElement(ux_1.Grid, { item: true },
                                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle, component: "span" }, t(`common:${chargeCompanyType}-${localeString}`))),
                                        react_1.default.createElement(ux_1.Grid, { item: true },
                                            react_1.default.createElement("button", { onClick: () => {
                                                    handleToggleFeeExplanation(`${companyType}-${localeString}`);
                                                }, style: {
                                                    background: 'transparent',
                                                    border: 0,
                                                    cursor: 'pointer',
                                                    display: 'inline',
                                                } },
                                                feeTypeExplanationShown.includes(chargeAdjustment.chargeType) && (react_1.default.createElement(ux_1.HelpIcon, { color: "primary", fontSize: "small" })),
                                                !feeTypeExplanationShown.includes(chargeAdjustment.chargeType) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:whatsThis') },
                                                    react_1.default.createElement(ux_1.HelpIcon, { color: "primary", fontSize: "small" })))))),
                                    react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: 4 },
                                        react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                            react_1.default.createElement(__1.PriceDisplay, { language: language, price: chargeAdjustment.charge }))))),
                            react_1.default.createElement(ux_1.Collapse, { in: feeTypeExplanationShown.includes(`${companyType}-${localeString}`) },
                                react_1.default.createElement(ux_1.Box, { className: classes.serviceFeeExplanation, mt: 2, p: 2 },
                                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight", component: "span", fontStyle: "semibold" }, t(`common:serviceFeeExplanation-${companyType}-${localeString}-title`)),
                                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight" },
                                        react_1.default.createElement(next_i18next_1.Trans, { i18nKey: `common:serviceFeeExplanation-${companyType}-${localeString}-description` }, getFeeExplanationChildren(Object.assign(Object.assign({}, chargeAdjustment), { chargeType: `${companyType}-${localeString}` }))))))));
                    }))),
                react_1.default.createElement(ux_1.Grid, { item: true }, fuelSurcharge.map((fee, index) => {
                    const chargeTitle = t(`common:charge-${fee.chargeType}`);
                    return (react_1.default.createElement(ux_1.Box, { key: `${fee.recipient}-${fee.chargeTypeId}-${index}`, mb: 0.5 },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between" },
                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, xs: true },
                                react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle, component: "span" }, chargeTitle),
                                react_1.default.createElement("button", { onClick: () => {
                                        handleToggleFeeExplanation(fee.chargeType);
                                    }, style: {
                                        background: 'transparent',
                                        border: 0,
                                        cursor: 'pointer',
                                        display: 'inline',
                                    } },
                                    feeTypeExplanationShown.includes(fee.chargeType) && (react_1.default.createElement(ux_1.HelpIcon, { color: "primary", fontSize: "small" })),
                                    !feeTypeExplanationShown.includes(fee.chargeType) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:whatsThis') },
                                        react_1.default.createElement(ux_1.HelpIcon, { color: "primary", fontSize: "small" }))))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: fee.charge, valueFormatter: (displayValue, value) => {
                                            if (shipmentDetails.shipment.isAllInRate && !parseFloat(`${value}`)) {
                                                return t('common:includedAbbr');
                                            }
                                            return displayValue;
                                        } })))),
                        react_1.default.createElement(ux_1.Collapse, { in: feeTypeExplanationShown.includes(fee.chargeType) },
                            react_1.default.createElement(ux_1.Box, { className: classes.serviceFeeExplanation, mt: 2, p: 2 },
                                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold" }, t(`common:serviceFeeExplanation-${fee.chargeType}-title`)),
                                react_1.default.createElement(ux_1.Typography, { color: "primaryLight" },
                                    react_1.default.createElement(next_i18next_1.Trans, { i18nKey: `common:serviceFeeExplanation-${fee.chargeType}-description` }, getFeeExplanationChildren(fee)))))));
                })),
                react_1.default.createElement(ux_1.Grid, { item: true }, serviceFees.map((fee, index) => {
                    let chargeTitle = fee.chargeType === 'TRUXWEB_SHIPPER'
                        ? t(`shipments:additionalchargetype-${fee.recipient}-${fee.chargeReference}`)
                        : t(`common:charge-${fee.chargeType}`);
                    if (fee.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
                        chargeTitle = t('common:adminFees');
                    }
                    const feeExplanationReference = fee.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT' ? 'adminFees' : fee.chargeType;
                    return (react_1.default.createElement(ux_1.Box, { key: `${fee.recipient}-${fee.chargeTypeId}-${index}`, mb: 0.5 },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between" },
                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, xs: true },
                                react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle, component: "span" }, chargeTitle),
                                react_1.default.createElement("button", { onClick: () => {
                                        handleToggleFeeExplanation(fee.chargeType);
                                    }, style: {
                                        background: 'transparent',
                                        border: 0,
                                        cursor: 'pointer',
                                        display: 'inline',
                                    } },
                                    feeTypeExplanationShown.includes(feeExplanationReference) && (react_1.default.createElement(ux_1.HelpIcon, { color: "primary", fontSize: "small" })),
                                    !feeTypeExplanationShown.includes(feeExplanationReference) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:whatsThis') },
                                        react_1.default.createElement(ux_1.HelpIcon, { color: "primary", fontSize: "small" }))))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: fee.charge })))),
                        react_1.default.createElement(ux_1.Collapse, { in: feeTypeExplanationShown.includes(fee.chargeType) },
                            react_1.default.createElement(ux_1.Box, { className: classes.serviceFeeExplanation, mt: 2, p: 2 },
                                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold" }, t(`common:serviceFeeExplanation-${feeExplanationReference}-title`)),
                                react_1.default.createElement(ux_1.Typography, { color: "primaryLight" },
                                    react_1.default.createElement(next_i18next_1.Trans, { i18nKey: `common:serviceFeeExplanation-${feeExplanationReference}-description` }, getFeeExplanationChildren(fee)))))));
                })),
                companyAdditionalCharges.length > 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mb: 1 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowHeader }, companyType === schemas_1.EUserTypeV1.SHIPPER
                                ? t('shipments:additionalCarrierCharges')
                                : t('shipments:additionalCharges')))),
                    companyAdditionalCharges.map((additionalCharge, idx) => {
                        const chargeTitle = additionalCharge.chargeReference &&
                            (ADDITIONAL_CHARGES_TYPES.includes(additionalCharge.chargeReference) ||
                                [`TRUXWEB_${companyType}`].includes(additionalCharge.chargeType))
                            ? t(`shipments:additionalchargetype-${additionalCharge.recipient}-${additionalCharge.chargeReference}`)
                            : t('shipments:additionalCharges');
                        return (react_1.default.createElement(ux_1.Box, { key: idx, mb: 0.5 },
                            react_1.default.createElement(ux_1.Grid, { item: true, key: idx },
                                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, chargeTitle)),
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                            react_1.default.createElement(__1.PriceDisplay, { language: language, price: additionalCharge.charge })))))));
                    }))),
                truxwebAdditionalCharges.length > 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mb: 1 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowHeader }, t('shipments:additionalTruxwebCharges')))),
                    truxwebAdditionalCharges.map((additionalCharge, idx) => {
                        const chargeTitle = additionalCharge.chargeReference &&
                            (ADDITIONAL_CHARGES_TYPES.includes(additionalCharge.chargeReference) ||
                                [`TRUXWEB_${companyType}`].includes(additionalCharge.chargeType))
                            ? t(`shipments:additionalchargetype-${additionalCharge.recipient}-${additionalCharge.chargeReference}`)
                            : t('shipments:additionalCharges');
                        return (react_1.default.createElement(ux_1.Box, { key: idx, mb: 0.5 },
                            react_1.default.createElement(ux_1.Grid, { item: true, key: idx },
                                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, chargeTitle)),
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                            react_1.default.createElement(__1.PriceDisplay, { language: language, price: additionalCharge.charge })))))));
                    }))),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Divider, null)),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mb: 0.5 },
                            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                react_1.default.createElement(ux_1.Grid, { item: true },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:subtotal`))),
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: subtotal })))))),
                react_1.default.createElement(ux_1.Grid, { item: true }, taxes.map((tax, index) => {
                    return (react_1.default.createElement(ux_1.Box, { key: `${tax.recipient}-${tax.chargeTypeId}-${index}`, mb: 0.5 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:charge-${tax.chargeType}`))),
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: tax.charge }))))));
                })),
                creditCardSurchargeDisplay)),
        react_1.default.createElement(ux_1.Box, { mt: 2 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.totalRowItem, fontStyle: "semibold", variant: "bodyLarge" }, t('common:total'))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyLarge" },
                        react_1.default.createElement(__1.PriceDisplay, { className: classes.totalRowItem, language: language, price: total })))))));
};
exports.ShipmentDashboardOrderSummary = ShipmentDashboardOrderSummary;
