"use strict";
/* eslint-disable complexity */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestDetails = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const QuoteRequestDetails_styles_1 = require("./QuoteRequestDetails.styles");
const COMMENT_LENGTH = 1000;
const QuoteRequestDetails = ({ accessorialData, additionalContent, canSaveSearches, canSetQuoteTaxExemptStatus, company, completedQuoteDisplay, confirmationText, customMetadataHandlers, equipmentCode, handleNavigateBack, handleRequestQuote, hasCompleted, isComplete, isSaving, isTemperatureDataRequired, language, queryParams, searchAccessorials, selectedBookings, shouldForceFormUpdate, t, }) => {
    var _a, _b, _c, _d;
    const classes = (0, QuoteRequestDetails_styles_1.useStyles)();
    const [isSubmitDisabled, setSubmitDisabled] = (0, react_1.useState)(false);
    const [isConfirmationShown, setConfirmationShown] = (0, react_1.useState)(false);
    const [isTaxExemptConfirmationRequired, setIsTaxExemptConfirmationRequired] = (0, react_1.useState)(false);
    const { shipmentDates } = queryParams || {};
    const selectedCarriers = (0, react_1.useMemo)(() => {
        return selectedBookings.map(({ carrierId, carrierName, estimatedTransitTime, price }) => {
            return {
                carrierName,
                estimatedTransitTime,
                id: carrierId,
                isSelected: true,
                price,
            };
        });
    }, [selectedBookings]);
    const accessorials = (0, react_1.useMemo)(() => {
        // We need to determine all of the accessorials which
        // were requested for this shipment
        const { accessorialMetadata, accessorials } = searchAccessorials;
        // There are accessorials which can have their metadata specified
        // before the finalization of the request and we want to restore
        // those parameter here, so create a lookup map to aid in that
        const accessorialMetadataLookup = {};
        (accessorialMetadata || []).forEach((record) => {
            accessorialMetadataLookup[record.accessorialId] = record;
        });
        const existingMetadataLookup = {};
        if (queryParams.loadDefinition && queryParams.loadDefinition.accessorialMetadata) {
            queryParams.loadDefinition.accessorialMetadata.forEach((record) => {
                if (record) {
                    existingMetadataLookup[record.accessorialId] = record;
                }
            });
        }
        const accesorialIds = (accessorials || [])
            .map(({ accessorialId }) => accessorialId)
            .filter((accessorialId) => accessorialId);
        return Object.values(accessorialData)
            .filter(({ id }) => {
            return accesorialIds.includes(id);
        })
            .map((accessorial) => {
            var _a;
            if (!((_a = accessorial === null || accessorial === void 0 ? void 0 : accessorial.metadata) === null || _a === void 0 ? void 0 : _a.length)) {
                return {
                    accessorial,
                    hasMetadata: false,
                    isDisabled: false,
                    isRequired: false,
                    metadataName: '',
                    metadataType: schemas_1.EMetadataTypeV1.STRING,
                    metadataValue: '',
                };
            }
            const selectedMetadata = accessorialMetadataLookup[accessorial.id || 0];
            return accessorial.metadata.map((entry) => {
                var _a;
                return {
                    accessorial,
                    hasMetadata: true,
                    // If the metadata was specified before it reaches
                    // this stage of confirmation, it cannot be altered.
                    // This is becauase if the metadata was specified
                    // it actually affects the availability of the search
                    // result, so changing it at this point is non-sensical.
                    //
                    // This behaviour is isolated to `OPTIONAL` metadata
                    isDisabled: entry.metadataType === schemas_1.EMetadataTypeV1.OPTIONAL,
                    isRequired: entry.required,
                    metadataName: entry.metadataName,
                    metadataType: entry.metadataType,
                    metadataValue: (selectedMetadata === null || selectedMetadata === void 0 ? void 0 : selectedMetadata.value) || ((_a = existingMetadataLookup[accessorial.id || 0]) === null || _a === void 0 ? void 0 : _a.value),
                };
            });
        })
            .flat()
            .sort((a, b) => {
            if (a.hasMetadata && b.hasMetadata) {
                return 0;
            }
            if (a.hasMetadata && !b.hasMetadata) {
                return -1;
            }
            return 1;
        });
    }, [accessorialData, searchAccessorials, queryParams]);
    const { control, formState: { errors }, handleSubmit, setValue, watch, } = (0, react_hook_form_1.useForm)({
        defaultValues: {
            accessorial: accessorials || [],
            carrier: selectedCarriers || [],
            isBookingDateFlexible: Boolean(queryParams.loadDefinition)
                ? (_a = queryParams.loadDefinition) === null || _a === void 0 ? void 0 : _a.isBookingDateFlexible
                : true,
            isTaxExempt: Boolean(queryParams.loadDefinition)
                ? (_b = queryParams.loadDefinition) === null || _b === void 0 ? void 0 : _b.isTaxExempt
                : false,
            loadDefinition: queryParams.loadDefinition,
            shipperNote: '',
        },
    });
    const areAccessorialsRequired = (0, react_1.useMemo)(() => {
        return Boolean(((queryParams === null || queryParams === void 0 ? void 0 : queryParams.allRequestedAccessorialIds) || []).length);
    }, [queryParams]);
    // Note: this check should be abtracted out
    const areCargoDefinitionsRequired = queryParams.shipmentType === schemas_1.ETruckloadTypeV1.LTL;
    const { fields: carrierFields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'carrier',
    });
    const { fields: accessorialFields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'accessorial',
    });
    const { append, fields: cargoFields, remove, update, } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'loadDefinition.cargoSpecifications',
    });
    const contentDisplay = [
        {
            component: (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(__1.QuoteDetailsCarrierList, { canSetQuoteTaxExemptStatus: canSetQuoteTaxExemptStatus, carrierFields: carrierFields, control: control, equipmentCode: equipmentCode, header: `1. ${t('common:summary')}`, isSelectionDisabled: isComplete, language: language, queryParams: queryParams, shipmentDate: shipmentDates ? shipmentDates[0] : new Date().toString(), shouldDisplayHeaderRow: false, t: t, testId: 'QuoteRequestDetails' }),
                Boolean(additionalContent) && react_1.default.createElement(ux_1.Box, { mb: 4 }, additionalContent))),
            subTitle: '',
            title: '',
        },
    ];
    if (areCargoDefinitionsRequired) {
        contentDisplay.push({
            component: (react_1.default.createElement(__1.LtlCargoSpecifications, { control: control, data: ((_c = queryParams.loadDefinition) === null || _c === void 0 ? void 0 : _c.cargoSpecifications) || [], displayDirection: "row", equipmentCode: equipmentCode, errors: ((_d = errors === null || errors === void 0 ? void 0 : errors.loadDefinition) === null || _d === void 0 ? void 0 : _d.cargoSpecifications) || [], fields: cargoFields, formAppend: append, formRemove: remove, formValueName: 'loadDefinition.cargoSpecifications', isDisplayOnly: true, isTemperatureDataRequired: isTemperatureDataRequired, setValue: setValue, shouldSuppressHeader: true, t: t, update: update, watch: watch })),
            subTitle: '',
            title: t('common:cargo'),
        });
    }
    contentDisplay.push({
        component: (react_1.default.createElement(ux_1.Box, { mt: 2 },
            react_1.default.createElement(__1.QuoteRequestDetailsConfirmAddresses, { control: control, isDisabled: isSaving || isComplete, queryParams: queryParams, t: t, testId: 'QuoteRequestDetails' }))),
        subTitle: '',
        title: t('common:validateAddress'),
    });
    if (areAccessorialsRequired) {
        contentDisplay.push({
            component: (react_1.default.createElement(react_1.default.Fragment, null, accessorialFields.map((accessorialField, index) => {
                return (react_1.default.createElement(ux_1.LineItem, { className: classes.accessorialLineItem, key: accessorialField.accessorial.id },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: accessorialField.metadataValue, name: `accessorial.${index}.metadataValue`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                            return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                                    react_1.default.createElement(ux_1.Box, { className: classes.accessorialDetails, ml: 4 },
                                        react_1.default.createElement(ux_1.Typography, { className: classes.accessorialLineItemText }, t(`common:SHIPPER-accessorial-${accessorialField.accessorial.code}`)))),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: true }, accessorialField.hasMetadata && (react_1.default.createElement(ux_1.Box, { ml: 2.5, mr: 4 },
                                    react_1.default.createElement(__1.QuoteRequestAccessorialMetadata, { accessorial: accessorialField.accessorial, className: classes.accessorialSelect, customMetadataHandlers: customMetadataHandlers, isDisabled: isSaving || isComplete || accessorialField.isDisabled, localizationPrefix: "common:", metadataType: accessorialField.metadataType, onChange: onChange, t: t, value: value })))),
                                react_1.default.createElement(ux_1.Box, { className: classes.iconWrapper }, Boolean(error) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:errorRequiredField') },
                                    react_1.default.createElement(ux_1.InfoOutlinedIcon, { className: classes.errorIcon }))))));
                        }, rules: { required: accessorialField.isRequired } })));
            }))),
            subTitle: t('search:additionalServicesPleaseSelect'),
            title: t('common:accessorialDetails'),
        });
    }
    contentDisplay.push({
        component: (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: `shipperNote`, render: ({ field: { onChange, value } }) => {
                return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                        react_1.default.createElement(ux_1.GreyTextArea, { className: classes.shipperNote, disabled: isSaving || isComplete, inputProps: {
                                'data-testid': `QuoteRequestDetails-SpecialInstructions`,
                                maxLength: COMMENT_LENGTH,
                            }, minRows: 5, multiline: true, onChange: onChange, value: value, variant: "filled" })),
                    react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: 6 },
                        react_1.default.createElement(ux_1.Box, { mr: 3 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.characterCount }, t('common:maximumCharacterCount', { count: COMMENT_LENGTH }))))));
            } })),
        subTitle: '',
        title: t('common:specialInstructions'),
    });
    const content = (react_1.default.createElement("form", { className: classes.content, onSubmit: handleSubmit(handleRequestQuote) },
        hasCompleted && completedQuoteDisplay,
        contentDisplay.map((contentItem, index) => {
            if (index === 0) {
                return react_1.default.createElement("span", { key: index }, contentItem.component);
            }
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Divider, null),
                react_1.default.createElement(ux_1.Box, { key: index, mb: 6.5, mt: 6.5 },
                    react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
                        react_1.default.createElement(ux_1.Typography, { className: classes.primaryLight, fontStyle: "semibold", variant: "bodyLarge" }, `${index + 1}. ${contentItem.title}`),
                        Boolean(contentItem.subTitle) && (react_1.default.createElement(ux_1.Typography, { variant: "body1" }, contentItem.subTitle))),
                    contentItem.component)));
        }),
        react_1.default.createElement(ux_1.Collapse, { in: isConfirmationShown },
            react_1.default.createElement(ux_1.Box, { className: classes.confirmationBox, mb: 3, p: 3 },
                react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, confirmationText || t('common:quoteRequestConfirmation')),
                    react_1.default.createElement(ux_1.Box, { mt: 3 },
                        react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:quoteRequestConfirmationP2')))))),
        react_1.default.createElement(ux_1.Collapse, { in: isTaxExemptConfirmationRequired },
            react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, areOnlyChildrenShown: true, control: control, defaultValue: '', id: `hasTaxExemptConfirmation`, inputProps: { maxLength: 32 }, label: '', shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "string" }, (onChange, value) => {
                return (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                    react_1.default.createElement(ux_1.Box, { mb: 3, ml: 2 },
                        react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", onChange: onChange }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t('common:taxExemptDeclaration', { companyName: company === null || company === void 0 ? void 0 : company.name })) }))));
            })),
        react_1.default.createElement(ux_1.Fade, { in: true },
            react_1.default.createElement(ux_1.Box, { style: { opacity: hasCompleted ? 0 : 1 } },
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.FlatButton, { color: "primaryLight", "data-testid": 'QuoteRequestDetails-BackButton', disabled: isSaving, onClick: handleNavigateBack, variant: "outlined" }, t('common:back'))),
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "flex-end", xs: true },
                        canSaveSearches && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mr: 4, mt: 3 },
                                react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, areOnlyChildrenShown: true, control: control, defaultValue: '', id: `shouldSaveSearch`, label: '', shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "string" }, (onChange, value) => {
                                    return (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                                        react_1.default.createElement(ux_1.Box, { mb: 3, ml: 2 },
                                            react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", onChange: onChange }), label: react_1.default.createElement(ux_1.Box, { mr: 1 }, t('common:saveSearchPrompt')), labelPlacement: "start" }))));
                                })))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.SaveButton, { buttonText: t('common:submit'), color: "primaryLight", customAction: areCargoDefinitionsRequired
                                    ? () => {
                                        if (isConfirmationShown) {
                                            handleSubmit(handleRequestQuote)();
                                        }
                                        else {
                                            setConfirmationShown(true);
                                        }
                                    }
                                    : undefined, isDisabled: isSubmitDisabled, isSaving: isSaving, t: t, testId: 'QuoteRequestDetails' }))))))));
    watch((data) => {
        if (data) {
            const selectedCarriers = data.carrier.filter(({ isSelected }) => {
                return isSelected === true;
            });
            if (selectedCarriers.length === 0) {
                setSubmitDisabled(true);
            }
            else if (selectedCarriers.length !== 0 && isSubmitDisabled) {
                setSubmitDisabled(false);
            }
            if (data.isTaxExempt) {
                setIsTaxExemptConfirmationRequired(true);
                if (!data.hasTaxExemptConfirmation) {
                    setSubmitDisabled(true);
                }
                else if (selectedCarriers.length) {
                    setSubmitDisabled(false);
                }
            }
            else if (!data.isTaxExempt) {
                setIsTaxExemptConfirmationRequired(false);
                if (selectedCarriers.length) {
                    setSubmitDisabled(false);
                }
            }
        }
    });
    (0, react_1.useEffect)(() => {
        if (shouldForceFormUpdate) {
            setValue('carrier', selectedCarriers);
        }
    }, [selectedCarriers, shouldForceFormUpdate, setValue]);
    return (react_1.default.createElement(ux_1.Grid, { alignContent: "center", className: classes.wrapper, container: true, direction: "column" },
        react_1.default.createElement(__1.SearchStepper, { currentStep: schemas_1.ESearchQuoteFormStepsV1.reservation, isComplete: isComplete, t: t }),
        react_1.default.createElement(ux_1.Box, { className: classes.contentWrapper }, content)));
};
exports.QuoteRequestDetails = QuoteRequestDetails;
