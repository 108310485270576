"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaneRateSheetList = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const LaneRateSheetList = ({ handleDownloadRateSheet, handleRemoveRateSheet, handleRouteToRateSheet, locationType, rateSheetLocations, rateSheetRegions, rateSheets, t, }) => {
    var _a;
    const shouldShowActions = (0, react_1.useMemo)(() => {
        if (rateSheets.length > 1)
            return true;
        if (rateSheets[0].rateSheetLocationId || rateSheets[0].rateSheetRegionId)
            return true;
        return !Boolean(rateSheets[0].rateSheet.isGlobal);
    }, [rateSheets]);
    const [isDownloading, setDownloading] = (0, react_1.useState)(null);
    const [isRemoving, setRemoving] = (0, react_1.useState)(null);
    const [locationLookup, regionLookup] = (0, react_1.useMemo)(() => {
        if (!rateSheetLocations && !rateSheetRegions)
            return [{}, {}];
        const lookup = {};
        const regionLookup = {};
        (rateSheetLocations || []).forEach((record) => {
            lookup[record.id] = record;
        });
        (rateSheetRegions || []).forEach((record) => {
            regionLookup[record.id] = record;
        });
        return [lookup, regionLookup];
    }, [rateSheetLocations, rateSheetRegions]);
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: [
            {
                field: 'id',
                headerName: ' ',
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.StandardButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setDownloading(row.id);
                            try {
                                yield handleDownloadRateSheet(row);
                            }
                            finally {
                                setDownloading(null);
                            }
                        }) }, isDownloading === row.id ? react_1.default.createElement(ux_1.CircularProgress, null) : react_1.default.createElement(ux_1.TruxwebDownloadIcon, null)));
                },
                width: 50,
            },
            {
                field: 'name',
                flex: 1,
                headerName: t('common:name'),
                renderCell: ({ row, value }) => {
                    return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center", spacing: 1 },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", onClick: () => {
                                    if (handleRouteToRateSheet) {
                                        handleRouteToRateSheet(row.id);
                                    }
                                }, style: { cursor: 'pointer' } }, value)),
                        Boolean(row.isGlobal) && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Tooltip, { title: t('common:globalRateSheetUsedByLaneExplained') },
                                react_1.default.createElement(ux_1.Box, null,
                                    react_1.default.createElement(ux_1.TruxwebGlobeIcon, null)))))));
                },
            },
            {
                field: 'isLocationIndependent',
                headerName: ' ',
                renderCell: ({ row, value }) => {
                    var _a, _b;
                    if (value)
                        return react_1.default.createElement(react_1.default.Fragment, null);
                    if ((row === null || row === void 0 ? void 0 : row.boundaryType) === schemas_1.ERateSheetBoundaryTypeV1.CITY) {
                        const location = locationLookup[(_a = row === null || row === void 0 ? void 0 : row.laneRateSheet) === null || _a === void 0 ? void 0 : _a.rateSheetLocationId];
                        return (react_1.default.createElement(ux_1.Tooltip, { title: location ? location.name : t('common:isLocationRestricted') },
                            react_1.default.createElement(ux_1.Box, { style: { lineHeight: '64px' } },
                                react_1.default.createElement(ux_1.TruxwebBuildingIcon, null))));
                    }
                    if ((row === null || row === void 0 ? void 0 : row.boundaryType) === schemas_1.ERateSheetBoundaryTypeV1.REGION) {
                        const region = regionLookup[(_b = row === null || row === void 0 ? void 0 : row.laneRateSheet) === null || _b === void 0 ? void 0 : _b.rateSheetRegionId];
                        return (react_1.default.createElement(ux_1.Tooltip, { title: region ? region.name : t('common:isLocationRestricted') },
                            react_1.default.createElement(ux_1.Box, { style: { lineHeight: '64px' } },
                                react_1.default.createElement(ux_1.TruxwebGlobeAltIcon, null))));
                    }
                    return react_1.default.createElement(react_1.default.Fragment, null);
                },
                width: 25,
            },
            {
                field: 'version',
                headerName: t('common:version'),
                renderCell: ({ value }) => {
                    return react_1.default.createElement(ux_1.Typography, null, value);
                },
                width: 25,
            },
            {
                field: 'dateUpdated',
                flex: 1,
                headerName: t('common:dateUpdated'),
                renderCell: ({ value }) => {
                    return react_1.default.createElement(ux_1.Typography, null, (0, utils_1.formatDate)(value));
                },
            },
        ], data: (rateSheets || []).map((record) => {
            let boundaryType = record.boundaryType;
            if (!boundaryType && record.rateSheetLocationId) {
                boundaryType = schemas_1.ERateSheetBoundaryTypeV1.CITY;
            }
            if (!boundaryType && record.rateSheetRegionId) {
                boundaryType = schemas_1.ERateSheetBoundaryTypeV1.REGION;
            }
            return Object.assign(Object.assign({}, record.rateSheet), { boundaryType, index: record.index, isLocationIndependent: !Boolean(record.rateSheetLocationId) && !Boolean(record.rateSheetRegionId), laneRateSheet: record });
        }), gridHeight: 52 * (((_a = (rateSheets || [])) === null || _a === void 0 ? void 0 : _a.length) + 2), isActionsOverlayEnabled: shouldShowActions, renderActionsContent: (row, manageOverlayState) => {
            return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center", style: { height: '100%' } },
                react_1.default.createElement(ux_1.StandardButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setRemoving(row.id);
                        if (handleRemoveRateSheet) {
                            yield handleRemoveRateSheet(Object.assign(Object.assign({}, row), { locationType }));
                        }
                        if (manageOverlayState) {
                            manageOverlayState(false);
                        }
                        setRemoving(null);
                    }) }, isRemoving === row.id ? react_1.default.createElement(ux_1.CircularProgress, { size: 16 }) : t('common:remove'))));
        }, rowHeight: 52, shouldSuppressFooter: true, t: t }));
};
exports.LaneRateSheetList = LaneRateSheetList;
