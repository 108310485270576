/* eslint-disable complexity */
import {
  AccountHeaderItem,
  ComingSoon,
  HeaderMeta,
  LanguageSwitcher,
  Loading,
  PageLink,
  ReleasePopup,
} from '../../components';
import {
  ANNOUNCEMENTS_VERSION,
  APP_VERSION,
  browserSupport,
  IS_BROWSER_DETECTION_ENABLED,
  IS_IN_MAINTENANCE,
  LEFT_MENU_SUMMARY_REFRESH_MS,
} from '../../config';
import {
  Badge,
  Box,
  DashboardPageTemplate,
  Grid,
  Tooltip,
  TruxwebMegaphoneIcon,
  Typography,
  UserProfile,
} from '@truxweb/ux';
import { EPermissionV1, EStatusGenericV1, EUserTypeV1 } from '@truxweb/schemas';
import { hasWindow, pageIdToLocaleString } from '../../utils';
import { logout, updateActiveMenuItem, updateSidebarState } from '../../actions';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  useActiveMenuItem,
  useApplicationLoadingState,
  useAuth,
  useInterval,
  useMenu,
  usePermissions,
  useQuoteCounts,
  useShipmentCredits,
  useSidebarState,
  useUserData,
} from '../../hooks';
import Bowser from 'bowser';
import classnames from 'classnames';
import Head from 'next/head';
import { TPageId } from '../../types';
import { updateApplicationLoadingState } from '../../stores';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './WebAppLayout.styles';
import { useTranslation } from 'next-i18next';
import Wings from '../../public/assets/images/logos/truxweb-wings.svg';

const REQUIRED_NAMESPACES = ['common'];

type TWebAppLayoutProps = {
  children?: React.ReactNode;
  pageId: TPageId;
};
interface IActivePage {
  pageId: string;
  pageStyle: 'centered' | 'fullWidth';
  title?: string;
}

export const WebAppLayout = ({ children, pageId }: TWebAppLayoutProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { companyData, userData } = useUserData();
  const shipmentCredits = useShipmentCredits(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { handleRefetchQuotes } = useQuoteCounts();
  const [hasShipperPermission] = usePermissions([EPermissionV1.VIEW_SHIPPER_PAGE]);
  const [hasCarrierPermission] = usePermissions([EPermissionV1.VIEW_CARRIER_PAGE]);
  const applicationLoadingState = useApplicationLoadingState();

  let haveAnnouncementsBeenAcknowledged = false;
  let haveReleaseAnnoucementsPopShown = false;
  if (hasWindow()) {
    haveAnnouncementsBeenAcknowledged = localStorage.getItem(ANNOUNCEMENTS_VERSION) === 'true';
    haveReleaseAnnoucementsPopShown =
      localStorage.getItem(`${ANNOUNCEMENTS_VERSION}-popup`) === 'true';
  }

  const isLeftMenuOpen = useSidebarState();
  const setIsLeftMenuOpen = useCallback(
    (state: boolean) => {
      dispatch(updateSidebarState(state));
    },
    [dispatch]
  );

  const activeTextMenuIcon = useActiveMenuItem();
  const setActiveTextMenuIcon = useCallback(
    (activeMenuItem: string) => {
      dispatch(updateActiveMenuItem(activeMenuItem));
    },
    [dispatch]
  );
  useAuth();

  const menu = useMenu(isLeftMenuOpen);

  const handleLeftMenuToggle = useCallback(
    (state: boolean) => {
      setIsLeftMenuOpen(state);
    },
    [setIsLeftMenuOpen]
  );

  const handleSignOut = useCallback(async () => {
    dispatch(logout());
    router.push('/');
  }, [dispatch, router]);

  const [localeString, formattedPageId] = pageIdToLocaleString(pageId);

  // Update the quote counts for the sidebar every 30s
  useInterval(handleRefetchQuotes, LEFT_MENU_SUMMARY_REFRESH_MS);

  let pageTitlePrefix = t(localeString);

  if (pageTitlePrefix === formattedPageId) {
    pageTitlePrefix = t('common:siteMap-default');
  }

  const pageTitle = pageId
    ? t('common:appTitle', { pageTitle: `${pageTitlePrefix} - ` })
    : t('common:appTitle', { pageTitle: '' });

  const ProfileIcon = (
    <PageLink pageId={'account'}>
      <UserProfile isSelf={true} userData={userData} />
    </PageLink>
  );
  const subscriptionBadge =
    Boolean(companyData?.subscription) && companyData?.subscription?.isActive ? (
      <Box
        className={classes.pro}
        p={0.25}
        px={2}
        style={{
          bottom: -16,
          position: 'absolute',
          right: -8,
        }}
      >
        <Typography className={classes.proText} variant="bodyXSmall">
          {t('common:pro')}
        </Typography>
      </Box>
    ) : null;

  // NOTE: Reports are current disabled, clicking the logo should direct
  // shippers to the reports page when enabled
  const TruxwebLogo: JSX.Element = hasShipperPermission ? (
    <PageLink pageId={'shipments'}>
      <Tooltip enterDelay={750} leaveDelay={150} placement="right" title={t('common:shipments')}>
        <Box style={{ position: 'relative', top: 'auto' }}>
          <Wings height="27px" />
          {subscriptionBadge}
        </Box>
      </Tooltip>
    </PageLink>
  ) : (
    <PageLink pageId={'shipments'}>
      <Tooltip enterDelay={750} leaveDelay={150} placement="right" title={t('common:shipments')}>
        <Box style={{ position: 'relative' }}>
          <Wings height="27px" />
          {subscriptionBadge}
        </Box>
      </Tooltip>
    </PageLink>
  );

  const activePage: IActivePage = useMemo(() => {
    const menuOptions = Object.keys(menu);

    const page = menuOptions
      .map((key) => {
        return Object.values(menu[key].links);
      })
      .flat()
      .find((menuItem: IActivePage) => {
        return menuItem.pageId === pageId;
      });

    return page as IActivePage;
  }, [menu, pageId]);

  const textMenuContent = activeTextMenuIcon ? (
    <>
      {Object.keys(menu[activeTextMenuIcon].links)
        .filter((key) => {
          if (menu[activeTextMenuIcon].links[key].permissions === undefined) return true;
          return menu[activeTextMenuIcon].links[key].permissions;
        })
        .sort(
          (a, b) =>
            menu[activeTextMenuIcon].links[a].order - menu[activeTextMenuIcon].links[b].order
        )
        .map((link) => (
          <Box data-testid={`MenuItem-${link}`} key={link}>
            {menu[activeTextMenuIcon].links[link].link}
          </Box>
        ))}
    </>
  ) : null;

  const menuItems = Object.keys(menu)
    .filter((key) => !menu[key].maintenanceIcon && menu[key].permissions)
    .sort((a, b) => menu[a].order - menu[b].order)
    .map((key) => {
      return {
        hasNotification: Boolean(menu[key].hasNotification),
        icon: menu[key].icon,
        name: menu[key].name,
        title: menu[key].title,
      };
    });

  const maintenanceItems = Object.keys(menu)
    .filter((key) => menu[key].maintenanceIcon)
    .map((key) => {
      return { icon: menu[key].icon, name: menu[key].name };
    });

  let localizedTitle = t(localeString) !== formattedPageId ? t(localeString) : '';

  const pageContentStyleOverrides: Record<string, 'fullWidth' | 'centered'> = {
    announcements: 'fullWidth',
    'carrier/[carrier]': 'fullWidth',
    report: 'fullWidth',
    shipments: 'fullWidth',
  };

  let titleSupplement = <></>;
  switch (pageId) {
    case 'account':
      titleSupplement = ProfileIcon;
      break;
    default:
      titleSupplement = null;
      break;
  }

  const pageTitleOverrides: Record<string, string> = {
    'account/document': t('common:documents'),
    'account/settings': t('common:settings'),
    loads: t('common:savedLoads'),
    reservation: t('common:search'),
    results: t('common:search'),
  };

  let activePageStyle = activePage?.pageStyle || pageContentStyleOverrides[pageId] || 'centered';
  if (IS_IN_MAINTENANCE) {
    activePageStyle = 'fullWidth';
    localizedTitle = '';
  }

  const companyStatusBanner =
    companyData?.status !== EStatusGenericV1.ACTIVE && companyData?.type === EUserTypeV1.CARRIER ? (
      <Box className={classes.statusBanner} pb={2} pl={8} pr={8} pt={2}>
        <Typography>
          {t('common:carrierStatusWarning', {
            status: t(`common:companyStatus-${companyData.status}`),
          })}
        </Typography>
        <Typography>{t('common:carrierStatusWarningContactSupport')}</Typography>
      </Box>
    ) : (
      <></>
    );

  const announcementLink = (
    <Grid container>
      <Grid item style={{ position: 'relative' }}>
        <TruxwebMegaphoneIcon
          className={
            !haveAnnouncementsBeenAcknowledged
              ? classes.pendingAnnouncementsBottom
              : classes.pendingAnnouncementsBottomAck
          }
        />
        <TruxwebMegaphoneIcon
          className={!haveAnnouncementsBeenAcknowledged ? classes.pendingAnnouncementsTop : null}
        />
      </Grid>
      <Grid item>
        <Box ml={1}>
          <Typography fontStyle="semibold">{t('common:siteMap-announcements')} &nbsp;</Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const announcements = (
    <PageLink pageId="announcements">
      {!haveAnnouncementsBeenAcknowledged && (
        <Badge
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          badgeContent={!haveAnnouncementsBeenAcknowledged ? '' : null}
          color="error"
          variant="dot"
        >
          {announcementLink}
        </Badge>
      )}
      {haveAnnouncementsBeenAcknowledged && announcementLink}
    </PageLink>
  );

  useEffect(() => {
    if (userData === null && !applicationLoadingState.isApplicationLoading) {
      dispatch(
        updateApplicationLoadingState({ ...applicationLoadingState, isApplicationLoading: true })
      );
    }
    if (userData !== null && applicationLoadingState.isApplicationLoading) {
      dispatch(
        updateApplicationLoadingState({ ...applicationLoadingState, isApplicationLoading: false })
      );
    }
  }, [userData, dispatch, applicationLoadingState]);

  if (IS_BROWSER_DETECTION_ENABLED && typeof window !== 'undefined') {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.satisfies(browserSupport)) {
      return <h1 className={classes.unsupportedBrowser}>{t('common:unsupportedBrowser')}</h1>;
    }
  }
  return (
    <>
      {userData && (
        <>
          {(applicationLoadingState.isApplicationLoading ||
            applicationLoadingState.isActionLoading) && (
            <Loading
              actionId={applicationLoadingState.actionId}
              actionType={applicationLoadingState.actionType}
              isActionLoading={applicationLoadingState.isActionLoading}
              isGlobalLoader={applicationLoadingState.isApplicationLoading}
              isLoading={
                applicationLoadingState.isApplicationLoading ||
                applicationLoadingState.isActionLoading
              }
              loadingPhrases={applicationLoadingState.loadingPhrases}
              staticText={applicationLoadingState.staticText}
            />
          )}
          <Head>
            <title>{pageTitle}</title>
            <HeaderMeta pageId={pageId} t={t} />
          </Head>
          <Grid>
            {hasShipperPermission && !haveReleaseAnnoucementsPopShown && <ReleasePopup />}
            {hasCarrierPermission && !haveReleaseAnnoucementsPopShown && <ReleasePopup />}

            <DashboardPageTemplate
              dashboardHeaderProps={{
                actions: IS_IN_MAINTENANCE ? null : <LanguageSwitcher pageId={pageId} />,
                availableCredits: IS_IN_MAINTENANCE ? 0 : shipmentCredits?.data?.length || 0,
                company: IS_IN_MAINTENANCE ? null : companyData,
                profile: IS_IN_MAINTENANCE ? null : <AccountHeaderItem linkClass={classes.link} />,
                t,
                title: pageTitleOverrides[pageId] || localizedTitle,
                user: IS_IN_MAINTENANCE ? null : userData,
              }}
              iconMenuProps={{
                activeIcon: activeTextMenuIcon,
                isExpanded: isLeftMenuOpen,
                logo: TruxwebLogo,
                maintenanceIcons: maintenanceItems,
                menuItems,
                profile: ProfileIcon,
                setActiveIcon: setActiveTextMenuIcon,
              }}
              internalContents={activePageStyle}
              isInMaintenance={IS_IN_MAINTENANCE}
              isLeftTextMenuOpen={isLeftMenuOpen}
              isMobileEnabled
              logo={TruxwebLogo}
              maintenanceComponent={
                <ComingSoon isFullPageBlur shouldOnlyShowChildren>
                  <>
                    <Typography variant="h3">{t('common:maintenanceMessageLineOne')}</Typography>
                    <Box mt={2}>
                      <Typography>{t('common:maintenanceDescription')}</Typography>
                    </Box>
                  </>
                </ComingSoon>
              }
              menu={menu}
              onLeftMenuStateChange={handleLeftMenuToggle}
              pageBanner={companyStatusBanner}
              textMenuContent={textMenuContent}
              textMenuProps={{
                announcements,
                appVersion: <Typography variant="bodySmall">{`v${APP_VERSION}`}</Typography>,
                handleLogout: handleSignOut,
                legalLinks: (
                  <Grid alignItems="center" container direction="column">
                    <Grid item>
                      <PageLink
                        className={classes.legalLink}
                        pageId={'privacy-policy'}
                        target="legal"
                      >
                        {t('common:privacyPolicy')}
                      </PageLink>
                    </Grid>
                    <Grid item>
                      <PageLink
                        className={classes.legalLink}
                        pageId={'terms-and-conditions'}
                        target="legal"
                      >
                        {t('common:termsAndConditions')}
                      </PageLink>
                    </Grid>
                  </Grid>
                ),
                title: menu[activeTextMenuIcon]?.title || '',
                user: userData,
              }}
              titleSupplement={titleSupplement}
            >
              {() => {
                return (
                  <>
                    <main
                      className={classnames(
                        classes.content,
                        activePageStyle === 'fullWidth' ? classes.fullWidth : null
                      )}
                      role="main"
                    >
                      {children}
                    </main>
                  </>
                );
              }}
            </DashboardPageTemplate>
          </Grid>
        </>
      )}
    </>
  );
};
