import { TPageId } from '../types';

// There needs to be an excessive number of branches here for
// all the cases covered. Over time see if this can be simplfied

export const getPageBackground = (
  pageId: TPageId,
  classes: Record<string, string>
): string | null => {
  let backgroundClass: string | null = null;

  // subroutes
  if (pageId.indexOf('shipments') !== -1) {
    return classes.shipmentsBackground;
  }

  if (pageId.indexOf('fleet') !== -1 || pageId.indexOf('admin') !== -1) {
    return classes.blueGradient;
  }

  switch (pageId) {
    case 'privacy-policy':
      break;
    case 'home':
      backgroundClass = classes.landingPage;
      break;
    case 'terms-and-conditions':
      backgroundClass = classes.backgroundLightblue;
      break;
    case 'carrier/[carrier]':
      backgroundClass = classes.forest;
      break;
    default:
      backgroundClass = classes.backgroundWhite;
  }
  return backgroundClass;
};
