/* eslint-disable import/no-commonjs*/

// NOTE: This is lookup map for all of the routes under `pages`, this is the canonical list
// of pages, it should be used to generate sitemaps etc.
//
// The `keys` of each language should also reflect the locale string for the page
// use for i18n
//
// This is written in JS as it needs to be included by JS and TS code
const localizedRoutes = {
  'en-CA': {
    about: {
      section: 'company',
      url: 'about',
    },
    account: {
      section: 'account',
      url: 'account',
    },
    'account/address': {
      section: 'account',
      url: 'account/address',
    },
    'account/company': {
      section: 'account',
      url: 'account/company',
    },
    'account/document': {
      section: 'account',
      url: 'account/document',
    },
    'account/notifications': {
      section: 'account',
      url: 'account/notifications',
    },
    'account/payment': {
      section: 'account',
      url: 'account/payment',
    },
    'account/profile': {
      section: 'account',
      url: 'account/profile',
    },
    'account/settings': {
      section: 'account',
      url: 'account/settings',
    },
    announcements: {
      section: 'home',
      url: 'announcements',
    },
    'carrier-services': {
      section: 'company',
      url: 'carrier-services',
    },
    'carrier/[carrier]': {
      section: 'search',
      url: 'carrier/[carrier]',
    },
    'contact-us': {
      section: 'contactUs',
      url: 'contact-us',
    },
    fleet: {
      section: 'equipment',
      url: 'fleet',
    },
    'fleet/accessorials': {
      section: 'equipment',
      url: 'fleet/accessorials',
    },
    'fleet/equipment': {
      section: 'equipment',
      url: 'fleet/equipment',
    },
    'fleet/lanes': {
      section: 'equipment',
      url: 'fleet/lanes',
    },
    'fleet/lanes/[laneId]': {
      section: 'equipment',
      url: 'fleet/lanes/[laneId]',
    },
    home: {
      section: 'home',
      url: '',
    },
    investors: {
      section: 'company',
      url: 'investors',
    },
    loads: {
      section: 'search',
      url: 'loads',
    },
    login: {
      section: 'registration',
      url: 'login',
    },
    'privacy-policy': {
      section: 'legal',
      url: 'privacy-policy',
    },
    report: { section: 'home', url: 'reports' },
    requests: { section: 'requests', url: 'requests' },
    'requests/confirmed': { section: 'requests', url: 'requests/confirmed' },
    'requests/examination': { section: 'requests', url: 'requests/examination' },
    'requests/refused': { section: 'requests', url: 'requests/refused' },
    reservation: { section: 'search', url: 'reservation' },
    results: {
      section: 'search',
      url: 'results',
    },
    search: {
      section: 'search',
      url: 'search',
    },
    shipments: {
      section: 'shipments',
      url: 'shipments',
    },
    'shipments/[shipmentRef]': {
      section: 'shipments',
      url: 'shipments/[shipmentRef]',
    },
    'shipments/active': {
      section: 'shipments',
      url: 'shipments/active',
    },
    'shipments/booked': {
      section: 'shipments',
      url: 'shipments/booked',
    },
    'shipments/delivered': {
      section: 'shipments',
      url: 'shipments/delivered',
    },
    'shipments/historical': {
      section: 'shipments',
      url: 'shipments/historical',
    },
    'shipper-services': {
      section: 'company',
      url: 'shipper-services',
    },
    signup: { section: 'registration', url: 'signup' },
    'signup/carrier': { section: 'registration', url: 'signup/carrier' },
    'signup/carrier/confirm': { section: 'registration', url: 'signup/carrier/confirm' },
    'signup/shipper': { section: 'registration', url: 'signup/shipper' },
    'signup/shipper/confirm': { section: 'registration', url: 'signup/shipper/confirm' },
    support: {
      section: 'help',
      url: 'support',
    },
    'terms-and-conditions': {
      section: 'legl',
      url: 'terms-and-conditions',
    },
  },
  'fr-CA': {
    about: {
      section: 'company',
      url: 'a-propos',
    },
    account: {
      section: 'account',
      url: 'compte',
    },
    'account/address': {
      section: 'account',
      url: 'compte/adresse',
    },
    'account/company': {
      section: 'account',
      url: 'compte/enterprise',
    },
    'account/document': {
      section: 'account',
      url: 'compte/document',
    },
    'account/notifications': {
      section: 'account',
      url: 'compte/notifications',
    },
    'account/settings': {
      section: 'account',
      url: 'compte/settings',
    },
    'account/payment': {
      section: 'account',
      url: 'compte/paiement',
    },
    'account/profile': {
      section: 'account',
      url: 'compte/profil',
    },
    announcements: {
      section: 'home',
      url: 'annonces',
    },
    'carrier-services': {
      section: 'company',
      url: 'carrier-services',
    },
    'carrier/[carrier]': {
      section: 'search',
      url: 'transporteur/[carrier]',
    },
    'contact-us': {
      section: 'contactUs',
      url: 'contactez-nous',
    },
    fleet: {
      section: 'equipment',
      url: 'flotte',
    },
    'fleet/accessorials': {
      section: 'equipment',
      url: 'flotte/accessoires',
    },
    'fleet/equipment': {
      section: 'equipment',
      url: 'flotte/equipement',
    },
    'fleet/lanes': {
      section: 'equipment',
      url: 'flotte/voies',
    },
    'fleet/lanes/[laneId]': {
      section: 'equipment',
      url: 'flotte/voies/[laneId]',
    },
    home: {
      section: 'home',
      url: '',
    },
    investors: {
      section: 'company',
      url: 'investors',
    },
    loads: {
      section: 'search',
      url: 'chargements',
    },
    login: {
      section: 'registration',
      url: 'connexion',
    },
    'privacy-policy': {
      section: 'equipment',
      url: 'politique-de-confidentialite',
    },
    report: { section: 'home', url: 'rapport' },
    requests: {
      section: 'requests',
      url: 'requetes',
    },
    'requests/confirmed': {
      section: 'requests',
      url: 'requetes/confirmees',
    },
    'requests/examination': {
      section: 'requests',
      url: 'requetes/examination',
    },
    'requests/refused': {
      section: 'requests',
      url: 'requetes/refusees',
    },
    reservation: {
      section: 'search',
      url: 'reservation',
    },
    results: {
      section: 'search',
      url: 'resultats',
    },
    search: {
      section: 'search',
      url: 'recherche',
    },
    shipments: {
      section: 'shipments',
      url: 'expeditions',
    },
    'shipments/[shipmentRef]': {
      section: 'shipments',
      url: 'expeditions/[shipmentRef]',
    },
    'shipments/active': {
      section: 'shipments',
      url: 'expeditions/actif',
    },
    'shipments/booked': {
      section: 'shipments',
      url: 'expeditions/reserve',
    },
    'shipments/delivered': {
      section: 'shipments',
      url: 'expeditions/livree',
    },
    'shipments/historical': {
      section: 'shipments',
      url: 'expeditions/historique',
    },
    'shipper-services': {
      section: 'company',
      url: 'shipper-services',
    },
    signup: {
      section: 'registration',
      url: 'inscription',
    },
    'signup/carrier': { section: 'registration', url: 'inscription/transporteur' },
    'signup/carrier/company': {
      section: 'registration',
      url: 'inscription/transporteur/entreprise',
    },
    'signup/carrier/confirm': {
      section: 'registration',
      url: 'inscription/transporteur/confirmer',
    },
    'signup/shipper': { section: 'registration', url: 'inscription/expediteur' },
    'signup/shipper/confirm': { section: 'registration', url: 'inscription/expediteur/confirmer' },

    support: {
      section: 'help',
      url: 'support',
    },
    'terms-and-conditions': {
      section: 'legal',
      url: 'terms-et-conditions',
    },
  },
};

module.exports = { localizedRoutes };
