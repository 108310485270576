import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import {
  DashboardUpdatesMarketing,
  LandingPageBookADemo,
  LoadPlanningMarketing,
  PageLink,
  PermissionGuard,
  QuoteChatMarketing,
  ShipmentCreditsMarketing,
  ShipmentReportMarketing,
  SubscriptionMarketing,
} from '../../components';
import {
  DATE_FORMAT_STRINGS_SHORT,
  formatLocalizedDate,
  transformI18nLocaleToLanguage,
} from '@truxweb/utils';
import React, { useMemo } from 'react';
import { useHomeStyles, useLandingPageMarginStyles } from '../../styles';
import { ANNOUNCEMENTS_VERSION } from '../../config';
import { AuthorizedPage } from '../../pageTemplates';
import Cloud from '../../public/assets/images/landingPage/cloud.svg';
import { EPermissionV1 } from '@truxweb/schemas';
import { hasWindow } from '../../utils';
import Image from 'next/image';
import { type TPageId } from '../../types';
import { useStyles } from './AnnouncementsPage.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TAnnouncementsPageProps = {
  pageId: TPageId;
};

export const AnnouncementsPage = ({ pageId }: TAnnouncementsPageProps): JSX.Element => {
  const classes = useHomeStyles();
  const localClasses = useStyles();
  const margins = useLandingPageMarginStyles();
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);

  const language = useMemo(() => {
    return transformI18nLocaleToLanguage(i18n.language);
  }, [i18n]);

  const theme = useTheme();
  const isSmallTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  if (hasWindow()) {
    localStorage.setItem(ANNOUNCEMENTS_VERSION, 'true');
  }

  const annoucementsItems = [
    {
      component: (
        <Grid container item xs>
          <DashboardUpdatesMarketing size="lg" />
        </Grid>
      ),
      date: '2024-05-01T04:00:00Z',
      permissions: [EPermissionV1.VIEW_CARRIER_PAGE],
    },
    {
      component: (
        <Grid container item xs>
          <DashboardUpdatesMarketing size="lg" />
        </Grid>
      ),
      date: '2024-05-01T04:00:00Z',
      permissions: [EPermissionV1.VIEW_SHIPPER_PAGE],
    },
    {
      component: (
        <Grid container item xs>
          <QuoteChatMarketing size="lg" />
        </Grid>
      ),
      date: '2024-03-28T04:00:00Z',
      permissions: [EPermissionV1.VIEW_CARRIER_PAGE],
    },
    {
      component: (
        <Grid container item xs>
          <QuoteChatMarketing size="lg" />
        </Grid>
      ),
      date: '2024-03-28T04:00:00Z',
      permissions: [EPermissionV1.VIEW_SHIPPER_PAGE],
    },
    {
      component: (
        <Grid container item xs>
          <ShipmentReportMarketing size="lg" />
        </Grid>
      ),
      date: '2023-08-29T04:00:00Z',
      permissions: [EPermissionV1.VIEW_SHIPPER_PAGE],
    },
    {
      component: (
        <Grid container item xs>
          <Grid item xs>
            <Box mt={2}>
              <LoadPlanningMarketing shouldSuppressCTA />
            </Box>
          </Grid>
          <Grid item style={{ maxWidth: '365px', position: 'relative' }} xs>
            <Image
              key={`client-logo-1`}
              layout={'fill'}
              objectFit="cover"
              objectPosition={'top left'}
              src={`/assets/images/marketing/SitePreview-SavedLoads-1.png`}
              unoptimized
            />
          </Grid>
        </Grid>
      ),
      date: '2023-08-12T04:00:00Z',
      permissions: [EPermissionV1.VIEW_SHIPPER_PAGE],
    },
    {
      component: (
        <Grid
          alignItems="center"
          className={margins.landingPageMargins}
          container
          item
          justifyContent="center"
          style={{ position: 'relative', zIndex: 0 }}
          xs={12}
        >
          {!isSmallTablet && (
            <Box
              style={{
                bottom: 0,
                left: 256,
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 1,
              }}
            >
              <Box className={classes.cloudOne}>
                <Cloud height={'100%'} width={'100%'} />
              </Box>
              <Box className={classes.cloudTwo}>
                <Cloud height={'100%'} width={'100%'} />
              </Box>
              <Box className={classes.cloudThree}>
                <Cloud height={'100%'} width={'100%'} />
              </Box>
            </Box>
          )}
          <Box p={10}>
            <ShipmentCreditsMarketing />
          </Box>
        </Grid>
      ),
      date: '2023-06-12T04:00:00Z',
      permissions: [EPermissionV1.VIEW_SHIPPER_PAGE],
    },
    {
      component: (
        <Grid
          alignItems="flex-start"
          className={margins.landingPageMargins}
          container
          item
          justifyContent="center"
          xs={12}
        >
          <Grid item xs={6}>
            <Box className={classes.bodyCopyContainer} p={10} pt={0} style={{ marginTop: 0 }}>
              {!isSmallTablet && (
                <>
                  <Box className={classes.cloudOne}>
                    <Cloud height={'100%'} width={'100%'} />
                  </Box>
                  <Box className={classes.cloudTwo}>
                    <Cloud height={'100%'} width={'100%'} />
                  </Box>
                  <Box className={classes.cloudThree}>
                    <Cloud height={'100%'} width={'100%'} />
                  </Box>
                </>
              )}

              <SubscriptionMarketing />
            </Box>
          </Grid>
        </Grid>
      ),
      date: '2023-05-30T04:00:00Z',
      permissions: [EPermissionV1.VIEW_SHIPPER_PAGE],
    },
  ];

  return (
    <AuthorizedPage pageId={pageId}>
      <Box className={localClasses.header} p={3}>
        <Grid container justifyContent="center">
          <Typography color="white" component="span" fontStyle="semibold" variant="bodyLarge">
            {t('common:announcementsHeader')}
          </Typography>
        </Grid>
      </Box>
      <Box mr={8} mt={4} pb={4} style={{ width: '100%' }}>
        <LandingPageBookADemo
          content={{
            cta: t('common:weWantToHearFromYou'),
            link: <PageLink pageId={'contact-us'}>{t('common:contactUs')}</PageLink>,
            prompt: t('common:alwaysImproving'),
          }}
        />
      </Box>
      <Box mt={3}>
        <Grid
          alignItems="flex-start"
          className={classes.container}
          container
          direction="row"
          item
          style={{
            minHeight: '75vh',
            overflow: 'hidden',
            zIndex: 1,
          }}
          xs
        >
          {annoucementsItems.map((item, index) => {
            return (
              <PermissionGuard key={index} permissions={item.permissions}>
                <Grid container direction="column" item xs={12}>
                  <Grid className={localClasses.dateHeader} container item justifyContent="center">
                    <Box p={1}>
                      <Typography color="white" fontStyle="semibold" variant="h3">
                        {formatLocalizedDate(
                          item.date,
                          language,
                          DATE_FORMAT_STRINGS_SHORT[language]
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>{item.component}</Grid>
                </Grid>
              </PermissionGuard>
            );
          })}
        </Grid>
      </Box>
    </AuthorizedPage>
  );
};
