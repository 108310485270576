"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDocumentDetailsDownload = void 0;
const react_1 = __importStar(require("react"));
const ux_1 = require("@truxweb/ux");
const ShipmentDocumentDetailsDownload = ({ documentId, downloadIcon, handleDownloadDocument, onDownloadError, onDownloadSuccess, singleDocumentType, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const onDownloadFile = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const strFile = yield handleDownloadDocument([documentId]);
            if (strFile) {
                const link = document.createElement('a');
                link.href = strFile;
                link.setAttribute(`target`, `_blank`);
                link.setAttribute(`rel`, `noopener noreferrer`);
                document.body.appendChild(link);
                link.click();
                (_a = link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
                onDownloadSuccess();
            }
        }
        catch (err) {
            onDownloadError(err.message);
        }
        finally {
            setIsLoading(false);
        }
    }), [handleDownloadDocument, documentId, onDownloadSuccess, onDownloadError]);
    return (react_1.default.createElement("label", { htmlFor: `${`${singleDocumentType}`}-FileDownload`, style: { marginLeft: '-14px' } },
        react_1.default.createElement(ux_1.Button, { component: "span", disabled: isLoading, onClick: () => onDownloadFile() }, downloadIcon)));
};
exports.ShipmentDocumentDetailsDownload = ShipmentDocumentDetailsDownload;
