import { AlertProvider, AuthProvider, QuoteProvider } from '../contexts';
import { Amplify, Auth } from 'aws-amplify';
import { AWS_CONFIG, IS_SENTRY_RELEASE_ENABLED, SENTRY_DSN } from '../config';
import { CssBaseline, RESPONSIVE_THEME, ThemeProvider, TRUXWEB_THEME_05_2022 } from '@truxweb/ux';
import { init, setUser } from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { type NextPage } from 'next';
import { PageError } from '../pageContents';
import { reduxWrapper } from '../stores';
import { type TTruxwebPageProps } from '../types';
import { useRouter } from 'next/router';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const RESPONSIVE_PAGES = ['/index', '/contact-us', '/', '/contactez-nous', '/heartbeat'];
const TruxwebApp = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
  Amplify.configure(AWS_CONFIG);
  const [authentication, setAuthentication] = useState(
    (pageProps as TTruxwebPageProps).cognitoUserData || null
  );
  const [hasAuthEvaluated, setAuthEvaluated] = useState(
    Boolean((pageProps as TTruxwebPageProps).cognitoUserData)
  );
  const { pathname } = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if (!hasAuthEvaluated) {
      Auth.currentAuthenticatedUser()
        .then(async () => {
          const user = await Auth.currentAuthenticatedUser();
          setAuthentication(user.attributes);
          if (IS_SENTRY_RELEASE_ENABLED) {
            init({
              dsn: SENTRY_DSN,
            });
            setUser({
              email: user.attributes.email,
              id: user.attributes.sub,
            });
          }
        })
        .catch(() => {
          setAuthentication(null);
        })
        .finally(() => {
          setAuthEvaluated(true);
        });
    }
  }, [setAuthentication, hasAuthEvaluated, setAuthEvaluated]);

  const getLayout = Component.getLayout || ((page) => page);
  return (
    <ThemeProvider
      theme={RESPONSIVE_PAGES.includes(pathname) ? RESPONSIVE_THEME : TRUXWEB_THEME_05_2022}
    >
      <CssBaseline />
      <PageError>
        <AuthProvider defaultAuthUser={authentication} hasAuthEvaluated={hasAuthEvaluated}>
          <AlertProvider>
            <QuoteProvider>{getLayout(<Component {...pageProps} />)}</QuoteProvider>
          </AlertProvider>
        </AuthProvider>
      </PageError>
    </ThemeProvider>
  );
};

export default appWithTranslation(reduxWrapper.withRedux(TruxwebApp) as any);
