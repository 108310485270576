"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGridStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useGridStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        '& .MuiDataGrid-cell': {
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },
    },
}));
