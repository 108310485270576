"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataTable = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const DataTable_styles_1 = require("./DataTable.styles");
const DEFAULT_PAGE_SIZE = 20;
const ROW_HEIGHT = 52;
const DataTable = ({ areColumnsEnabled, areFiltersEnabled, columns, data, defaultSort, handleCellEditCommit, handleRowSelection, hasCheckboxSelection, isCellEditable, isDensityEnabled, isExportEnabled, isLoading, onPageChange, onPageSizeChange, onSortChange, pageSize, selectedRows, shouldFitRowSizeToContent, shouldSuppressFooter, }) => {
    let toolbar = undefined;
    const rowHeightClases = (0, DataTable_styles_1.useGridStyles)();
    const [gridSort, setGridSort] = (0, react_1.useState)(defaultSort);
    const handleSetSort = (0, react_1.useCallback)((sort) => {
        setGridSort(sort);
        if (onSortChange) {
            onSortChange(sort);
        }
    }, [setGridSort, onSortChange]);
    if (isExportEnabled || isDensityEnabled || areColumnsEnabled || areFiltersEnabled) {
        toolbar = () => {
            return (react_1.default.createElement(ux_1.GridToolbarContainer, null,
                areFiltersEnabled && react_1.default.createElement(ux_1.GridToolbarFilterButton, null),
                areColumnsEnabled && react_1.default.createElement(ux_1.GridToolbarColumnsButton, null),
                isExportEnabled && react_1.default.createElement(ux_1.GridToolbarExport, null),
                isDensityEnabled && react_1.default.createElement(ux_1.GridToolbarDensitySelector, null)));
        };
    }
    const tablePageSize = pageSize ? pageSize : DEFAULT_PAGE_SIZE;
    const displayedRows = data.length > tablePageSize ? tablePageSize : data.length;
    return (react_1.default.createElement(ux_1.Box, { pb: 8, style: { height: (displayedRows + 3) * (ROW_HEIGHT + 4), width: '100%' } },
        react_1.default.createElement(ux_1.DataGrid, { autoHeight: shouldFitRowSizeToContent ? false : true, checkboxSelection: hasCheckboxSelection, classes: shouldFitRowSizeToContent ? rowHeightClases : {}, columns: columns, components: { Toolbar: toolbar }, disableSelectionOnClick: true, disableVirtualization: shouldFitRowSizeToContent, hideFooter: shouldSuppressFooter, isCellEditable: isCellEditable, loading: isLoading, onCellEditCommit: handleCellEditCommit, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSelectionModelChange: handleRowSelection, onSortModelChange: handleSetSort, pageSize: tablePageSize, rows: data, selectionModel: selectedRows, sortModel: gridSort })));
};
exports.DataTable = DataTable;
