import { type Action, type AnyAction, type CombinedState } from 'redux';
import {
  alertSlice,
  authSlice,
  baseLoadingState,
  carrierSlice,
  companyDocumentSlice,
  companyLocationSlice,
  companySlice,
  loadParametersSlice,
  quoteSlice,
  reportingSlice,
  searchSlice,
  shipmentCommentSlice,
  shipmentSlice,
} from '.';
import { combineReducers, configureStore, type ThunkAction } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { EApplicationActions } from '../types';
import { uxSlice } from './ux';

const combinedReducer = combineReducers({
  [alertSlice.name]: alertSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [carrierSlice.name]: carrierSlice.reducer,
  [companySlice.name]: companySlice.reducer,
  [companyLocationSlice.name]: companyLocationSlice.reducer,
  [companyDocumentSlice.name]: companyDocumentSlice.reducer,
  [loadParametersSlice.name]: loadParametersSlice.reducer,
  [quoteSlice.name]: quoteSlice.reducer,
  [reportingSlice.name]: reportingSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [shipmentSlice.name]: shipmentSlice.reducer,
  [shipmentCommentSlice.name]: shipmentCommentSlice.reducer,
  [uxSlice.name]: uxSlice.reducer,
});

const rootReducer = (
  state: CombinedState<ReturnType<typeof combinedReducer>>,
  action: AnyAction
) => {
  if (action.type === EApplicationActions.LOGOUT) {
    state = {
      auth: {
        userData: {
          ...baseLoadingState,
          hasLoggedOut: true,
        },
      },
    } as any;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export const makeStore = (): ReturnType<typeof configureStore> => {
  return store;
};
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = any;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppStore, unknown, Action>;

export const reduxWrapper = createWrapper<AppStore>(makeStore, { debug: false });
