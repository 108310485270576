import { ARE_COMPANY_DOCUMENTS_ENABLED, IS_FUEL_SURCHARGE_ACTIVE } from '../config';
import { EPermissionV1, EUserTypeV1 } from '@truxweb/schemas';
import {
  Grid,
  TextMenuItem,
  TruxwebBuildingIcon,
  TruxwebCreditCardIcon,
  TruxwebCubeIcon,
  TruxwebDocumentIcon,
  TruxwebInterrogationIcon,
  TruxwebMarkerIcon,
  TruxwebRoadIcon,
  TruxwebSearchAltIcon,
  TruxwebSearchIcon,
  TruxwebSettingsIcon,
  TruxwebTireIcon,
  TruxwebTrailerIcon,
  TruxwebTruckContainerIcon,
  TruxwebTruckSideIcon,
  TruxwebUserIcon,
  UnreadCount,
} from '@truxweb/ux';
import { usePermissions, useQuoteCounts, useUserData } from '.';
import { PageLink } from '../components';
import { useMemo } from 'react';
import { useStyles } from '../pageTemplates/WebAppLayout/WebAppLayout.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const useMenu = (isLeftMenuOpen: boolean) => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { quoteCounts } = useQuoteCounts();
  const { userType } = useUserData();
  const [canManageCarrierData] = usePermissions([EPermissionV1.MANAGE_CARRIER_DATA]);
  const [canManageShipperData] = usePermissions([EPermissionV1.MANAGE_SHIPPER_DATA]);
  const [canViewCarrierPages] = usePermissions([EPermissionV1.VIEW_CARRIER_PAGE]);
  const [canViewShipperPages] = usePermissions([EPermissionV1.VIEW_SHIPPER_PAGE]);
  const [canViewSavedLoads] = usePermissions([EPermissionV1.VIEW_SAVED_LOAD]);

  return useMemo(() => {
    const hasQuotesRequiringAttention = quoteCounts
      ? Boolean(
          Object.keys(quoteCounts).find((key) => {
            return key !== 'confirmed' && quoteCounts[key] !== 0;
          })
        )
      : false;

    let totalQuoteCounts = quoteCounts?.responseRequired || 0;
    if (userType === EUserTypeV1.CARRIER) {
      totalQuoteCounts += quoteCounts?.refused;
    }
    return {
      account: {
        icon: <TruxwebSettingsIcon className={classes.whiteIcon} />,
        links: {
          address: {
            link: (
              <PageLink pageId={'account/address'}>
                <TextMenuItem
                  icon={<TruxwebMarkerIcon />}
                  key="address"
                  name={t('common:address')}
                />
              </PageLink>
            ),
            order: 4,
            pageId: 'account/address',
            pageStyle: 'fullWidth',
          },

          company: {
            link: (
              <PageLink pageId={'account/company'}>
                <TextMenuItem
                  icon={<TruxwebBuildingIcon />}
                  key="company"
                  name={t('common:company')}
                />
              </PageLink>
            ),
            order: 2,
            pageId: 'account/company',
            pageStyle: 'centered',
          },

          document: {
            link: ARE_COMPANY_DOCUMENTS_ENABLED && (
              <PageLink pageId={'account/document'}>
                <TextMenuItem
                  icon={<TruxwebDocumentIcon />}
                  key="document"
                  name={t('common:billingDocuments')}
                />
              </PageLink>
            ),
            order: 5,
            pageId: 'account/document',
            pageStyle: 'centered',
          },
          paymentDetails: {
            link: (
              <PageLink pageId={'account/payment'}>
                <TextMenuItem
                  icon={<TruxwebCreditCardIcon />}
                  key="paymentDetails"
                  name={t('common:paymentDetails')}
                />
              </PageLink>
            ),
            order: 3,
            pageId: 'account/payment',
            pageStyle: 'centered',
          },
          settings: {
            link: IS_FUEL_SURCHARGE_ACTIVE && (
              <PageLink pageId={'account/settings'}>
                <TextMenuItem
                  icon={<TruxwebSettingsIcon />}
                  key="document"
                  name={t('common:settings')}
                />
              </PageLink>
            ),
            order: 6,
            pageId: 'account/document',
            pageStyle: 'centered',
            permissions: canManageCarrierData,
            title: t('common:settings'),
          },
          user: {
            link: (
              <PageLink pageId={'account'}>
                <TextMenuItem
                  icon={<TruxwebUserIcon />}
                  key={'account'}
                  name={t('common:account')}
                />
              </PageLink>
            ),
            order: 1,
            pageId: 'account',
            pageStyle: 'centered',
          },
        },
        maintenanceIcon: false,
        name: 'account',
        order: 4,
        permissions: canManageCarrierData || canManageShipperData,
        title: t('common:account'),
      },

      help: {
        icon: (
          <PageLink pageId={'contact-us'}>
            <Grid alignItems="center" container justifyContent="center">
              <TruxwebInterrogationIcon className={classes.whiteIcon} />
            </Grid>
          </PageLink>
        ),
        links: {
          help: (
            <PageLink pageId={'contact-us'}>
              <TextMenuItem icon={<TruxwebUserIcon />} key={'account'} name={t('common:account')} />
            </PageLink>
          ),
        },
        maintenanceIcon: true,
        name: 'help',
        permissions: canViewCarrierPages || canViewShipperPages,
        title: t('common:help'),
      },

      lanes: {
        icon: <TruxwebRoadIcon className={classes.whiteIcon} />,
        links: {
          accessorials: {
            link: (
              <PageLink pageId={'fleet/accessorials'}>
                <TextMenuItem
                  icon={<TruxwebTireIcon />}
                  key={'accessorials'}
                  name={t('common:accessorials')}
                />
              </PageLink>
            ),
            order: 2,
            pageId: 'fleet/accessorials',
            pageStyle: 'centered',
          },
          equipment: {
            link: (
              <PageLink pageId={'fleet/equipment'}>
                <TextMenuItem
                  icon={<TruxwebTrailerIcon />}
                  key={'equipment'}
                  name={t('common:equipment')}
                />
              </PageLink>
            ),
            order: 1,
            pageId: 'fleet/equipment',
            pageStyle: 'centered',
          },

          lanes: {
            link: (
              <PageLink pageId={'fleet/lanes'}>
                <TextMenuItem icon={<TruxwebRoadIcon />} key={'lanes'} name={t('common:lanes')} />
              </PageLink>
            ),
            order: 3,
            pageId: 'fleet/lanes',
            pageStyle: 'fullWidth',
          },
        },
        maintenanceIcon: false,
        name: 'lanes',
        order: 3,
        permissions: canManageCarrierData,
        title: t('common:lanes'),
      },
      search: {
        icon: <TruxwebSearchIcon className={classes.whiteIcon} />,
        links: {
          newSearch: {
            link: (
              <PageLink pageId={'search'}>
                <TextMenuItem
                  icon={<TruxwebSearchIcon />}
                  key={'newSearch'}
                  name={t('common:search')}
                />
              </PageLink>
            ),
            order: 1,
            pageId: 'search',
            pageStyle: 'centered',
          },
          savedLoads: {
            link: (
              <PageLink pageId={'loads'}>
                <TextMenuItem
                  icon={<TruxwebSearchAltIcon />}
                  key={'loads'}
                  name={t('common:savedLoads')}
                />
              </PageLink>
            ),
            order: 1,
            pageId: 'loads',
            pageStyle: 'fullWidth',
            permissions: canViewSavedLoads,
          },
        },
        maintenanceIcon: false,
        name: 'search',
        order: 1,
        permissions: canManageShipperData,
        title: t('common:search'),
      },
      shipments: {
        hasNotification: !isLeftMenuOpen && hasQuotesRequiringAttention,
        icon: <TruxwebTruckContainerIcon className={classes.whiteIcon} />,
        links: {
          quotes: {
            link: (
              <PageLink pageId={'requests'}>
                <TextMenuItem
                  additionalContent={<UnreadCount content={totalQuoteCounts || 0} />}
                  icon={<TruxwebCubeIcon />}
                  key="quotes"
                  name={t('common:siteMap-requests')}
                />
              </PageLink>
            ),
            order: 0,
            pageId: 'requests',
            pageStyle: 'fullWidth',
            title: t('common:shipments'),
          },
          shipments: {
            link: (
              <PageLink pageId={'shipments'}>
                <TextMenuItem
                  icon={<TruxwebTruckSideIcon />}
                  key="active"
                  name={t('common:siteMap-shipments')}
                />
              </PageLink>
            ),
            order: 1,
            pageId: 'shipments',
            pageStyle: 'fullWidth',
            title: t('common:siteMap-shipments'),
          },
        },
        maintenanceIcon: false,
        name: 'shipments',
        order: 2,
        permissions: canManageCarrierData || canManageShipperData,
        title: t('common:siteMap-shipmentsHeader'),
      },
    };
  }, [
    userType,
    canManageShipperData,
    canViewSavedLoads,
    isLeftMenuOpen,
    canManageCarrierData,
    classes,
    canViewCarrierPages,
    canViewShipperPages,
    quoteCounts,
    t,
  ]);
};
