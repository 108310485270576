"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupChargesForEditableSummary = void 0;
const schemas_1 = require("@truxweb/schemas");
const _1 = require(".");
const lodash_1 = require("lodash");
const utils_1 = require("@truxweb/utils");
const cloneItem = (item) => {
    return (0, lodash_1.cloneDeep)(item);
};
const groupChargesForEditableSummary = (request) => {
    const { accessorials, areAccessorialsEditable, areAdditionalChargesEditable, charges, isEditingDisabled, shipmentDetails, } = request;
    const { equipment: shipmentEquipment } = shipmentDetails;
    let total = 0;
    let subtotal = 0;
    const equipment = [];
    const accessorial = [];
    const additional = [];
    const carrierFees = [];
    const shipperFees = [];
    const taxes = [];
    const serviceFees = [];
    const truxwebFees = [];
    const isEditable = isEditingDisabled !== undefined ? !isEditingDisabled : true;
    const truxwebTaxes = [];
    const carrierTaxes = [];
    const carrierCharges = [];
    const creditCardSurcharge = [];
    const accessorialLookup = {};
    const accessorialLookupByCode = {};
    const equipmentLookup = {};
    shipmentEquipment.forEach((record) => {
        equipmentLookup[record.equipmentId] = record;
    });
    accessorials.forEach((record) => {
        accessorialLookup[record.id] = record;
        accessorialLookupByCode[record.code] = record;
    });
    charges.forEach((charge) => {
        if (charge.recipient === 'CARRIER' &&
            !charge.isTaxCharge &&
            !['TRUXWEB_CARRIER'].includes(charge.chargeType)) {
            carrierCharges.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
        }
        if (charge.isBillable && !['TRUXWEB_CARRIER'].includes(charge.chargeType)) {
            const addition = (0, _1.formatChargeNumberToCurrencyValue)(charge);
            total += addition;
        }
        if (!charge.isTaxCharge &&
            !['TRUXWEB_CARRIER', 'CREDIT_CARD_SURCHARGE'].includes(charge.chargeType) &&
            charge.isBillable) {
            subtotal += (0, _1.formatChargeNumberToCurrencyValue)(charge);
        }
        if (charge.isTaxCharge) {
            taxes.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            if (charge.recipient === 'TRUXWEB') {
                truxwebTaxes.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
            if (charge.recipient === 'CARRIER') {
                carrierTaxes.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
        }
        // CC Surchage is Never editable, this is always calculated
        if (charge.chargeType === 'CREDIT_CARD_SURCHARGE') {
            creditCardSurcharge.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
        }
        if (charge.recipient === 'TRUXWEB' &&
            !charge.isTaxCharge &&
            ['TRUXWEB_SHIPPER'].includes(charge.chargeType)) {
            shipperFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        if (charge.recipient === 'TRUXWEB' &&
            !charge.isTaxCharge &&
            ['TRUXWEB_CARRIER'].includes(charge.chargeType)) {
            carrierFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        // Truxweb Fee is Never editable, this is always calculated
        if (charge.recipient === 'TRUXWEB' &&
            !charge.isTaxCharge &&
            !['TRUXWEB_CARRIER', 'TRUXWEB_SHIPPER', 'CREDIT_CARD_SURCHARGE'].includes(charge.chargeType)) {
            serviceFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            if (charge.isBillable) {
                truxwebFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
        }
        // Fuel surchage is never editable, it is always calculated
        if (charge.chargeType === 'FUEL_SURCHARGE') {
            serviceFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        if (charge.chargeType === 'EQUIPMENT') {
            equipment.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        if (['ACCESSORIAL'].includes(charge.chargeType)) {
            accessorial.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: areAccessorialsEditable }));
        }
        if (charge.chargeType === 'ADDITIONAL') {
            additional.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: areAdditionalChargesEditable }));
        }
    });
    // Group the truxweb taxes
    const truxwebTaxesLookup = {};
    truxwebTaxes.forEach((charge) => {
        if (!charge.isBillable)
            return;
        let lookup = `${charge.chargeType}`;
        if (charge.chargeReference === 'CARRIER_TAX') {
            lookup = `${charge.chargeType}-CARRIER`;
        }
        if (!truxwebTaxesLookup[lookup]) {
            const newChargeValue = (0, _1.formatChargeNumberToCurrencyValue)(charge);
            truxwebTaxesLookup[lookup] = cloneItem(charge);
            truxwebTaxesLookup[lookup].charge.value = newChargeValue;
        }
        else {
            truxwebTaxesLookup[lookup].charge.value += (0, _1.formatChargeNumberToCurrencyValue)(charge);
        }
    });
    const groupedTruxwebTaxes = Object.values(truxwebTaxesLookup);
    // TRUXWEB SUMMARY
    const truxwebFeeTotal = truxwebFees.length
        ? truxwebFees.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebCarrierFeeTotal = carrierFees.length
        ? carrierFees.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebShipperFeeTotal = shipperFees.length
        ? shipperFees.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebSubTotalFields = [truxwebFeeTotal, truxwebCarrierFeeTotal, truxwebShipperFeeTotal]
        .filter((record) => record !== null && record.isBillable)
        .map((record) => {
        return Object.assign({}, record);
    });
    const truxwebSubtotal = truxwebSubTotalFields.length
        ? truxwebSubTotalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebTotalFields = [truxwebSubtotal, ...groupedTruxwebTaxes]
        .filter((record) => record !== null && record.isBillable)
        .map((record) => {
        return Object.assign({}, record);
    });
    const truxwebTotal = truxwebTotalFields.length
        ? truxwebTotalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebOrderSummary = {
        truxwebCarrierFeeTotal,
        truxwebFeeTotal,
        truxwebFees,
        truxwebShipperFeeTotal,
        truxwebSubtotal,
        truxwebTaxes: groupedTruxwebTaxes,
        truxwebTotal,
    };
    // CARRIER SUMMARY
    const carrierFreightFields = carrierCharges
        .filter((record) => record !== null)
        .map((record) => {
        return Object.assign({}, record);
    });
    const carrierFrieghtTotal = carrierFreightFields.length
        ? carrierFreightFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const carrierSubTotalFields = [carrierFrieghtTotal, truxwebCarrierFeeTotal]
        .filter((record) => record !== null)
        .map((record) => {
        return Object.assign({}, record);
    });
    const carrierSubtotal = carrierSubTotalFields.length
        ? carrierSubTotalFields.map(cloneItem).reduce((acc, curr) => {
            const current = (0, _1.formatChargeNumberToCurrencyValue)(acc);
            const next = (0, _1.formatChargeNumberToCurrencyValue)(curr);
            // TRUXWEB CARRIER fees are treated as debits from the total they will recieved
            // if there is a negative carrier fee, this will increase the amount
            const nextValue = curr.chargeType === 'TRUXWEB_CARRIER' ? current - next : current + next;
            acc.charge = Object.assign(Object.assign({}, acc.charge), { value: nextValue });
            return acc;
        })
        : null;
    const carrierTotalFields = [carrierSubtotal, ...carrierTaxes]
        .filter((record) => record !== null)
        .map((record) => {
        return Object.assign({}, record);
    });
    const carrierTotal = carrierTotalFields.length
        ? carrierTotalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const carrierOrderSummary = {
        carrierFeeTotal: truxwebCarrierFeeTotal,
        carrierFrieghtTotal,
        carrierSubtotal,
        carrierTaxes,
        carrierTotal,
    };
    const shipperTaxes = {};
    [...groupedTruxwebTaxes, ...carrierTaxes].forEach((charge) => {
        if (!charge.isBillable)
            return;
        if (!shipperTaxes[charge.chargeType]) {
            const newChargeValue = (0, _1.formatChargeNumberToCurrencyValue)(charge);
            shipperTaxes[charge.chargeType] = cloneItem(charge);
            shipperTaxes[charge.chargeType].charge.value = newChargeValue;
        }
        else {
            shipperTaxes[charge.chargeType].charge.value += (0, _1.formatChargeNumberToCurrencyValue)(charge);
        }
    });
    const shipperOrderSummary = {
        shipperSubtotal: {
            currency: schemas_1.ECurrencyV1.CAD,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: subtotal || 0,
        },
        shipperTaxes: Object.values(shipperTaxes),
        shipperTotal: {
            currency: schemas_1.ECurrencyV1.CAD,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: (0, utils_1.formatNumberToCurrencyValue)(total) || 0,
        },
    };
    return {
        accessorial,
        accessorialLookup,
        accessorialLookupByCode,
        additional,
        carrierFees,
        carrierOrderSummary,
        creditCardSurcharge,
        equipment,
        equipmentLookup,
        serviceFees,
        shipperFees,
        shipperOrderSummary,
        truxwebOrderSummary,
    };
};
exports.groupChargesForEditableSummary = groupChargesForEditableSummary;
