import { Box, CircularProgress, FlatCard, Grid, RightHandSidebar } from '@truxweb/ux';
import { DEFAULT_PAGE_SIZE, QUOTE_TABLE_REFRESH_MS } from '../../config';
import { EUserTypeV1, type TShipperShipmentDataRow } from '@truxweb/schemas';
import { QuickAccess, ShipmentCard } from '@truxweb/common-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval, useRefresh, useShipperShipmentListData } from '../../hooks';
import { getRouteProperties } from '../../utils';
import { ShipmentsDashboardSidebarWrapper } from '..';
import { TPageId } from '../../types';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TMobileShipmentShipperListProps = {
  pageId: TPageId;
};

export const MobileShipmentShipperList = ({
  pageId,
}: TMobileShipmentShipperListProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const {
    locale,
    query: { comments, documents, orderSummary, shipmentRef },
  } = router;
  const [isRightSidebarOpen, setRightSidebarState] = useState(false);
  const { handleRefresh, shouldRefresh } = useRefresh();
  const { data, hasLoaded } = useShipperShipmentListData(
    {
      language: transformI18nLocaleToLanguage(locale),
      limit: DEFAULT_PAGE_SIZE,
      sort: [],
      t,
      userType: EUserTypeV1.SHIPPER,
    },
    true,
    shouldRefresh
  );

  const handleClose = useCallback(() => {
    const { route } = getRouteProperties(locale, pageId);
    router.push(route);
  }, [router, locale, pageId]);

  const findShipment = useCallback(
    (shipmentRef: string): TShipperShipmentDataRow | null => {
      return (data || []).find((row: TShipperShipmentDataRow) => {
        return row.shipmentRef === shipmentRef;
      });
    },
    [data]
  );

  const selectedShipmentRef = useMemo(() => {
    return shipmentRef as string;
  }, [shipmentRef]);

  const selectedSidebarDefaultSection = useMemo(():
    | 'tracking'
    | 'documents'
    | 'comments'
    | 'orderSummary'
    | undefined => {
    if (parseInt(documents as string) > 0) {
      return 'documents';
    }
    if (parseInt(comments as string) > 0) {
      return 'comments';
    }
    if (parseInt(orderSummary as string) > 0) {
      return 'orderSummary';
    }
    return 'tracking';
  }, [documents, comments, orderSummary]);

  const baseRoute = useMemo(() => {
    const { route } = getRouteProperties(locale, pageId);
    return route;
  }, [locale, pageId]);

  const handleSelectShipment = useCallback(
    async (shipmentRef: string, sectionToOpen?: 'documents' | 'comments') => {
      router.push(
        `${baseRoute}?shipmentRef=${shipmentRef}${sectionToOpen ? `&${sectionToOpen}=1` : ''}`
      );
      if (shipmentRef !== selectedShipmentRef) {
        const selectedShipment = findShipment(shipmentRef);

        if (selectedShipment) {
          setRightSidebarState(true);
        }
      }
    },
    [router, selectedShipmentRef, findShipment, baseRoute]
  );

  useInterval(handleRefresh, QUOTE_TABLE_REFRESH_MS);

  useEffect(() => {
    if (!selectedShipmentRef && isRightSidebarOpen) {
      setRightSidebarState(false);
    }
  }, [selectedShipmentRef, isRightSidebarOpen]);

  useEffect(() => {
    if (selectedShipmentRef && hasLoaded) {
      // Find the selected shipment

      const selectedShipment = findShipment(selectedShipmentRef);
      if (selectedShipment) {
        if (!isRightSidebarOpen) {
          setRightSidebarState(true);
        }
      }
    }
  }, [selectedShipmentRef, hasLoaded, data, findShipment, isRightSidebarOpen]);

  if (!hasLoaded) {
    return (
      <FlatCard>
        <Grid alignItems="center" container justifyContent="center">
          <CircularProgress />
        </Grid>
      </FlatCard>
    );
  }
  return (
    <>
      <Grid container direction="column" style={{ height: '100%' }}>
        <Grid item>
          <QuickAccess
            handleQuickAccess={(shipmentRef: string) => {
              handleSelectShipment(shipmentRef);
            }}
            placeholder={t('common:quickAccessShipment')}
            value={''}
          />
        </Grid>
        <Grid item xs>
          <Box m={3} style={{ height: 'calc(100vh - 224px)', overflow: 'auto' }}>
            <Grid container direction="column">
              {(data || []).map((shipment: TShipperShipmentDataRow) => {
                return (
                  <Box
                    key={shipment.id}
                    mb={3}
                    onClick={() => {
                      handleSelectShipment(shipment.shipmentRef);
                    }}
                    style={{ cursor: 'pointer', maxWidth: '100%' }}
                  >
                    <ShipmentCard
                      language={transformI18nLocaleToLanguage(locale)}
                      locations={{
                        destinations: [shipment.destination],
                        origin: shipment.origin,
                      }}
                      shipment={shipment._data.shipment}
                      t={t}
                      userType={EUserTypeV1.SHIPPER}
                    />
                  </Box>
                );
              })}
            </Grid>
          </Box>
        </Grid>
        <RightHandSidebar
          handleClose={handleClose}
          isOpen={isRightSidebarOpen}
          testId="MobileShipmentShipperList"
        >
          <Grid item key={selectedShipmentRef}>
            {Boolean(selectedShipmentRef) && (
              <ShipmentsDashboardSidebarWrapper
                baseRoute={baseRoute}
                defaultExpandedSection={selectedSidebarDefaultSection}
                handleClose={handleClose}
                handleListRefresh={handleRefresh}
                shipmentRef={selectedShipmentRef}
              />
            )}
          </Grid>
        </RightHandSidebar>
      </Grid>
    </>
  );
};
