import {
  Box,
  CircularProgress,
  Grid,
  type GridFilterModel,
  type GridSortModel,
  List,
  ListItem,
  RightHandSidebar,
  ToggleButtons,
  TruxwebRefreshIcon,
  Typography,
} from '@truxweb/ux';
import { DEFAULT_PAGE_SIZE, QUOTE_TABLE_REFRESH_MS } from '../../config';
import { EShipmentStatusGroupV1, EUserTypeV1 } from '@truxweb/schemas';
import { getRouteProperties, hasWindow } from '../../utils';
import { QuickAccess, ShipmentCarrierDataGrid } from '@truxweb/common-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCarrierShipmentListData,
  useInterval,
  useReduxAccessorialData,
  useReduxEquipmentData,
  useRefresh,
  useShipmentEntityTags,
  useUserData,
  useUxState,
} from '../../hooks';
import classnames from 'classnames';
import { handleToggleShipmentTableGroup } from '../../stores';
import { ShipmentsDashboardSidebarWrapper } from '..';
import { TPageId } from '../../types';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './ShipmentCarrierList.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];
const GAP_INTERVAL = 1;
const PAGE_SIZE = 10;

type TShipmentCarrierListProps = {
  pageId: TPageId;
};

const TABLE_ID = 'TRUXWEB_CARRIER_SHIPMENT_TABLE';
const DEFAULT_COLUMNS = [
  'carrierStatus',
  'shipmentRef',
  'action',
  'carrierReferenceNumber',
  'carrierQuoteReferenceNumber',
  'shipmentType',
  'carrierSubtotal',
  'origin',
  'destination',
  'destinationReferenceNumber',
  'poNumber',
  'carrierName',
  'pickupDate',
  'equipmentCodes',
  'accessorialCodes',
];

const DEFAULT_SORT: GridSortModel = [
  {
    field: 'pickupDate',
    sort: 'asc',
  },
];
export const ShipmentCarrierList = ({ pageId }: TShipmentCarrierListProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const { userData } = useUserData();
  const uxState = useUxState();
  const dispatch = useDispatch();
  const { data: accessorials, hasLoaded: hasAccessorialsFetched } = useReduxAccessorialData();
  const { data: equipment, hasLoaded: hasEquipmentFetched } = useReduxEquipmentData();

  const {
    locale,
    query: { comments, documents, orderSummary, shipmentRef },
  } = router;
  const [hasInit, setInit] = useState(false);
  const [isRightSidebarOpen, setRightSidebarState] = useState(false);
  const [columnKeys, setColumnKeys] = useState<null | string[]>(null);
  const [tableSort, setTableSort] = useState<null | GridSortModel>(null);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableFilters, setTableFilters] = useState<null | GridFilterModel>(null);
  const { handleRefresh, shouldRefresh } = useRefresh();
  const tags = useShipmentEntityTags(shouldRefresh);
  const shipmentData = useCarrierShipmentListData(
    {
      filter: tableFilters,
      language: transformI18nLocaleToLanguage(locale),
      limit: DEFAULT_PAGE_SIZE,
      page: currentPage,
      pageSize: currentPageSize,
      sort: tableSort,
      t,
      userType: EUserTypeV1.CARRIER,
    },
    hasInit,
    shouldRefresh
  );
  const { data, hasLoaded, isLoading, rowCount } = shipmentData;

  const [toggleTimeout, setToggleTimeout] = useState(null);

  const handleToggleRefresh = useCallback(() => {
    if (toggleTimeout) clearTimeout(toggleTimeout);
    const newTimeout = setTimeout(() => {
      handleRefresh();
    }, 500);
    setToggleTimeout(newTimeout);
  }, [toggleTimeout, setToggleTimeout, handleRefresh]);

  const manageColumnKeys = useCallback(
    (event: any) => {
      const { field } = event;
      const fieldIndex = columnKeys.indexOf(field);
      const newColumnKeys = [...columnKeys];
      if (fieldIndex > -1) {
        newColumnKeys.splice(fieldIndex, 1);
      } else {
        newColumnKeys.push(field);
      }
      setColumnKeys(newColumnKeys);

      const storedKeys = localStorage.getItem(TABLE_ID);
      let update = { columnKeys: newColumnKeys };
      if (storedKeys) {
        update = JSON.parse(storedKeys);
        update.columnKeys = newColumnKeys;
      }
      localStorage.setItem(TABLE_ID, JSON.stringify(update));
    },
    [columnKeys, setColumnKeys]
  );

  const manageTableSort = useCallback(
    (sort: GridSortModel | undefined) => {
      let hasSortChanges = false;
      if (sort && !tableSort) {
        hasSortChanges = true;
      } else if (!sort && tableSort) {
        hasSortChanges = true;
      } else if (sort && tableSort) {
        hasSortChanges = JSON.stringify(sort) !== JSON.stringify(tableSort);
      }
      if (hasSortChanges) {
        setTableSort(sort || null);
        const storedKeys = localStorage.getItem(TABLE_ID);
        let update = { tableSort: sort };
        if (storedKeys) {
          update = JSON.parse(storedKeys);
          update.tableSort = sort;
        }
        localStorage.setItem(TABLE_ID, JSON.stringify(update));
        handleRefresh();
      }
    },
    [setTableSort, tableSort, handleRefresh]
  );
  const manageTableFilters = useCallback(
    (filter: GridFilterModel) => {
      setTableFilters(filter);
      handleRefresh();
    },
    [setTableFilters, handleRefresh]
  );

  const classes = useStyles({
    gapInterval: GAP_INTERVAL,
    isLoading,
  });
  const handleClose = useCallback(() => {
    const { route } = getRouteProperties(locale, pageId);
    router.push(route);
  }, [router, locale, pageId]);

  const selectedShipmentRef = useMemo(() => {
    return shipmentRef as string;
  }, [shipmentRef]);

  const selectedSidebarDefaultSection = useMemo(():
    | 'tracking'
    | 'documents'
    | 'comments'
    | 'orderSummary'
    | undefined => {
    if (parseInt(documents as string) > 0) {
      return 'documents';
    }
    if (parseInt(comments as string) > 0) {
      return 'comments';
    }
    if (parseInt(orderSummary as string) > 0) {
      return 'orderSummary';
    }
    return 'tracking';
  }, [documents, comments, orderSummary]);

  const baseRoute = useMemo(() => {
    const { route } = getRouteProperties(locale, pageId);
    return route;
  }, [locale, pageId]);

  const handleSelectShipment = useCallback(
    async (shipmentRef: string, sectionToOpen?: 'documents' | 'comments') => {
      router.push(
        `${baseRoute}?shipmentRef=${shipmentRef}${sectionToOpen ? `&${sectionToOpen}=1` : ''}`
      );
    },
    [router, baseRoute]
  );

  // This is used when single toggles are clicked
  const handleToggle = useCallback(
    (toggle: string, forceSingleToggle: boolean) => {
      dispatch(
        handleToggleShipmentTableGroup({
          forceToggle: forceSingleToggle,
          forceToggleSet: false,
          statusGroups: [toggle],
        })
      );
      handleToggleRefresh();
    },
    [dispatch, handleToggleRefresh]
  );

  // This is used to set the entire toggle set
  const handleSetToggles = useCallback(
    (toggles: string[]) => {
      dispatch(
        handleToggleShipmentTableGroup({
          forceToggle: false,
          forceToggleSet: true,
          statusGroups: toggles,
        })
      );
      handleToggleRefresh();
    },
    [dispatch, handleToggleRefresh]
  );

  const [toggles, toggleExplanations] = useMemo(() => {
    const toggles = [
      {
        group: EShipmentStatusGroupV1.UPCOMING,
        label: t('common:upcoming'),
      },
      {
        group: EShipmentStatusGroupV1.IN_PROGRESS,
        label: t('common:active'),
      },
      {
        group: EShipmentStatusGroupV1.DELIVERED,
        label: t('common:delivered'),
      },
      {
        group: EShipmentStatusGroupV1.COMPLETED,
        label: t('common:completed'),
      },
    ];

    const list = (
      <List key={1}>
        {toggles.map(({ group, label }) => {
          return (
            <ListItem key={group}>
              <Typography color="white">
                <b>{label}</b>:{' '}
                {t(`common:statusGroup-${group}-${EUserTypeV1.CARRIER}-explanation`)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );

    const statusToggleExplanation = (
      <>
        <Typography color="white" fontStyle="semibold">
          {t('common:statusToggles')}
        </Typography>
        {list}
      </>
    );

    return [toggles, statusToggleExplanation];
  }, [t]);

  useInterval(handleRefresh, QUOTE_TABLE_REFRESH_MS);

  useEffect(() => {
    if (selectedShipmentRef && !isRightSidebarOpen) {
      setRightSidebarState(true);
    }
    if (!selectedShipmentRef && isRightSidebarOpen) {
      setRightSidebarState(false);
    }
  }, [selectedShipmentRef, isRightSidebarOpen, setRightSidebarState]);

  useEffect(() => {
    if (hasWindow() && columnKeys === null) {
      const storedKeys = localStorage.getItem(TABLE_ID);
      if (storedKeys) {
        const parsed = JSON.parse(storedKeys);
        const newSort = parsed.tableSort || DEFAULT_SORT;
        setColumnKeys(parsed.columnKeys || DEFAULT_COLUMNS);
        setTableSort(newSort);
      } else {
        setColumnKeys(DEFAULT_COLUMNS);
        setTableSort(DEFAULT_SORT);
      }

      if (!hasInit) {
        const storedFilters = localStorage.getItem(`${TABLE_ID}_FILTERS`);
        if (storedFilters) {
          handleSetToggles(JSON.parse(storedFilters));
        }
      }

      setInit(true);
    }
  }, [columnKeys, setColumnKeys, setTableSort, hasInit, setInit, handleSetToggles]);

  return (
    <Grid container direction="column">
      <Grid item xs>
        <Box mb={2}>
          <Grid alignItems="center" container direction="row" justifyContent="space-between" xs>
            <Grid item xs>
              <Box mr={2}>
                <QuickAccess
                  handleQuickAccess={(shipmentRef: string) => {
                    handleSelectShipment(shipmentRef);
                  }}
                  placeholder={t('common:quickAccessShipment')}
                  value={''}
                />
              </Box>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={7}>
              <Grid item style={{ marginTop: '8px' }} xs>
                <ToggleButtons
                  explanation={toggleExplanations}
                  handleToggle={handleToggle}
                  isDisabled={isLoading}
                  label={t('common:statusToggles')}
                  selectedToggles={uxState.shipmentTableGroup}
                  toggles={toggles}
                />
              </Grid>
              <Grid item>
                {!isLoading && (
                  <Box ml={1} mt={1.5}>
                    <button className={classes.button} disabled={isLoading} onClick={handleRefresh}>
                      <TruxwebRefreshIcon
                        className={classnames(classes.icon, classes.animatedIcon)}
                      />
                    </button>
                  </Box>
                )}
                {isLoading && (
                  <Box ml={1.9} mt={1.75}>
                    <CircularProgress size={16} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid className={classes.tableContainer} item>
        {!tableSort || !hasEquipmentFetched || !hasAccessorialsFetched ? (
          <></>
        ) : (
          <ShipmentCarrierDataGrid
            accessorial={accessorials}
            areFiltersEnabled
            availableTags={tags?.data || []}
            columnKeys={columnKeys}
            currentSort={tableSort}
            data={data}
            equipment={equipment}
            filterMode={'server'}
            gapInterval={GAP_INTERVAL}
            handleColumnVisibilityChange={manageColumnKeys}
            handleFilterChange={manageTableFilters}
            handleRouteToShipment={handleSelectShipment}
            handleSortingChange={manageTableSort}
            isLoading={!hasLoaded}
            isLoadingPageData={isLoading}
            language={userData.language}
            lastLoadedDate={
              shipmentData?.lastLoadedDate ? new Date(shipmentData?.lastLoadedDate) : undefined
            }
            onPageChange={(page) => {
              setCurrentPage(page);
              handleRefresh();
            }}
            onPageSizeChange={(pageSize) => {
              setCurrentPageSize(pageSize);
              handleRefresh();
            }}
            pageSize={currentPageSize}
            paginationMode={'server'}
            rowCount={rowCount}
            sortingMode={'server'}
            t={t}
          />
        )}
      </Grid>
      <RightHandSidebar
        canExpand
        handleClose={handleClose}
        isLeftMenuOpen={uxState.sidebarState}
        isOpen={isRightSidebarOpen}
        testId="ShipmentCarrierList"
      >
        <Grid item key={selectedShipmentRef}>
          <ShipmentsDashboardSidebarWrapper
            baseRoute={baseRoute}
            defaultExpandedSection={selectedSidebarDefaultSection}
            handleClose={handleClose}
            handleListRefresh={handleRefresh}
            shipmentRef={selectedShipmentRef}
          />
        </Grid>
      </RightHandSidebar>
    </Grid>
  );
};
