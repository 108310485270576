import { ELanguageV1, TCarrierProfileDisplayV1 } from '@truxweb/schemas';
import { TUseActionResponse, useAction } from './useAction';
import { getExternalCarrierProfile } from '../actions';
import { useCallback } from 'react';

export const usePublicCarrierProfileData = (
  companyCode: string,
  language: ELanguageV1,
  forceRefetch?: boolean
): TUseActionResponse<TCarrierProfileDisplayV1 | null> => {
  const action = useCallback(async () => {
    return await getExternalCarrierProfile(companyCode, language);
  }, [companyCode, language]);

  return useAction<TCarrierProfileDisplayV1 | null>(action, true, forceRefetch || false);
};
