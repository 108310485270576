import {
  addBankAccountToUserProfileV2,
  addCreditCardV2,
  deactivateCreditCardByIdV2,
  requestShipperPaymentTerms,
} from '../../actions';
import { EPaymentTermsTypeV1, EStatusGenericV1 } from '@truxweb/schemas';
import {
  formatRequestPaymentTermsFormData,
  ShipperPaymentCards,
  ShipperPaymentTerms,
} from '@truxweb/common-components';
import { InteriorHeader, Loading } from '..';
import React, { useCallback, useEffect, useState } from 'react';
import { TApprovePaymentTerms, TShipperPaymentTermsRequestFormData } from '../../types';
import {
  useAlerts,
  useBambora,
  useBankAccounts,
  useCompanyData,
  useCreditCards,
  useErrorHandling,
  usePaymentTerms,
  useUserData,
} from '../../hooks';
import { addressFromFormData } from '@truxweb/utils';
import { CARRIER_ONBOARDING_BUCKET } from '../../config';
import { Grid } from '@truxweb/ux';
import { uploadFileToBucket } from '../../apiUtils';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'account'];

export const AccountShipperPayment = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const bambora = useBambora();
  const { userData } = useUserData();
  const companyData = useCompanyData();

  const [isAddCardFormShown, setIsAddCardFormShown] = useState(false);
  const [shouldPaymentOptionsRefetch, setShouldPaymentOptionsRefetch] = useState(false);
  const [shouldCardsRefetch, setShouldCardsRefetch] = useState(false);
  const [shouldPaymentTermsRefetch, setShouldPaymentTermsRefetch] = useState(false);
  const [defaultBankAccount, setDefaultBankAccount] = useState(null);
  const [hasErrorShown, setHasErrorShown] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [proofOfOwnership, setProofOfOwnership] = useState(null);
  const [arePaymentTermsShown, setPaymentTermsShown] = useState(false);

  const creditCards = useCreditCards(shouldCardsRefetch);
  const [pendingTermsRequest, arePendingTermsLoaded] = usePaymentTerms(shouldPaymentTermsRefetch);
  const [bankAccounts, haveBankAccountsFetched, areBankAccountsLoading, bankAccountError] =
    useBankAccounts();

  const userCreditCards = creditCards[0];
  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();
  const isCardListLoading = creditCards[2];

  useEffect(() => {
    if (haveBankAccountsFetched) {
      const defaultBankAccount = (bankAccounts || []).find((account) => {
        return account.isDefault === true;
      });
      setDefaultBankAccount(defaultBankAccount);
    }
  }, [haveBankAccountsFetched, bankAccounts, setDefaultBankAccount, bankAccountError]);

  useEffect(() => {
    if (haveBankAccountsFetched && bankAccountError && !hasErrorShown) {
      errorHandler(bankAccountError);
      setHasErrorShown(true);
    }
  }, [bankAccountError, errorHandler, haveBankAccountsFetched, hasErrorShown, setHasErrorShown]);

  const submitToken = useCallback(async (): Promise<string> => {
    return await new Promise((resolve, reject) => {
      bambora.createToken((result: { error: Error; token: string }) => {
        if (result.error) reject(result.error);
        resolve(result.token);
      });
    });
  }, [bambora]);

  const handleAddCardSubmit = useCallback(
    async (formData: Record<string, any>) => {
      try {
        const address = addressFromFormData(formData, userData);
        const cardToken = await submitToken();

        const addCardReq = {
          address,
          isDefault: false,
          token: cardToken,
        };
        await addCreditCardV2(addCardReq);

        setIsAddCardFormShown(false);
        setShouldCardsRefetch(true);

        addAlert({
          message: t('common:addCardSuccess'),
          severity: 'success',
        });
      } catch (err) {
        errorHandler(err, t('common:unableToAddCreditCard'));
      }
    },
    [setIsAddCardFormShown, addAlert, t, setShouldCardsRefetch, submitToken, userData, errorHandler]
  );

  const handleRemoveCardSubmit = useCallback(
    async (cardId: number) => {
      try {
        await deactivateCreditCardByIdV2(cardId);

        setIsAddCardFormShown(false);
        setShouldCardsRefetch(true);

        addAlert({
          message: t('common:removeCardSuccess'),
          severity: 'success',
        });
      } catch (err) {
        errorHandler(err, t('common:deleteFailed'));
      }
    },
    [setIsAddCardFormShown, addAlert, t, setShouldCardsRefetch, errorHandler]
  );

  useEffect(() => {
    if (shouldCardsRefetch) {
      setShouldCardsRefetch(false);
    }
  }, [setShouldCardsRefetch, shouldCardsRefetch]);

  useEffect(() => {
    if (shouldPaymentOptionsRefetch) {
      setShouldPaymentOptionsRefetch(false);
    }
  }, [setShouldPaymentOptionsRefetch, shouldPaymentOptionsRefetch]);

  const handleUploadFailure = useCallback(
    (message?: string) => {
      addAlert({ message: message || t('common:uploadFailed'), severity: 'error' });
    },
    [t, addAlert]
  );

  const handleUploadFile = useCallback(
    async (file: File, onChange: any) => {
      try {
        setIsUploading(true);
        const postFileName = `${userData.extId}_bankingDetails_${new Date().toISOString()}.pdf`;
        const s3Response = await uploadFileToBucket(
          postFileName,
          file,
          CARRIER_ONBOARDING_BUCKET,
          'application/pdf'
        );
        onChange({
          localName: file.name,
          remoteName: s3Response.key,
        });
        addAlert({ message: t('common:uploadSuccess'), severity: 'success' });
      } catch (err) {
        errorHandler(err, t('common:uploadFailed'));
      } finally {
        setIsUploading(false);
      }
    },
    [userData, addAlert, t, setIsUploading, errorHandler]
  );

  const onSubmitSuccess = useCallback(
    async (data: TShipperPaymentTermsRequestFormData | TApprovePaymentTerms) => {
      const successMessage = t('account:requestPaymentTermsSuccess');
      setIsSaving(true);
      try {
        // Only add a bank account for direct debit requests
        if (data.paymentTermsType === EPaymentTermsTypeV1.DEBIT) {
          const { proofOfOwnership, ...bankAccountData } = data;
          await addBankAccountToUserProfileV2({
            data: bankAccountData,
            proofOfOwnership: {
              proofOfOwnershipName: proofOfOwnership?.localName,
              proofOfOwnershipUrl: `public/${proofOfOwnership?.remoteName}`,
            },
            status: EStatusGenericV1.PENDING,
            userData,
          });
        }

        const paymentTermsRequest = formatRequestPaymentTermsFormData(data, userData);
        await requestShipperPaymentTerms(paymentTermsRequest);
        addAlert({
          message: successMessage,
          severity: 'success',
        });

        // ONSUCCESS
        setShouldPaymentTermsRefetch(true);
        setShouldPaymentOptionsRefetch(true);
        //
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsSaving(false);
      }
    },
    [addAlert, t, userData, setIsSaving, errorHandler]
  );

  return (
    <Grid container direction="column">
      <Loading isLoading={isCardListLoading || areBankAccountsLoading} />
      <Grid item xs>
        <InteriorHeader>{t('common:paymentDetails')}</InteriorHeader>
      </Grid>
      <Grid item xs>
        <Grid container direction="column" style={{ width: 640 }}>
          <Grid item xs>
            <ShipperPaymentCards
              bambora={bambora}
              handleAddCardSubmit={handleAddCardSubmit}
              handleRemoveCardSubmit={handleRemoveCardSubmit}
              isAddCardFormShown={isAddCardFormShown}
              setIsAddCardFormShown={setIsAddCardFormShown}
              t={t}
              userCreditCards={userCreditCards}
            />
          </Grid>
          <Grid item xs>
            {haveBankAccountsFetched && companyData[1] === true && (
              <ShipperPaymentTerms
                arePaymentTermsShown={arePaymentTermsShown}
                arePendingTermsLoaded={arePendingTermsLoaded}
                bankAccount={defaultBankAccount}
                companyData={companyData[0]}
                handleUploadFailure={handleUploadFailure}
                handleUploadFile={handleUploadFile}
                isAdmin={false}
                isSaving={isSaving}
                isUploading={isUploading}
                onSubmitSuccess={onSubmitSuccess}
                pendingTermsRequest={pendingTermsRequest}
                proofOfOwnership={proofOfOwnership}
                setPaymentTermsShown={setPaymentTermsShown}
                setProofOfOwnership={setProofOfOwnership}
                setShouldPaymentTermsRefetch={setShouldPaymentTermsRefetch}
                shouldPaymentTermsRefetch={shouldPaymentTermsRefetch}
                t={t}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
