import { CircularProgress, Grid } from '@truxweb/ux';
import { CARRIER_PROFILE_BUCKET } from '../../config';
import { CarrierProfileMobile } from '@truxweb/common-components';
import { getPublicS3BucketUrl } from '../../apiUtils';
import Image from 'next/image';
import { PageLink } from '..';
import React from 'react';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { usePublicCarrierProfileData } from '../../hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TCarrierProfileSidebarWrapperProps = {
  companyCode: string;
};

export const CarrierProfileSidebarWrapper = ({
  companyCode,
}: TCarrierProfileSidebarWrapperProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { locale } = useRouter();

  const language = transformI18nLocaleToLanguage(locale);
  const [carrierData, hasResponseLoaded] = usePublicCarrierProfileData(companyCode, language);

  if (!hasResponseLoaded) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  const profileImageUrl = getPublicS3BucketUrl(
    CARRIER_PROFILE_BUCKET,
    carrierData.carrierProfileRecord[0].logoLink
  );

  const profileImage = <Image height={48} objectFit="contain" src={profileImageUrl} width={220} />;

  return (
    <CarrierProfileMobile
      language={language}
      profile={carrierData}
      profileImage={profileImage}
      renderCompanyProfileLink={() => {
        return (
          <PageLink
            pageId={'carrier/[carrier]'}
            routeParams={[companyCode]}
            shouldUseModernVariant
            target={'carrier'}
          >
            {t('common:viewFullProfile')}
          </PageLink>
        );
      }}
      t={t}
    />
  );
};
