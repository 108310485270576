"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectStyles = exports.useInputStyles = exports.useTextStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        '& :hover': {
            backgroundColor: '#fff !important',
            borderRadius: `${theme.spacing(1)}px `,
        },
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        border: '0',
        borderColor: '#636363',
        borderRadius: `${theme.spacing(1)}px !important`,
        boxShadow: ({ shouldShowBoxShadow }) => {
            return shouldShowBoxShadow ? '2px 0px 4px rgba(0, 0, 0, 0.15)' : '0';
        },
        display: 'flex',
        height: theme.spacing(6),
        lineHeight: theme.spacing(3),
        paddingBottom: `${theme.spacing(0)} !important`,
        paddingTop: `${theme.spacing(0)} !important`,
    },
}));
exports.useTextStyles = (0, ux_1.makeStyles)((theme) => ({
    text: {
        color: theme.palette.grey[600],
    },
}));
exports.useInputStyles = (0, ux_1.makeStyles)((theme) => ({
    input: {
        background: theme.palette.common.white,
    },
}));
exports.useSelectStyles = (0, ux_1.makeStyles)(() => ({
    transparent: {
        backgroundColor: 'transparent !important',
    },
}));
