"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        paddingBottom: theme.spacing(3),
    },
    noPointerEvents: {
        '&:hover': {
            pointerEvents: 'none',
        },
        cursor: 'unset',
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
}));
