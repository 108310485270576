import { EPaymentMethodV1 } from '@truxweb/schemas';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

// Required Env
if (!publicRuntimeConfig.API_SERVER)
  throw new Error('Application Environment is Misconfigured: API_SERVER');
export const API_SERVER = publicRuntimeConfig.API_SERVER;

if (!publicRuntimeConfig.AWS_REGION)
  throw new Error('Application Environment is Misconfigured: AWS_REGION');
export const AWS_REGION = publicRuntimeConfig.AWS_REGION;

if (!publicRuntimeConfig.SHIPMENT_DOCUMENT_UPLOAD)
  throw new Error('Application Environment is Misconfigured: SHIPMENT_DOCUMENT_UPLOAD');
export const SHIPMENT_DOCUMENT_UPLOAD = publicRuntimeConfig.SHIPMENT_DOCUMENT_UPLOAD;

if (!publicRuntimeConfig.CARRIER_DATA_BUCKET_NAME)
  throw new Error('Application Environment is Misconfigured: CARRIER_DATA_BUCKET_NAME');
export const CARRIER_DATA_BUCKET = publicRuntimeConfig.CARRIER_DATA_BUCKET_NAME;

if (!publicRuntimeConfig.CARRIER_ONBOARDING_BUCKET_NAME)
  throw new Error('Application Environment is Misconfigured: CARRIER_ONBOARDING_BUCKET_NAME');
export const CARRIER_ONBOARDING_BUCKET = publicRuntimeConfig.CARRIER_ONBOARDING_BUCKET_NAME;

if (!publicRuntimeConfig.CARRIER_PROFILE_BUCKET)
  throw new Error('Application Environment is Misconfigured: CARRIER_PROFILE_BUCKET');
export const CARRIER_PROFILE_BUCKET = publicRuntimeConfig.CARRIER_PROFILE_BUCKET;

if (!publicRuntimeConfig.IDENTITY_POOL_ID)
  throw new Error('Application Environment is Misconfigured: IDENTITY_POOL_ID');
export const IDENTITY_POOL_ID = publicRuntimeConfig.IDENTITY_POOL_ID;

if (!publicRuntimeConfig.USER_POOLS_ID)
  throw new Error('Application Environment is Misconfigured: USER_POOLS_ID');
export const USER_POOLS_ID = publicRuntimeConfig.USER_POOLS_ID;

if (!publicRuntimeConfig.WEB_CLIENT_ID)
  throw new Error('Application Environment is Misconfigured: WEB_CLIENT_ID');
export const WEB_CLIENT_ID = publicRuntimeConfig.WEB_CLIENT_ID;

if (!publicRuntimeConfig.SEARCH_LOCAL_STORAGE)
  throw new Error('Application Environment is Misconfigured: SEARCH_LOCAL_STORAGE');
export const SEARCH_LOCAL_STORAGE = publicRuntimeConfig.SEARCH_LOCAL_STORAGE;

if (!publicRuntimeConfig.CONTACT_EMAIL_ADDRESS)
  throw new Error('Application Environment is Misconfigured: CONTACT_EMAIL_ADDRESS');

export const CONTACT_EMAIL_ADDRESS = publicRuntimeConfig.CONTACT_EMAIL_ADDRESS;

if (!publicRuntimeConfig.REGISTRATION_FORM_DATA_KEY)
  throw new Error('Application Environment is Misconfigured: REGISTRATION_FORM_DATA_KEY');
export const REGISTRATION_FORM_DATA_KEY = publicRuntimeConfig.REGISTRATION_FORM_DATA_KEY;

if (!publicRuntimeConfig.IS_BOOKING_ENABLED)
  throw new Error('Application Environment is Misconfigured: IS_BOOKING_ENABLED');
export const IS_BOOKING_ENABLED =
  publicRuntimeConfig.IS_BOOKING_ENABLED && publicRuntimeConfig.IS_BOOKING_ENABLED === 'false'
    ? false
    : true;

if (!publicRuntimeConfig.CAN_CARRIER_ADD_ADDITIONAL_CHARGES)
  throw new Error('Application Environment is Misconfigured: CAN_CARRIER_ADD_ADDITIONAL_CHARGES');
export const CAN_CARRIER_ADD_ADDITIONAL_CHARGES =
  publicRuntimeConfig.CAN_CARRIER_ADD_ADDITIONAL_CHARGES === 'false' ? false : true;

// Non required eng
export const EXTERNAL_FORMS =
  publicRuntimeConfig.EXTERNAL_FORMS && publicRuntimeConfig.EXTERNAL_FORMS !== 'false'
    ? true
    : false;

if (!publicRuntimeConfig.QUOTE_MAX_SELECTION)
  throw new Error('Application Environment is Misconfigured: QUOTE_MAX_SELECTION');
export const QUOTE_MAX_SELECTION = parseInt(publicRuntimeConfig.QUOTE_MAX_SELECTION, 10);

if (!publicRuntimeConfig.IS_MOBILE_ENABLED)
  throw new Error('Application Environment is Misconfigured: IS_MOBILE_ENABLED');
export const IS_MOBILE_ENABLED = publicRuntimeConfig.IS_MOBILE_ENABLED === 'false' ? false : true;

export const QUOTE_TABLE_REFRESH_MS = publicRuntimeConfig.QUOTE_TABLE_REFRESH_MS
  ? parseInt(publicRuntimeConfig.QUOTE_TABLE_REFRESH_MS, 10)
  : 150000;

export const QUOTE_SIDEBAR_REFRESH_MS = publicRuntimeConfig.QUOTE_SIDEBAR_REFRESH_MS
  ? parseInt(publicRuntimeConfig.QUOTE_SIDEBAR_REFRESH_MS, 10)
  : 30000;

export const LEFT_MENU_SUMMARY_REFRESH_MS = publicRuntimeConfig.LEFT_MENU_SUMMARY_REFRESH_MS
  ? parseInt(publicRuntimeConfig.LEFT_MENU_SUMMARY_REFRESH_MS, 10)
  : 150000;

export const GOOGLE_MAPS_API_KEY = publicRuntimeConfig.GOOGLE_MAPS_API_KEY;

export const COMPANY_VERSION = publicRuntimeConfig.COMPANY_VERSION
  ? parseInt(publicRuntimeConfig.COMPANY_VERSION)
  : 1;

export const IS_IN_MAINTENANCE = publicRuntimeConfig.IS_IN_MAINTENANCE
  ? publicRuntimeConfig.IS_IN_MAINTENANCE === 'false'
    ? false
    : true
  : false;

export const IS_SENTRY_DEBUG_ENABLED = publicRuntimeConfig.IS_SENTRY_DEBUG_ENABLED
  ? publicRuntimeConfig.IS_SENTRY_DEBUG_ENABLED === 'false'
    ? false
    : true
  : false;

export const IS_SENTRY_RELEASE_ENABLED = publicRuntimeConfig.IS_SENTRY_RELEASE_ENABLED
  ? publicRuntimeConfig.IS_SENTRY_RELEASE_ENABLED === 'false'
    ? false
    : true
  : false;

export const IS_FUEL_SURCHARGE_ACTIVE = publicRuntimeConfig.IS_FUEL_SURCHARGE_ACTIVE
  ? publicRuntimeConfig.IS_FUEL_SURCHARGE_ACTIVE === 'true'
    ? true
    : false
  : false;

export const SENTRY_DSN = publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = publicRuntimeConfig.NEXT_PUBLIC_SENTRY_SAMPLE_RATE;

export const IS_REGISTRATION_ENABLED = true;
export const IS_BROWSER_DETECTION_ENABLED = false;
export const IS_SIGNUP_SUPPRESSED = true;
export const IS_REGION_MANAGEMENT_ENABLED = false;
export const ADMIN_EDITABLE_TRANSACTIONS = [EPaymentMethodV1.StandardInvoice];
export const RAW_SEARCH_FORM_DATA = 'TRUXWEB_SEARCH_FORM';
export const BOOKING_CONFIRMATION = 'TRUXWEB_BOOKING_CONFIRMATION';
export const DEFAULT_PAGE_SIZE = 40;
export const APP_VERSION = publicRuntimeConfig.APP_VERSION || '1.4.0';
export const ANNOUNCEMENTS_VERSION =
  publicRuntimeConfig.ANNOUNCEMENTS_VERSION || `RELEASE_ANNOUCEMENTS_ACKNOWLEDGED-${APP_VERSION}`;

export const ARE_COMPANY_DOCUMENTS_ENABLED = publicRuntimeConfig.ARE_COMPANY_DOCUMENTS_ENABLED
  ? publicRuntimeConfig.ARE_COMPANY_DOCUMENTS_ENABLED === 'false'
    ? false
    : true
  : false;

// FIXME: this needs to be a carrier permissions
export const SHOULD_ALLOW_MULTIPLE_QUOTE_EDITS_FOR_CARRIER =
  publicRuntimeConfig.SHOULD_ALLOW_MULTIPLE_QUOTE_EDITS_FOR_CARRIER
    ? publicRuntimeConfig.SHOULD_ALLOW_MULTIPLE_QUOTE_EDITS_FOR_CARRIER === 'false'
      ? false
      : true
    : false;
