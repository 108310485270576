"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatChargeNumberToCurrencyValue = exports.reduceCharges = void 0;
const utils_1 = require("@truxweb/utils");
const reduceCharges = (acc, curr) => {
    const current = (0, exports.formatChargeNumberToCurrencyValue)(acc);
    const next = curr.isBillable ? (0, exports.formatChargeNumberToCurrencyValue)(curr) : 0;
    acc.charge = Object.assign(Object.assign({}, acc.charge), { value: current + next });
    return acc;
};
exports.reduceCharges = reduceCharges;
const formatChargeNumberToCurrencyValue = (charge) => {
    return (0, utils_1.formatNumberToCurrencyValue)(charge.charge.value);
};
exports.formatChargeNumberToCurrencyValue = formatChargeNumberToCurrencyValue;
