"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardDataGrid = void 0;
const __1 = require("..");
const react_1 = __importStar(require("react"));
const DashboardDataGrid_styles_1 = require("./DashboardDataGrid.styles");
const DEFAULT_GRID_HEIGHT = 72;
const DEFAULT_ROW_HEIGHT = 85;
const DEFAULT_PAGE_SIZE = 20;
// eslint-disable-next-line complexity
const DashboardDataGrid = ({ actionColumnCondition, actionOverlayDirection, columns, currentSort, data, defaultSort, filterMode, gapInterval, gridHeight, handleCellClick, handleColumnVisibilityChange, handleEmptyGrid, handleFilterChange, handleRowClick, handleTotalRowDisplayText, hasCustomHeader, isActionsOverlayAppended, isActionsOverlayEnabled, isAutoPageSize, isCheckboxSelectionEnabled, isLoading, onPageChange, onPageSizeChange, onRowSelectionChange, onSortChange, pageSize, paginationMode, paginationPosition, renderActionsContent, rowCount, rowHeight, selectionModel, shouldDisableMultipleSelection, shouldDisplayRowCountTextInHeader, shouldHideFooterSelectedRowCount, shouldSuppressFooter, sortingMode, t, toolbar, }) => {
    isCheckboxSelectionEnabled = isCheckboxSelectionEnabled || false;
    hasCustomHeader = hasCustomHeader || false;
    rowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
    pageSize = pageSize || DEFAULT_PAGE_SIZE;
    //by default MUI pagination is aligned to the right of the grid.  Initial design for DashboardDataGrid
    //aligns pagination to the left.  Search Results grid centers pagination.
    paginationPosition = paginationPosition ? paginationPosition : 'left';
    const dataGridClasses = (0, DashboardDataGrid_styles_1.useStyles)({
        gridHeight: gridHeight ? gridHeight : DEFAULT_GRID_HEIGHT,
        paginationPosition,
        shouldUseViewportHeight: !Boolean(gridHeight),
    });
    const customComponents = {};
    const [gridSort, setGridSort] = (0, react_1.useState)(defaultSort);
    const [resultsText, setResultsText] = (0, react_1.useState)('');
    const handleSetSort = (0, react_1.useCallback)((sort) => {
        setGridSort(sort);
    }, [setGridSort]);
    if (handleCellClick && handleRowClick) {
        // Linting disabled to allow for dev warning
        //eslint-disable-next-line no-console
        console.warn('Both cell & row click events are being handled onClick.');
    }
    const updateResultsText = (0, react_1.useCallback)((offset, limit, total) => {
        const results = `${offset}-${limit <= total ? limit : total}`;
        const resultsText = handleTotalRowDisplayText
            ? handleTotalRowDisplayText(t, results, total.toString())
            : '';
        setResultsText(resultsText);
    }, [handleTotalRowDisplayText, setResultsText, t]);
    //We may want to yank this out into it's own component
    const CustomPagination = () => {
        const { apiRef, state } = (0, __1.useGridSlotComponentProps)();
        //pagination display numbers
        const offset = state.pagination.page * state.pagination.pageSize + 1;
        const limit = (state.pagination.page + 1) * state.pagination.pageSize;
        const total = state.pagination.rowCount;
        updateResultsText(offset, limit, total);
        // That data coming from the grid slow components hook does not correct
        // pick up changes to the pageSize when it is changed dynamcally, so we
        // need to fix it here;
        const realPageCount = Math.ceil(state.pagination.rowCount / state.pagination.pageSize);
        //Expecting translation text to display pagination
        if (resultsText && resultsText.length > 0) {
            return (react_1.default.createElement(__1.Box, null,
                !shouldDisplayRowCountTextInHeader && (react_1.default.createElement(__1.Box, null,
                    react_1.default.createElement(__1.Typography, { className: dataGridClasses.paginationText }, resultsText))),
                react_1.default.createElement(__1.Box, null,
                    react_1.default.createElement(__1.Pagination, { count: realPageCount, onChange: (_event, value) => apiRef.current.setPage(value - 1), page: state.pagination.page + 1, shape: "rounded", size: "small" }))));
        }
        return (react_1.default.createElement(__1.Box, null,
            react_1.default.createElement(__1.Pagination, { color: "primary", count: realPageCount, onChange: (_event, value) => {
                    apiRef.current.setPage(value - 1);
                }, page: state.pagination.page + 1, variant: "outlined" })));
    };
    const HeaderComponent = (props) => {
        if (!hasCustomHeader)
            return null;
        return react_1.default.createElement(__1.Box, null, toolbar ? toolbar(resultsText, props) : null);
    };
    const DefaultCheckbox = (0, react_1.useCallback)((props) => {
        return (react_1.default.createElement(__1.Checkbox, Object.assign({}, props, { className: props.checked ? dataGridClasses.checked : dataGridClasses.checkbox })));
    }, [dataGridClasses]);
    customComponents.args = {
        components: {
            Checkbox: DefaultCheckbox,
            NoRowsOverlay: handleEmptyGrid,
            Pagination: CustomPagination,
            Toolbar: HeaderComponent,
        },
    };
    // If the actions overlay is on, we automaticaly insert this
    // column into the first index of the columns array
    if (isActionsOverlayEnabled) {
        const actionCol = {
            align: 'center',
            field: 'DashboardDataGridActionsOverly',
            headerName: ' ',
            renderCell: ({ row }) => {
                if (!renderActionsContent)
                    return null;
                /*Note: search results grid conditionallity displayed based on the `hasActiveProfile` property of
                 * a searchResultsGridRow. This business logic should be refactored as it is a valid use-case to
                 * hide the actionColumn feature conditionally on a row by row basis */
                let shouldDisplayAction = true;
                if (actionColumnCondition) {
                    shouldDisplayAction = actionColumnCondition(row);
                }
                return (react_1.default.createElement(__1.DataGridColumnAction, { gapInterval: gapInterval, renderActionsContent: renderActionsContent, row: row, rowHeight: rowHeight || DEFAULT_ROW_HEIGHT, shouldDisplay: shouldDisplayAction, slideDirection: actionOverlayDirection }));
            },
            renderHeader: () => {
                return ' ';
            },
            width: 25,
        };
        isActionsOverlayAppended ? columns.push(actionCol) : columns.unshift(actionCol);
        if (handleRowClick) {
            if (handleCellClick) {
                // eslint-disable-next-line no-console
                console.warn('Cell Click handler will be overriden as the actions overly is enabled');
            }
            handleCellClick = (params) => {
                if (params.field !== 'DashboardDataGridActionsOverly') {
                    handleRowClick(params);
                }
            };
        }
    }
    //Return MUI DataGrid with flexbox layout
    // Virtualization must be disabled for the overlay to work
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.Box, { className: dataGridClasses.dataGridFlexWrapper },
            react_1.default.createElement(__1.Box, { className: dataGridClasses.dataGridFlexContainer },
                react_1.default.createElement(__1.DataGrid, Object.assign({ autoPageSize: isAutoPageSize, checkboxSelection: isCheckboxSelectionEnabled, columns: columns, disableMultipleSelection: shouldDisableMultipleSelection, disableVirtualization: false, filterMode: filterMode, hideFooter: shouldSuppressFooter, hideFooterSelectedRowCount: shouldHideFooterSelectedRowCount, loading: isLoading, localeText: {
                        columnsPanelHideAllButton: react_1.default.createElement(react_1.default.Fragment, null),
                        columnsPanelShowAllButton: react_1.default.createElement(react_1.default.Fragment, null),
                        columnsPanelTextFieldLabel: t('common:findColumn'),
                        columnsPanelTextFieldPlaceholder: t('common:columnTitle'),
                        toolbarColumns: t('common:columns'),
                        toolbarExport: t('common:export'),
                    }, onCellClick: handleCellClick, onColumnVisibilityChange: handleColumnVisibilityChange, onFilterModelChange: handleFilterChange || undefined, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onRowClick: !isActionsOverlayEnabled ? handleRowClick : undefined, onSelectionModelChange: onRowSelectionChange, onSortModelChange: onSortChange || handleSetSort, pageSize: pageSize, paginationMode: paginationMode, rowCount: rowCount, rowHeight: rowHeight, rows: data, selectionModel: selectionModel, sortModel: currentSort || gridSort, sortingMode: sortingMode }, customComponents.args))))));
};
exports.DashboardDataGrid = DashboardDataGrid;
