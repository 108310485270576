// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardInvoiceTransactionStatusV1 = exports.CreditCardTransactionTypeV1 = exports.AvsV1 = exports.PaymentTransactionStatusV1 = exports.BankAcctReturnTypeV1 = exports.BankAcctTransferTypeV1 = exports.BankAcctTransactionTypeV1 = exports.MetadataTypeV1 = exports.PriceTypeV1 = exports.CurrencyV1 = exports.ShipmentLocationTypeV1 = exports.DayOfWeekV1 = exports.CountryV1 = exports.LtlPackagingV1 = exports.ShipmentTrackerV1 = exports.PaymentMethodV1 = exports.TruckloadTypeV1 = exports.ShipmentStatusV1 = void 0;
var ShipmentStatusV1;
(function (ShipmentStatusV1) {
    ShipmentStatusV1["REQUIRES_DOCUMENTS"] = "REQUIRES_DOCUMENTS";
    ShipmentStatusV1["PICKUP_READY"] = "PICKUP_READY";
    ShipmentStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentStatusV1["DELIVERED"] = "DELIVERED";
    ShipmentStatusV1["AWAITING_CARRIER_INVOICE"] = "AWAITING_CARRIER_INVOICE";
    ShipmentStatusV1["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    ShipmentStatusV1["READY_FOR_PROCESSING"] = "READY_FOR_PROCESSING";
    ShipmentStatusV1["PROCESSING_FAILURE"] = "PROCESSING_FAILURE";
    ShipmentStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentStatusV1["CANCELLED"] = "CANCELLED";
})(ShipmentStatusV1 || (exports.ShipmentStatusV1 = ShipmentStatusV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
var ShipmentTrackerV1;
(function (ShipmentTrackerV1) {
    ShipmentTrackerV1["P44"] = "P44";
})(ShipmentTrackerV1 || (exports.ShipmentTrackerV1 = ShipmentTrackerV1 = {}));
/**
 * How the good is packaged for shipping
 */
var LtlPackagingV1;
(function (LtlPackagingV1) {
    LtlPackagingV1["STANDARD_PALLET"] = "STANDARD_PALLET";
    LtlPackagingV1["NON_STANDARD_PALLET"] = "NON_STANDARD_PALLET";
    LtlPackagingV1["CRATE"] = "CRATE";
    LtlPackagingV1["LOOSE"] = "LOOSE";
})(LtlPackagingV1 || (exports.LtlPackagingV1 = LtlPackagingV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var ShipmentLocationTypeV1;
(function (ShipmentLocationTypeV1) {
    ShipmentLocationTypeV1["ORIGIN"] = "ORIGIN";
    ShipmentLocationTypeV1["DESTINATION"] = "DESTINATION";
})(ShipmentLocationTypeV1 || (exports.ShipmentLocationTypeV1 = ShipmentLocationTypeV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var MetadataTypeV1;
(function (MetadataTypeV1) {
    MetadataTypeV1["INTEGER"] = "INTEGER";
    MetadataTypeV1["STRING"] = "STRING";
    MetadataTypeV1["ADDRESS"] = "ADDRESS";
    MetadataTypeV1["DATE"] = "DATE";
    MetadataTypeV1["WEIGHT"] = "WEIGHT";
    MetadataTypeV1["PRICE"] = "PRICE";
    MetadataTypeV1["DURATION"] = "DURATION";
    MetadataTypeV1["TIME"] = "TIME";
    MetadataTypeV1["OPTIONAL"] = "OPTIONAL";
})(MetadataTypeV1 || (exports.MetadataTypeV1 = MetadataTypeV1 = {}));
var BankAcctTransactionTypeV1;
(function (BankAcctTransactionTypeV1) {
    BankAcctTransactionTypeV1["ACH"] = "ACH";
    BankAcctTransactionTypeV1["EFT"] = "EFT";
})(BankAcctTransactionTypeV1 || (exports.BankAcctTransactionTypeV1 = BankAcctTransactionTypeV1 = {}));
var BankAcctTransferTypeV1;
(function (BankAcctTransferTypeV1) {
    BankAcctTransferTypeV1["Credit"] = "Credit";
    BankAcctTransferTypeV1["Debit"] = "Debit";
})(BankAcctTransferTypeV1 || (exports.BankAcctTransferTypeV1 = BankAcctTransferTypeV1 = {}));
var BankAcctReturnTypeV1;
(function (BankAcctReturnTypeV1) {
    BankAcctReturnTypeV1["Rejected"] = "Rejected";
    BankAcctReturnTypeV1["Returned"] = "Returned";
})(BankAcctReturnTypeV1 || (exports.BankAcctReturnTypeV1 = BankAcctReturnTypeV1 = {}));
var PaymentTransactionStatusV1;
(function (PaymentTransactionStatusV1) {
    PaymentTransactionStatusV1["Failed"] = "Failed";
    PaymentTransactionStatusV1["Success"] = "Success";
    PaymentTransactionStatusV1["Pending"] = "Pending";
    PaymentTransactionStatusV1["Hold"] = "Hold";
    PaymentTransactionStatusV1["Cancelled"] = "Cancelled";
})(PaymentTransactionStatusV1 || (exports.PaymentTransactionStatusV1 = PaymentTransactionStatusV1 = {}));
var AvsV1;
(function (AvsV1) {
    AvsV1["Match"] = "Match";
    AvsV1["AddressOnly"] = "AddressOnly";
    AvsV1["ZipOnly"] = "ZipOnly";
    AvsV1["NoMatch"] = "NoMatch";
    AvsV1["NA"] = "NA";
})(AvsV1 || (exports.AvsV1 = AvsV1 = {}));
var CreditCardTransactionTypeV1;
(function (CreditCardTransactionTypeV1) {
    CreditCardTransactionTypeV1["Capture"] = "Capture";
    CreditCardTransactionTypeV1["PreAuth"] = "PreAuth";
    CreditCardTransactionTypeV1["Refund"] = "Refund";
    CreditCardTransactionTypeV1["Void"] = "Void";
})(CreditCardTransactionTypeV1 || (exports.CreditCardTransactionTypeV1 = CreditCardTransactionTypeV1 = {}));
var StandardInvoiceTransactionStatusV1;
(function (StandardInvoiceTransactionStatusV1) {
    StandardInvoiceTransactionStatusV1["HOLD"] = "Hold";
    StandardInvoiceTransactionStatusV1["ISSUED"] = "Issued";
    StandardInvoiceTransactionStatusV1["PAID"] = "Paid";
    StandardInvoiceTransactionStatusV1["VOID"] = "Void";
    StandardInvoiceTransactionStatusV1["CANCELLED"] = "Cancelled";
})(StandardInvoiceTransactionStatusV1 || (exports.StandardInvoiceTransactionStatusV1 = StandardInvoiceTransactionStatusV1 = {}));
