"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    cargo: {
        lineHeight: `${theme.spacing(2)}px`,
    },
    equipmentCard: {
        background: '#eaeaea',
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: 16,
    },
}));
