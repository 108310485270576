"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipperPaymentTermsForm = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
// eslint-disable-next-line complexity
const ShipperPaymentTermsForm = ({ bankAccountDetails, handleDenyPaymentTerms, handleRevisePaymentTerms, handleUploadFailure, handleUploadFile, isAdmin, isSaving, isUploading, onSubmitSuccess, paymentTerms, proofOfOwnership, setProofOfOwnership, t, }) => {
    var _a, _b;
    const { control, formState: { errors, isDirty }, handleSubmit, setError, setValue, watch, } = (0, react_hook_form_1.useForm)();
    const [areBankAccountDetailsRequired, setBankAccountDetailsRequired] = (0, react_1.useState)(false);
    const paymentTermsType = watch('paymentTermsType');
    (0, react_1.useEffect)(() => {
        // If there is no payment terms type it is defaulted to BPAD
        if (paymentTermsType === schemas_1.EPaymentTermsTypeV1.DEBIT || !paymentTermsType) {
            setProofOfOwnership({
                localName: bankAccountDetails === null || bankAccountDetails === void 0 ? void 0 : bankAccountDetails.proofOfOwnershipName,
                remoteName: bankAccountDetails === null || bankAccountDetails === void 0 ? void 0 : bankAccountDetails.proofOfOwnershipUrl,
            });
            setBankAccountDetailsRequired(true);
        }
        else {
            setBankAccountDetailsRequired(false);
        }
    }, [
        bankAccountDetails,
        paymentTermsType,
        setBankAccountDetailsRequired,
        paymentTerms,
        setProofOfOwnership,
        setValue,
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Box, { mb: 3, mt: 2 },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('account:companyLegalDetails')))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultValue: (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.companyLegalName) || '', id: "companyLegalName", inputProps: {
                                    placeholder: t('account:companyLegalName'),
                                }, isRequired: true, label: t('account:companyLegalName'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultValue: (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.companyTaxId) || '', id: "taxId", inputProps: {
                                    placeholder: t('account:taxId'),
                                }, isRequired: true, label: t('account:taxId'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(__1.AddressForm, { areNamesDisabled: true, control: control, data: paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.companyPaymentLocation, isAddressLineTwoDisabled: true, isFormDisabled: false, t: t, watch: watch })),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.paymentTermsType) || schemas_1.EPaymentTermsTypeV1.DEBIT, name: "paymentTermsType", render: ({ field: { onChange, value } }) => {
                                return (react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", onChange: onChange, sourceEnum: schemas_1.EPaymentTermsTypeV1, t: t, value: value === schemas_1.EPaymentTermsTypeV1.INVOICE
                                        ? schemas_1.EPaymentTermsTypeV1.INVOICE
                                        : schemas_1.EPaymentTermsTypeV1.DEBIT }));
                            }, rules: { required: true } })),
                    areBankAccountDetailsRequired && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 2 },
                                react_1.default.createElement(ux_1.Typography, { variant: "h5" }, t('account:companyBankingDetails')))),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                                    react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                            shrink: true,
                                        }, control: control, defaultValue: (bankAccountDetails === null || bankAccountDetails === void 0 ? void 0 : bankAccountDetails.accountName) || '', id: "accountHolderName", inputProps: {
                                            placeholder: t(`common:accountHolderName`),
                                            required: false,
                                        }, isRequired: true, label: t('common:accountHolderName'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                                    react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                            shrink: true,
                                        }, control: control, defaultValue: ((_a = bankAccountDetails === null || bankAccountDetails === void 0 ? void 0 : bankAccountDetails.address) === null || _a === void 0 ? void 0 : _a.name) || '', id: "bankName", inputProps: {
                                            placeholder: t(`common:bankName`),
                                            required: false,
                                        }, isRequired: true, label: t('common:bankName'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })))),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 2 },
                                react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t('account:branchAddress')))),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(__1.AddressForm, { areNamesDisabled: true, control: control, data: bankAccountDetails === null || bankAccountDetails === void 0 ? void 0 : bankAccountDetails.address, isAddressLineTwoDisabled: true, isFormDisabled: false, prefix: "bank", t: t, watch: watch })),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 2 },
                                react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t('account:accountInformation')))),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(__1.BankAccountDetails, { control: control, data: bankAccountDetails, handleUploadFailure: handleUploadFailure, isDirty: isDirty, isUploading: isUploading, onUploadFile: handleUploadFile, proofOfOwnership: proofOfOwnership, setError: setError, t: t })))),
                    isAdmin && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultValue: (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.netDays) || 0, id: "netDays", inputProps: {
                                    placeholder: t(`common:netDays`),
                                    required: false,
                                }, isRequired: true, label: t('common:netDays'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number" })))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: paymentTerms !== null, name: "termsAndConditionsAccepts", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                                    return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: Boolean(value), color: 'primary', onChange: onChange, value: value }), label: t(`account:creditCheckNotice`), style: { color: error ? 'red' : 'inherit' } }));
                                }, rules: { required: 'true' } }))),
                    react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                        react_1.default.createElement(ux_1.Box, { mt: 4 },
                            react_1.default.createElement(__1.SaveButton, { buttonText: isAdmin && paymentTerms !== null
                                    ? t('account:approvePaymentTerms')
                                    : t('account:submitApplication'), customAction: () => {
                                    handleSubmit(onSubmitSuccess)();
                                }, isDisabled: Boolean(((_b = Object.keys(errors)) === null || _b === void 0 ? void 0 : _b.length) > 0), isFullWidth: true, isSaving: isSaving, t: t })))),
                isAdmin && (react_1.default.createElement(ux_1.Box, { mt: 6 },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", item: true, justifyContent: "space-evenly", xs: true },
                        react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center", xs: true },
                            react_1.default.createElement(__1.SaveButton, { buttonText: t('account:requestRevision'), color: 'secondary', customAction: () => {
                                    if (handleRevisePaymentTerms) {
                                        handleRevisePaymentTerms();
                                    }
                                }, isDisabled: !Boolean(paymentTerms), isFullWidth: true, isSaving: isSaving, t: t })),
                        react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center", xs: true },
                            react_1.default.createElement(__1.SaveButton, { buttonText: t('account:denyRequest'), color: 'error', customAction: () => {
                                    if (handleDenyPaymentTerms) {
                                        handleDenyPaymentTerms();
                                    }
                                }, isDisabled: !Boolean(paymentTerms), isFullWidth: true, isSaving: isSaving, t: t })))))))));
};
exports.ShipperPaymentTermsForm = ShipperPaymentTermsForm;
