"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.dark,
        fontWeight: 600,
        margin: 0,
        padding: 0,
    },
    serviceFeeExplanation: {
        background: '#EDF8FF',
        borderRadius: theme.spacing(2),
    },
    summaryRowHeader: {
        color: theme.palette.primary.light,
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '18px',
    },
    summaryRowTitle: {
        color: theme.palette.grey[600],
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontStyle: 'normal',
        lineHeight: '18px',
    },
    totalRowItem: {
        color: theme.palette.grey[600],
    },
}));
